import React from 'react';
import { Grid } from 'src/modules/shared-main/components';
import { Skeleton } from '@mui/material';
import { generateArray } from 'src/utils';

const HighlightSkeleton: React.FC<Props> = ({ isLoading }) => {
  return (
    <Grid.Wrap renderIf={isLoading}>
      <Grid.Item variant="is-full">
        <Skeleton variant="text" width={320} height={40} />
      </Grid.Item>
      {generateArray(4).map((_, index) => (
        <Grid.Item variant="is-one-quarter" key={index}>
          <Skeleton variant="text" width="50%" />
          <Skeleton variant="text" width="100%" />
        </Grid.Item>
      ))}
    </Grid.Wrap>
  );
};

type Props = {
  isLoading: boolean;
};

export default HighlightSkeleton;
