/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { IoClose, IoCloseCircle, IoOpenOutline } from 'react-icons/io5';
import { useComponentDidMount } from 'src/hooks';
import { Callback } from 'src/redux/types';
import { FileCache } from 'src/services';
import { Link, Text, View } from '..';
import './styles.scss';
import { COLOR_CODE } from '../../common/appConfig/constants';
import { useGetPresignedDownloadFileUrl } from 'src/queries';

const FileRenderer: React.FC<Props> = ({
  url,
  downloadFileName,
  label,
  labelClassName,
  isUpdateOnChange = false,
  onRemove,
  progress = null,
}) => {
  const [decodeUrl, setDecodeUrl] = useState('');

  const { onGetDecodeUrl } = useGetPresignedDownloadFileUrl({
    onSuccess: ({ data }) => {
      setDecodeUrl(data.url);
    },
  });

  useComponentDidMount(() => {
    handleDecodeUrl();
  });

  useEffect(() => {
    if (isUpdateOnChange && url && decodeUrl) handleDecodeUrl();
  }, [url]);

  const handleDecodeUrl = () => {
    if (typeof url === 'string') {
      const decodeUrl = FileCache.getCachedUrl(url);
      if (!decodeUrl) {
        onGetDecodeUrl({ filePath: url });
      } else {
        setDecodeUrl(decodeUrl);
      }
    } else {
      const decodeUrl = URL.createObjectURL(url);
      setDecodeUrl(decodeUrl);
    }
  };

  const handleRemoveFile = () => {
    onRemove(url);
  };

  if (!decodeUrl) return null;

  const allowRemove = !!onRemove;

  if (label) {
    return (
      <View isRow align="center" className="mb-1">
        <Link
          href={decodeUrl}
          download={downloadFileName}
          target="_blank"
          className={cn('cmp-file-upload__name', labelClassName)}
        >
          {label}
        </Link>
        {allowRemove && (
          <IoClose className={cn('cmp-file-upload__icon--trash')} onClick={handleRemoveFile} />
        )}
      </View>
    );
  }

  return progress === null ? (
    <View isRow align="center" justify="space-between" className="my-1">
      <Link
        href={decodeUrl}
        download={downloadFileName}
        target="_blank"
        className={cn('cmp-file-upload__name')}
      >
        <View isRow align="center">
          {downloadFileName ||
            FileCache.trimUuidFromUniqueId(FileCache.getUniqueIdFromUrl(decodeUrl))}
          <IoOpenOutline color={COLOR_CODE.PRIMARY} size={16} className="ml-12" />
        </View>
      </Link>
      {allowRemove && (
        <IoClose
          size={17}
          color={COLOR_CODE.GRAY_600}
          className={cn('cmp-file-upload__icon--trash')}
          onClick={handleRemoveFile}
        />
      )}
    </View>
  ) : (
    <View isRow align="center" justify="space-between" className="my-1">
      <View flexGrow={1}>
        <Text
          className={cn('pl-0', {
            'text-color-grey': progress !== 100,
          })}
        >
          {downloadFileName}
        </Text>
        <LinearProgress
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: progress === 100 ? COLOR_CODE.SUCCESS : COLOR_CODE.PRIMARY,
            },
            backgroundColor: progress === 100 ? COLOR_CODE.SUCCESS : COLOR_CODE.HAWKES_BLUE,
            borderRadius: '2px',
          }}
          value={progress}
          variant="determinate"
        />
      </View>
      <View align="center" isRowWrap className="pl-24">
        <Link
          href={decodeUrl}
          download={downloadFileName}
          target="_blank"
          className={cn('cmp-file-upload__name pr-12 pl-16', {
            'cmp-file-upload__name--disabled': progress !== 100,
          })}
        >
          Preview
        </Link>
        <IoCloseCircle
          className={cn('cmp-file-upload__icon--trash')}
          size={24}
          color={COLOR_CODE.PRIMARY}
          onClick={onRemove}
        />
      </View>
    </View>
  );
};

type Props = {
  url: string | File;
  downloadFileName?: string;
  label?: string;
  labelClassName?: string;
  onRemove?: Callback;
  imageClassName?: string;
  isUpdateOnChange?: boolean;
  progress?: number;
};

export default FileRenderer;
