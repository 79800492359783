export enum WC5AKey {
  Id = 'id',
  FormNumber = 'formNumber',
  CaseNumber = 'caseNumber',
  ClaimantName = 'claimantName',
  CreatedAt = 'createdAt',

  //---Decedent's Information---//
  RawClaimantCaseNumber = 'rawClaimantCaseNumber',
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffix = 'rawClaimantSuffix',
  RawClaimantSocialSecurityNumber = 'rawClaimantSocialSecurityNumber',
  RawClaimantPassportNumber = 'rawClaimantPassportNumber',
  RawClaimantDateOfInjury = 'rawClaimantDateOfInjury',
  RawClaimantDeathCertificateNumber = 'rawClaimantDeathCertificateNumber',
  RawClaimantDeathDate = 'rawClaimantDeathDate',
  RawClaimantDeathPlace = 'rawClaimantDeathPlace',
  RawClaimantDeathNatureOfIi = 'rawClaimantDeathNatureOfIi',

  //----Employer---//
  RawEmployerName = 'rawEmployerName',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerZipcode = 'rawEmployerZipcode',
  RawEmployerState = 'rawEmployerState',
  RawEmployerEmail = 'rawEmployerEmail',
  RawEmployerPhone = 'rawEmployerPhone',
  RawEmployerPointOfContact = 'rawEmployerPointOfContact',

  //---Header and Signature---//
  RawNewAmend = 'rawNewAmend',
  RawHeaderCaseNumber = 'rawHeaderCaseNumber',
  RawDateReceived = 'rawDateReceived',
  RawDependentPrinterName = 'rawDependentPrinterName',
  RawDependentPrinterSignatureType = 'rawDependentPrinterSignatureType',
  RawDependentPrinterSignature = 'rawDependentPrinterSignature',
  RawDependentPrinterSignatureDate = 'rawDependentPrinterSignatureDate',
  RawDependentPrinterPhone = 'rawDependentPrinterPhone',
  RawDependentPrinterEmailAddress = 'rawDependentPrinterEmail',
  RawDependentPrinterAddress = 'rawDependentPrinterAddress',
  RawDependentPrinterRepresentedName = 'rawDependentPrinterRepresentedName',
  RawDependentPrinterRepresentedAddress = 'rawDependentPrinterRepresentedAddress',
  RawDependentPrinterRepresentedCity = 'rawDependentPrinterRepresentedCity',
  RawDependentPrinterRepresentedState = 'rawDependentPrinterRepresentedState',
  RawDependentPrinterRepresentedZipcode = 'rawDependentPrinterRepresentedZipcode',

  //---Dependent 1---//
  RawDependent1LastName = 'rawDependent1LastName',
  RawDependent1FirstName = 'rawDependent1FirstName',
  RawDependent1MiddleInitial = 'rawDependent1MiddleInitial',
  RawDependent1Suffix = 'rawDependent1Suffix',
  RawDependent1Birthday = 'rawDependent1Birthday',
  RawDependent1Address = 'rawDependent1Address',
  RawDependent1City = 'rawDependent1City',
  RawDependent1State = 'rawDependent1State',
  RawDependent1Zipcode = 'rawDependent1Zipcode',
  RawDependent1Phone = 'rawDependent1Phone',
  RawDependent1RelationToDeceased = 'rawDependent1RelationToDeceased',
  RawDependent1MarriageOrBirthCertificate = 'rawDependent1MarriageOrBirthCertificate',
  RawDependent1Email = 'rawDependent1Email',
  RawDependent1RepresentedName = 'rawDependent1RepresentedName',
  RawDependent1RepresentedPhone = 'rawDependent1RepresentedPhone',
  RawDependent1RepresentedAddress = 'rawDependent1RepresentedAddress',
  RawDependent1RepresentedCity = 'rawDependent1RepresentedCity',
  RawDependent1RepresentedState = 'rawDependent1RepresentedState',
  RawDependent1RepresentedZipcode = 'rawDependent1RepresentedZipcode',
  RawDependent1RepresentedEmail = 'rawDependent1RepresentedEmail',

  //---Dependent 2---//
  RawDependent2LastName = 'rawDependent2LastName',
  RawDependent2FirstName = 'rawDependent2FirstName',
  RawDependent2MiddleInitial = 'rawDependent2MiddleInitial',
  RawDependent2Suffix = 'rawDependent2Suffix',
  RawDependent2Birthday = 'rawDependent2Birthday',
  RawDependent2Address = 'rawDependent2Address',
  RawDependent2City = 'rawDependent2City',
  RawDependent2State = 'rawDependent2State',
  RawDependent2Zipcode = 'rawDependent2Zipcode',
  RawDependent2Phone = 'rawDependent2Phone',
  RawDependent2RelationToDeceased = 'rawDependent2RelationToDeceased',
  RawDependent2MarriageOrBirthCertificate = 'rawDependent2MarriageOrBirthCertificate',
  RawDependent2Email = 'rawDependent2Email',
  RawDependent2RepresentedName = 'rawDependent2RepresentedName',
  RawDependent2RepresentedPhone = 'rawDependent2RepresentedPhone',
  RawDependent2RepresentedAddress = 'rawDependent2RepresentedAddress',
  RawDependent2RepresentedCity = 'rawDependent2RepresentedCity',
  RawDependent2RepresentedState = 'rawDependent2RepresentedState',
  RawDependent2RepresentedZipcode = 'rawDependent2RepresentedZipcode',
  RawDependent2RepresentedEmail = 'rawDependent2RepresentedEmail',

  //---Dependent 3---//
  RawDependent3LastName = 'rawDependent3LastName',
  RawDependent3FirstName = 'rawDependent3FirstName',
  RawDependent3MiddleInitial = 'rawDependent3MiddleInitial',
  RawDependent3Suffix = 'rawDependent3Suffix',
  RawDependent3Birthday = 'rawDependent3Birthday',
  RawDependent3Address = 'rawDependent3Address',
  RawDependent3City = 'rawDependent3City',
  RawDependent3State = 'rawDependent3State',
  RawDependent3Zipcode = 'rawDependent3Zipcode',
  RawDependent3Phone = 'rawDependent3Phone',
  RawDependent3RelationToDeceased = 'rawDependent3RelationToDeceased',
  RawDependent3MarriageOrBirthCertificate = 'rawDependent3MarriageOrBirthCertificate',
  RawDependent3Email = 'rawDependent3Email',
  RawDependent3RepresentedName = 'rawDependent3RepresentedName',
  RawDependent3RepresentedPhone = 'rawDependent3RepresentedPhone',
  RawDependent3RepresentedAddress = 'rawDependent3RepresentedAddress',
  RawDependent3RepresentedCity = 'rawDependent3RepresentedCity',
  RawDependent3RepresentedState = 'rawDependent3RepresentedState',
  RawDependent3RepresentedZipcode = 'rawDependent3RepresentedZipcode',
  RawDependent3RepresentedEmail = 'rawDependent3RepresentedEmail',

  //---Dependent 4---//
  RawDependent4LastName = 'rawDependent4LastName',
  RawDependent4FirstName = 'rawDependent4FirstName',
  RawDependent4MiddleInitial = 'rawDependent4MiddleInitial',
  RawDependent4Suffix = 'rawDependent4Suffix',
  RawDependent4Birthday = 'rawDependent4Birthday',
  RawDependent4Address = 'rawDependent4Address',
  RawDependent4City = 'rawDependent4City',
  RawDependent4State = 'rawDependent4State',
  RawDependent4Zipcode = 'rawDependent4Zipcode',
  RawDependent4Phone = 'rawDependent4Phone',
  RawDependent4RelationToDeceased = 'rawDependent4RelationToDeceased',
  RawDependent4MarriageOrBirthCertificate = 'rawDependent4MarriageOrBirthCertificate',
  RawDependent4Email = 'rawDependent4Email',
  RawDependent4RepresentedName = 'rawDependent4RepresentedName',
  RawDependent4RepresentedPhone = 'rawDependent4RepresentedPhone',
  RawDependent4RepresentedAddress = 'rawDependent4RepresentedAddress',
  RawDependent4RepresentedCity = 'rawDependent4RepresentedCity',
  RawDependent4RepresentedState = 'rawDependent4RepresentedState',
  RawDependent4RepresentedZipcode = 'rawDependent4RepresentedZipcode',
  RawDependent4RepresentedEmail = 'rawDependent4RepresentedEmail',
}
