import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Text, View } from 'src/modules/shared-main/components';
import { hideDialog } from 'src/redux/dialog/dialogSlice';
import { Callback } from 'src/redux/types';

const DeleteConfirmation: React.FC<Props> = ({ isLoading, handleDelete }) => {
  const dispatch = useDispatch();

  return (
    <View>
      <Text>Are you sure you want to delete this record? Any related data will be removed.</Text>
      <View className="pt-24" isRowWrap justify="flex-end">
        <Button disabled={isLoading} onClick={() => dispatch(hideDialog())} variant="outline">
          No, keep it
        </Button>
        <Button className="ml-8" isLoading={isLoading} onClick={handleDelete}>
          Yes, delete it
        </Button>
      </View>
    </View>
  );
};

type Props = {
  isLoading: boolean;
  handleDelete: Callback;
};

export default DeleteConfirmation;
