export enum TDISFCaseKey {
  ID = 'id',
  CaseNumber = 'tdiSFCaseNumber',
  TDISFCaseFormattedNumber = 'tdiSFCaseFormattedNumber',
  DisabilityDate = 'disabilityDate',
  CaseStatus = 'caseStatus',
  PortalStatus = 'portalStatus',
  Status = 'status',
  ReceivedDate = 'receivedDate',
  DenialReason = 'denialReason',
  DenialOtherReason = 'otherReason',
  Employer = 'employer',
  EmployerName = 'employerName',
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  EmployerID = 'employerId',
  ClaimantID = 'claimantId',
}
