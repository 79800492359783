import { PaginationResponseType } from '../helpers';
import { AccountKey, RelatedContactKey } from './keys';
import { Account, RelatedContact } from './types';

export const toAccountDetail = (data: Account) => ({
  ...data,
  [AccountKey.TDIClaimServiceAccountName]: data?.tdiClaimsServiceAccount?.accountName || '',
  [AccountKey.WCClaimServiceAccountName]: data?.wcClaimsServiceAccount?.accountName || '',
});

export const toRelatedContactsData = (
  data: PaginationResponseType<RelatedContact>
): PaginationResponseType<RelatedContact> => {
  return {
    ...data,
    data: data?.data.map((relatedContact) => {
      const contactName = [
        relatedContact?.[RelatedContactKey.FirstName],
        relatedContact?.[RelatedContactKey.LastName],
      ]
        .filter((item) => item)
        .join(' ');
      return {
        ...relatedContact,
        [RelatedContactKey.ContactName]: contactName,
      };
    }),
  };
};
