export enum DecisionKey {
  Id = 'id',
  DecisionStatus = 'decisionStatus',
  DecisionNumber = 'decisionNumber',
  CompletedDate = 'decisionCompletedDate',
  MailedDate = 'mailedDate',
}

export enum DecisionStatus {
  DecisionRequired = 'Decision Required',
  DraftInPreparation = 'Draft in Preparation',
  DecisionDraftCompleted = 'Decision Draft Completed',
  HearingOfficerReview = 'Hearing Officer Review',
  UnderReview = 'Under Review',
  Completed = 'Completed',
}
