import { WC77AKey } from './keys';

export enum WC77AHearingApplicationResponseType {
  ReviewOfEmployerDenialOfHealthcare = "REVIEW OF EMPLOYER''S DENIAL OF HEALTHCARE",
  Compensability = 'COMPENSABILITY',
  TerminationOfTemporaryTotalDisability = 'TERMINATION OF TEMPORARY TOTAL DISABILITY',
  TerminationOfTemporaryPartialDisability = 'TERMINATION OF TEMPORARY PARTIAL DISABILITY',
  PermanentDisability = 'PERMANENT DISABILITY',
  Disfigurement = 'DISFIGUREMENT',
  DependentDeathBenefits = 'DEPENDENT DEATH BENEFITS',
  ConcurrentEmployment = 'CONCURRENT EMPLOYMENT',
  Reopening = 'REOPENING',
  OtherIssues = 'OTHER ISSUES',
}

export interface WC77AHearingApplicationResponse {
  [WC77AKey.Response]: WC77AHearingApplicationResponseType;
  [WC77AKey.Description]: string;
  [WC77AKey.IsCheckedResponse]?: boolean;
}

export interface WC77A {
  [WC77AKey.CaseId]: string;
  [WC77AKey.DateReceived]: string;

  [WC77AKey.RespondentName]: string;
  [WC77AKey.RespondentPhone]: string;
  [WC77AKey.RespondentAddress]: string;
  [WC77AKey.RespondentCity]: string;
  [WC77AKey.RespondentState]: string;
  [WC77AKey.RespondentZipCode]: string;
  [WC77AKey.RespondentRepresenting]: string;

  [WC77AKey.ClaimantLastName]: string;
  [WC77AKey.ClaimantFirstName]: string;
  [WC77AKey.ClaimantMiddleInitial]: string;
  [WC77AKey.ClaimantSuffix]: string;
  [WC77AKey.ClaimantIdType]: string;
  [WC77AKey.ClaimantIdNumber]: string;
  [WC77AKey.DateOfInjury]: string;
  [WC77AKey.ClaimantAddress]: string;
  [WC77AKey.ClaimantCity]: string;
  [WC77AKey.ClaimantState]: string;
  [WC77AKey.ClaimantZipCode]: string;
  [WC77AKey.ClaimantEmail]: string;
  [WC77AKey.ClaimantPhone]: string;

  [WC77AKey.EmployerCarrierName]: string;
  [WC77AKey.EmployerCarrierAddress]: string;
  [WC77AKey.EmployerCarrierCity]: string;
  [WC77AKey.EmployerCarrierState]: string;
  [WC77AKey.EmployerCarrierZipCode]: string;

  [WC77AKey.HearingRespondent]: string;
  [WC77AKey.HearingFiledApplicant]: string;
  [WC77AKey.HearingApplicationDate]: string;
  [WC77AKey.HearingApplicationResponses]: WC77AHearingApplicationResponse[];
  [WC77AKey.Response]: string;
  [WC77AKey.Description]: string;

  [WC77AKey.Witness1Name]: string;
  [WC77AKey.Witness1WorkPhone]: string;
  [WC77AKey.Witness1HomePhone]: string;
  [WC77AKey.Witness1Address]: string;
  [WC77AKey.Witness1City]: string;
  [WC77AKey.Witness1State]: string;
  [WC77AKey.Witness1ZipCode]: string;

  [WC77AKey.Witness2Name]: string;
  [WC77AKey.Witness2WorkPhone]: string;
  [WC77AKey.Witness2HomePhone]: string;
  [WC77AKey.Witness2Address]: string;
  [WC77AKey.Witness2City]: string;
  [WC77AKey.Witness2State]: string;
  [WC77AKey.Witness2ZipCode]: string;

  [WC77AKey.Witness3Name]: string;
  [WC77AKey.Witness3WorkPhone]: string;
  [WC77AKey.Witness3HomePhone]: string;
  [WC77AKey.Witness3Address]: string;
  [WC77AKey.Witness3City]: string;
  [WC77AKey.Witness3State]: string;
  [WC77AKey.Witness3ZipCode]: string;

  [WC77AKey.SpecialConditions]: string;
  [WC77AKey.Signature]: string;
  [WC77AKey.SignatureDate]: Date;
  [WC77AKey.SignatureTitle]: string;
}
