import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import appConfig from 'src/appConfig';
import { MockTDICase, MockWC } from 'src/mocks';
import {
  EditTDIFormPayload,
  EditWCFormPayload,
  GetPropertiesParams,
  ProfilePayload,
  TDIFormPayload,
  UploadFilesPayload,
  WCForm,
} from 'src/queries';
import {
  ChangePasswordPayload,
  ConfirmPasswordPayload,
  ConfirmSignInPayload,
  ConfirmSignUpPayload,
  ForgotPasswordPayload,
  ResendSignUpPayload,
  SignInPayload,
  SignUpPayload,
  SubmitForgotPasswordPayload,
} from 'src/queries/UAM/types';
import { GetPresignedPayload } from 'src/redux/file/types';
import { Callback } from 'src/redux/types';
import { emptyFunction, newCancelToken, stringify } from 'src/utils';
import { TokenService } from '.';

axios.defaults.withCredentials = true;

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) => Auth.signIn(body.email, body.password);
  const signUp = (body: SignUpPayload) => {
    const params = {
      username: body.username,
      password: body.password,
    };

    const attributes = {
      email: body.email,
      given_name: body.firstName,
      family_name: body.lastName,
      middle_name: body.middleName,
      birthdate: body.dateOfBirth,
      'custom:ssn': body.socialSecurityNumber,
      'custom:user_type': 'CLAIMANT',
    };

    return Auth.signUp({ ...params, attributes });
  };

  const resendSignUp = (body: ResendSignUpPayload) => Auth.resendSignUp(body.email);

  const confirmSignUp = (body: ConfirmSignUpPayload) =>
    Auth.confirmSignUp(body.username, body.code);

  const signOut = () => Auth.signOut();

  const forgotPassword = (body: ForgotPasswordPayload) => Auth.forgotPassword(body.email);

  const submitForgotPassword = (body: SubmitForgotPasswordPayload) =>
    Auth.forgotPasswordSubmit(body.username, body.token, body.password);

  const changePassword = (body: ChangePasswordPayload) =>
    Auth.changePassword(body.user, body.currentPassword, body.newPassword);

  const confirmSignIn = (body: ConfirmSignInPayload) => {
    console.log('body: ', body);
    console.log('a: ', Auth);
    return Auth.sendCustomChallengeAnswer(body.user, body.code);
  };

  const confirmPassword = (password: ConfirmPasswordPayload) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.signIn({
        username: user.username,
        password: password.password,
      })
    );
  };

  // ====================== Profile ======================
  const getUserId = (params: { username: string }) => {
    const username = { username: params.username };
    const queryString = stringify(username);
    return api.get(`/account-svc/v1/users/user-id?${queryString}`, {}, newCancelToken());
  };

  // ====================== Claimant Profile ======================
  const getMyProfile = () => api.get('/case-svc/v1/portal/users/me', {}, newCancelToken());

  const getPresignedDownloadUrl = (params: { filePath: string }) => {
    const filePath = { filePath: params.filePath };
    const queryString = stringify(filePath);
    return api.get(
      `/case-svc/v1/files/presigned-download-url?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const getAccount = () => api.get('/case-svc/v1/portal/users/me/account', {}, newCancelToken());

  const getAllRelatedContacts = (params: GetPropertiesParams) => {
    const { id, ...rest } = params;
    return api.get(`/case-svc/v1/accounts/${id}/contacts?${stringify(rest)}`, {}, newCancelToken());
  };

  const getAllAccounts = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/accounts?${stringify(params)}`, {}, newCancelToken());

  const getWCCases = () => MockWC.getWCCases();

  const getWCCaseById = () => MockWC.getWCCaseById();

  const getMyProfileMock = () => {
    const response = {
      id: '115176d7-2d27-4ee3-9f43-47c4eeb02d1e',
      createdAt: '2022-01-19T17:23:30.530Z',
      updatedAt: '2022-01-19T17:23:30.530Z',
      email: 'john_doe@gmail.com',
      firstName: 'John',
      lastName: 'Christopher',
      middleName: 'Doe',
      claimantUser: {
        socialSecurityNumber: 783910003,
        dateOfBirth: '1983-03-27',
        phoneNumber: 12012987481,
        mailingAddress: {
          deliverTo: 'John Doe',
          careOf: 'Edward Moore',
          address: '1100 Manor Dr., Chalfont, PA 18914',
          country: 'United States',
          zipCode: 55000,
          city: 'New York',
          state: 'New York',
        },
        directDeposit: {
          accountType: 'CHECKING',
          routingNumber: 1236789043,
          accountNumber: 'TPA423465235NH',
        },
      },
    };
    return new Promise((resolve) => {
      setTimeout(() => resolve({ ok: true, data: response }), 600);
    });
  };

  const updateUserAvatar = (body: { avatarUrl: string }) =>
    api.patch(`/me/avatar`, body, newCancelToken());

  const updateMyProfile = (body: ProfilePayload) =>
    api.put(`/case-svc/v1/portal/users/me`, body, newCancelToken());

  // ====================== Content ======================
  // const getContent = () => api.get('/content', {}, newCancelToken());
  const getContents = () => api.get('/account-svc/v1/contents', {}, newCancelToken());

  // ====================== File ======================
  const getPresignedUserServiceUrl = (params: GetPresignedPayload) => {
    return api.get('account-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  const uploadFile = (body: UploadFilesPayload) =>
    axios.put(body.url, body.fileData, {
      headers: { 'Content-Type': body.contentType },
      withCredentials: false,
    });

  const getPresignedUploadFile = (params) => {
    return api.get('/case-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  const uploadFiles = (body: { setProgress: Callback; payload: FormData }) => {
    const { setProgress = emptyFunction, payload } = body;
    return api.post('/case-svc/v1/files/array-of-files', payload, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const progress = Math.floor((loaded / total) * 100);
        setProgress(progress, payload);
      },
      ...newCancelToken(),
    });
  };

  const getPresignedDownloadFileUrl = (params) => {
    return api.get('/case-svc/v1/files/presigned-download-url', params, newCancelToken());
  };

  const submitUploadFile = (body) => {
    return api.post('/case-svc/v1/upload-files', { ...body }, newCancelToken());
  };

  const getDecodeUserServiceUrl = (params: { filePath: string }) =>
    api.get('/file-svc/v1/presigned-download-url', params, newCancelToken());

  const uploadFileWithProgress = (body: UploadFilesPayload) =>
    axios.put(body.url, body.fileData, {
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);
        body.setProgress(percentageProgress);
      },
    });

  const getAllUploadFiles = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/uploaded-files?${stringify(params)}`, {}, newCancelToken());

  // ====================== TDI ======================
  const getTDICaseMock = (params) => {
    const queryString = stringify(params);

    return new Promise((resolve) => {
      setTimeout(() => resolve({ ok: true, data: MockTDICase.getTDICase(queryString) }), 600);
    });
  };

  const getTIDDetailById = (params) => {
    const queryString = stringify(params);

    return new Promise((resolve) => {
      setTimeout(() => resolve({ ok: true, data: MockTDICase.getTDIDetail(queryString) }), 600);
    });
  };

  // ====================== TDI-Case ======================
  const getTDICase = (params: { id: string }) =>
    api.get(`case-svc/v1/tdi-cases/${params.id}`, {}, newCancelToken());

  const getAllTDICases = (params) =>
    api.get(`/case-svc/v1/tdi-cases?${stringify(params)}`, {}, newCancelToken());

  // ====================== TDI-Form ======================
  const getAllTDIForms = (params) =>
    api.get(`/case-svc/v1/external/tdi-forms?${stringify(params)}`, {}, newCancelToken());

  const getAllTDIDuplicatedCases = (params) =>
    api.get(
      `/case-svc/v1/external/tdi-forms/tdi-46s/duplicated-cases?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const deleteTDIForm = (id: string) =>
    api.delete(`/case-svc/v1/external/tdi-forms/${id}`, {}, newCancelToken());

  // ====================== System Accounts ======================
  const searchUserAccounts = (params: { search: string }) => {
    const queryString = stringify(params);
    return api.get(`/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsAxios = (params: { search: string; skip: number; take: number }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsByOrderAxios = (params: {
    search: string;
    skip: number;
    take: number;
    order: string;
  }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };

  // ====================== TDI SF ======================
  const getTDISFCase = (params: { id: string }) =>
    api.get(`case-svc/v1/tdi-sf-cases/${params.id}`, {}, newCancelToken());

  const getAllTDISFCases = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-sf-cases?${stringify(params)}`, {}, newCancelToken());

  // ====================== Portal User ======================
  const getAllAccountPortalUsers = (params: GetPropertiesParams) => {
    const { accountId, ...queryString } = params;

    return api.get(
      `/case-svc/v1/accounts/${accountId}/portal-users?${stringify(queryString)}`,
      {},
      newCancelToken()
    );
  };
  // ====================== Decisions ======================
  const getDecisions = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/decisions?${stringify(params)}`, {}, newCancelToken());

  // ====================== TDI-46s ======================
  const getTDI46 = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-46s/${params.id}`, {}, newCancelToken());
  };

  const getAllTDI46s = (params) =>
    api.get(`/case-svc/v1/tdi-46s?${stringify(params)}`, {}, newCancelToken());

  // ====================== Hearings ======================

  const getAllHearings = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/case-hearings?${stringify(params)}`, {}, newCancelToken());

  //====================== TDI-30s ======================

  const getAllTDI30 = (params) =>
    api.get(`/case-svc/v1/tdi-30s?${stringify(params)}`, {}, newCancelToken());

  // ====================== TDI Coverage ======================
  const getAllTDICoverages = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-coverages?${stringify(params)}`, {}, newCancelToken());

  // ====================== DCD Cases ======================
  const getAllDCDCases = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-cases?${stringify(params)}`, {}, newCancelToken());

  const getDCDCase = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-cases/${params.id}`, {}, newCancelToken());

  const createTDIForm = (body: TDIFormPayload) =>
    api.post(`/case-svc/v1/external/tdi-forms`, body, newCancelToken());

  const editTDIForm = (body: EditTDIFormPayload) =>
    api.put(`/case-svc/v1/external/tdi-forms/${body.id}`, body, newCancelToken());

  const getTDIForm = (params: { id: string }) => {
    return api.get(`/case-svc/v1/external/tdi-forms/${params.id}`, {}, newCancelToken());
  };

  // ====================== WC-3s ======================
  const getAllWC3s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-3s?${stringify(params)}`, {}, newCancelToken());

  const getWC3 = (params: { id: string }) => {
    return api.get(`/case-svc/v1/wc-3s/${params.id}`, {}, newCancelToken());
  };

  // ====================== WC-3As ======================
  const getAllWC3As = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-3as?${stringify(params)}`, {}, newCancelToken());

  const getWC3ADetail = (params: { id: string }) =>
    api.get(`/case-svc/v1/wc-3as/${params.id}`, {}, newCancelToken());

  // ====================== WC-5s ======================
  const getAllWC5s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5s?${stringify(params)}`, {}, newCancelToken());

  const getWC5Detail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5s/${params.id}`, {}, newCancelToken());

  // ====================== WC-5As ======================
  const getAllWC5As = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5As?${stringify(params)}`, {}, newCancelToken());

  const getWC5ADetail = (params: { id: string }) =>
    api.get(`/case-svc/v1/wc-5as/${params.id}`, {}, newCancelToken());

  // ====================== WC-77s ======================
  const getAllWC77s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-77s?${stringify(params)}`, {}, newCancelToken());

  const getWC77Detail = (params: { id: string }) =>
    api.get(`/case-svc/v1/wc-77s/${params.id}`, {}, newCancelToken());

  // ====================== WC-14s ======================
  const getWC14Detail = (params: { id: string }) =>
    api.get(`/case-svc/v1/wc-14s/${params.id}`, {}, newCancelToken());

  // ====================== WC - 1s ======================
  const getAllWC1s = (params) =>
    api.get(`/case-svc/v1/wc-1s?${stringify(params)}`, {}, newCancelToken());

  const getWC1Detail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/wc-1s/${params.id}`, {}, newCancelToken());
  };

  // ====================== WC - 2s ======================
  const getWC2 = (params: { id: string }) => {
    return api.get(`/case-svc/v1/wc-2s/${params.id}`, {}, newCancelToken());
  };

  const getAllWC2s = (params) =>
    api.get(`/case-svc/v1/wc-2s?${stringify(params)}`, {}, newCancelToken());

  // ====================== WC-14s ======================
  const getAllWC14s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-14s?${stringify(params)}`, {}, newCancelToken());

  // ====================== WC-Insurance-Policies ======================
  const getAllWCIP = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/insurance-policies?${stringify(params)}`, {}, newCancelToken());
  };
  const getInsurancePolicy = (params: { id: string }) =>
    api.get(`/case-svc/v1/insurance-policies/${params.id}`, {}, newCancelToken());

  // ====================== WC Coverages ======================
  const getAllWCCoverages = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/coverages?${stringify(params)}`, {}, newCancelToken());
  };

  // ====================== WC-Form ======================
  const getAllWCForms = (params) =>
    api.get(`/case-svc/v1/external/wc-forms?${stringify(params)}`, {}, newCancelToken());

  const createWCForm = (body: WCForm) =>
    api.post(`/case-svc/v1/external/wc-forms`, body, newCancelToken());

  const editWCForm = (body: EditWCFormPayload) =>
    api.put(`/case-svc/v1/external/wc-forms/${body.id}`, body, newCancelToken());

  const getWCForm = (params: { id: string }) => {
    return api.get(`/case-svc/v1/external/wc-forms/${params.id}`, {}, newCancelToken());
  };

  const deleteWCForm = (id: string) =>
    api.delete(`/case-svc/v1/external/wc-forms/${id}`, {}, newCancelToken());

  const getAllWC1DuplicatedCases = (params) =>
    api.get(
      `/case-svc/v1/external/wc-forms/wc-1s/duplicated-cases?${stringify(params)}`,
      {},
      newCancelToken()
    );

  // ===================== DASHBOARD ======================
  const getAllGlobal = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/portal/global-search?${stringify(params)}`, {}, newCancelToken());

  //
  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Auth ======================
    // getPermission,
    confirmSignIn,
    signIn,
    signUp,
    resendSignUp,
    confirmSignUp,
    signOut,
    forgotPassword,
    submitForgotPassword,
    changePassword,
    // setPreferredMfa,

    // ====================== File ======================
    getPresignedUserServiceUrl,
    uploadFile,
    uploadFileWithProgress,
    getDecodeUserServiceUrl,
    submitUploadFile,
    getPresignedDownloadFileUrl,
    getPresignedUploadFile,
    getAllUploadFiles,
    uploadFiles,

    // ====================== Content ======================
    getContents,

    // ====================== Users ======================
    getUserId,

    // ====================== Profile ======================
    getMyProfile,
    getMyProfileMock,
    // updateMyProfile,
    updateUserAvatar,
    updateMyProfile,
    confirmPassword,
    getPresignedDownloadUrl,

    // ====================== TDI ======================
    getTDICaseMock,
    getTIDDetailById,
    getTDICase,
    getAllTDICases,

    // ====================== TDI SF ======================
    getTDISFCase,
    getAllTDISFCases,

    // ====================== System Accounts ======================
    searchUserAccounts,
    searchUserAccountsAxios,
    searchUserAccountsByOrderAxios,

    // ====================== Account Information ======================
    getAccount,
    getAllRelatedContacts,
    getAllAccounts,

    // ====================== Account Information ======================
    getWCCases,
    getWCCaseById,

    // ====================== Portal User ======================
    getAllAccountPortalUsers,

    // ====================== Decisions ======================
    getDecisions,

    // ====================== TDI-46s ======================
    getTDI46,
    getAllTDI46s,

    // ====================== Hearings ======================
    getAllTDICoverages,

    // ====================== Hearings ======================
    getAllHearings,

    // ====================== DCD Cases ======================
    getAllDCDCases,
    getDCDCase,

    //====================== TDI-30s ======================
    getAllTDI30,

    //====================== TDI-Forms ======================
    createTDIForm,
    editTDIForm,
    getTDIForm,
    deleteTDIForm,
    getAllTDIForms,
    getAllTDIDuplicatedCases,

    // ====================== WC-3s ======================
    getAllWC3s,
    getWC3,

    // ====================== WC-3As ======================
    getAllWC3As,
    getWC3ADetail,

    // ====================== WC5 ======================
    getAllWC5s,
    getWC5Detail,

    // ====================== WC-5as ======================
    getAllWC5As,
    getWC5ADetail,

    // ====================== WC-77s ======================
    getAllWC77s,
    getWC77Detail,

    // ====================== WC-14s ======================
    getWC14Detail,

    // ====================== WC - 1s ======================
    getAllWC1s,
    getWC1Detail,

    // ====================== WC - 2s ======================
    getWC2,
    getAllWC2s,

    // ====================== WC-14s ======================
    getAllWC14s,

    // ====================== WC-Insurance-Policies ======================
    getAllWCIP,
    getInsurancePolicy,

    // ====================== WC Coverages ======================
    getAllWCCoverages,

    // ====================== WC-Form ======================
    getAllWCForms,
    createWCForm,
    editWCForm,
    getWCForm,
    deleteWCForm,
    getAllWC1DuplicatedCases,

    // ====================== DASHBOARD ======================
    getAllGlobal,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
