export enum PortalUserKey {
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  Account = 'account',
  AccountName = 'accountName',
  FullName = 'fullName',
  PortalUserClassification = 'portalUserClassification',
  ContactNumber = 'contactNumber',
  Email = 'email',
  Status = 'status',
  AccountId = 'accountId',
  Username = 'username',

  PortalUserTypeId = 'portalUserTypeId',
  PortalUserProgramTypes = 'portalUserProgramTypes',
  ProgramTypes = 'programTypes',
  PortalUserType = 'portalUserType',
  UserId = 'userId',
  ProgramTypeId = 'programTypeId',
  PortalUserClassificationId = 'portalUserClassificationId',
  PortalReviewComments = 'portalReviewComments',
}
