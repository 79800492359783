export enum TDI46FormKey {
  Id = 'id',
  NewAmend = 'newAmend',
  TdiCaseId = 'tdiCaseId',
  TdiCaseNumber = 'tdiCaseNumber',
  TdiCase = 'tdiCase',

  RawClaimantName = 'rawClaimantName',
  RawClaimantIdType = 'rawClaimantIdType',
  RawClaimantIdNumber = 'rawClaimantIdNumber',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffix = 'rawClaimantSuffix',
  RawClaimantAddress = 'rawClaimantAddress',
  RawClaimantCity = 'rawClaimantCity',
  RawClaimantState = 'rawClaimantState',
  RawClaimantZipcode = 'rawClaimantZipcode',
  RawClaimantEmail = 'rawClaimantEmail',
  RawClaimantPhone = 'rawClaimantPhone',
  RawClaimantFirstDateOfDisabilityClaimed = 'rawClaimantFirstDateOfDisabilityClaimed',
  RawClaimantFiledDate = 'rawClaimantFiledDate',
  RawClaimantDateNoticeSentDLIR = 'rawClaimantDateNoticeSentDLIR',
  RawClaimantDateNoticeSentClaimant = 'rawClaimantDateNoticeSentClaimant',
  RawClaimantClaimOrFileNumber = 'rawClaimantClaimOrFileNumber',

  IsDenyFailureToMeetEligibility = 'isDenyFailureToMeetEligibility',
  IsDenyDidNotPerformRegularService = 'isDenyDidNotPerformRegularService',
  IsDenyNotDisabledBeyond7Days = 'isDenyNotDisabledBeyond7Days',
  IsDenyReceived26WeeksOfBenefitPayment = 'isDenyReceived26WeeksOfBenefitPayment',
  Denial26WeeksOfBenefitStartDate = 'denial26WeeksOfBenefitStartDate',
  Denial26WeeksOfBenefitEndDate = 'denial26WeeksOfBenefitEndDate',
  IsDenyLateFillingAfter90Days = 'isDenyLateFillingAfter90Days',
  DenialLateFillingClaimedFiledOn = 'denialLateFillingClaimedFiledOn',
  DenialLateFillingNoBenefitArePayable = 'denialLateFillingNoBenefitArePayable',
  IsPaymentStart14DaysBeforeFileDate = 'isPaymentStart14DaysBeforeFileDate',
  IsDenyClaimingBenefitUnderWCLaw = 'isDenyClaimingBenefitUnderWCLaw',
  IsDenyNotDisabledAccordingMedicalRecord = 'isDenyNotDisabledAccordingMedicalRecord',
  AbleToPerformRegularWorkOnDate = 'ableToPerformRegularWorkOnDate',
  PaymentOfBenefitIsDeniedAfterDate = 'paymentOfBenefitIsDeniedAfterDate',
  IsDenyMedCertDoesntEstablishUnableToWork = 'isDenyMedCertDoesntEstablishUnableToWork',
  IsDenyNotUnderCareOfPhysician = 'isDenyNotUnderCareOfPhysician',
  NotUnderCarePhysicianStartDate = 'notUnderCarePhysicianStartDate',
  NotUnderCarePhysicianEndDate = 'notUnderCarePhysicianEndDate',
  NotUnderCarePhysicianNoBenefitArePayable = 'notUnderCarePhysicianNoBenefitArePayable',
  NotUnderCarePhysicianPaymentWillStart = 'notUnderCarePhysicianPaymentWillStart',
  NotUnderCarePhysicianPaymentWillStartOn = 'notUnderCarePhysicianPaymentWillStartOn',
  IsDenyBenefitEntitledUnderUnionContract = 'isDenyBenefitEntitledUnderUnionContract',
  IsDenyNotInsuranceCarrierForEmployer = 'isDenyNotInsuranceCarrierForEmployer',
  NotCarrierForEmplIsClaimForwarded = 'notCarrierForEmplIsClaimForwarded',
  NotCarrierForEmplIsClaimReturned = 'notCarrierForEmplIsClaimReturned',
  NotCarrierForEmplClaimForwardedTo = 'notCarrierForEmplClaimForwardedTo',
  IsDenyOtherReason = 'isDenyOtherReason',
  DenialOtherReasons = 'denialOtherReasons',

  RawEmployerDolNumber = 'rawEmployerDolNumber',
  RawEmployerName = 'rawEmployerName',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerState = 'rawEmployerState',
  RawEmployerZipcode = 'rawEmployerZipcode',

  RawCarrierName = 'rawCarrierName',
  RawCarrierId = 'rawCarrierId',
  RawCarrierClaimAdjustingServiceName = 'rawCarrierClaimAdjustingServiceName',
  RawCarrierAdjustingAddress = 'rawCarrierAdjustingAddress',
  RawCarrierCity = 'rawCarrierCity',
  RawCarrierState = 'rawCarrierState',
  RawCarrierZipcode = 'rawCarrierZipcode',
  RawCarrierPointOfContactName = 'rawCarrierPointOfContactName',
  RawCarrierPhone = 'rawCarrierPhone',
  RawCarrierEmail = 'rawCarrierEmail',

  RepresentativeName = 'representativeName',
  RepresentativeTitle = 'representativeTitle',

  SignatureEmail = 'signatureEmail',
  Signature = 'signature',
  SignaturePrintedName = 'signaturePrintedName',

  ClaimantAppeal = 'claimantAppeal',

  SignatureClaimantLastName = 'signatureClaimantLastName',
  SignatureClaimantFirstName = 'signatureClaimantFirstName',
  SignatureClaimantMiddleInitial = 'signatureClaimantMiddleInitial',
  SignatureClaimantSuffix = 'signatureClaimantSuffix',
  SignatureClaimantReceiveDenialNoticeDate = 'signatureClaimantReceiveDenialNoticeDate',
  SignatureClaimantEmail = 'signatureClaimantEmail',
  SignatureClaimantDate = 'signatureClaimantDate',

  // Additional
  DenialReasons = 'denialReasons',

  DateReceived = 'dateReceived',
  RawClaimantSSN = 'rawClaimantSSN',
  RawClaimantPassport = 'rawClaimantPassport',
  MostRecentTDI46 = 'mostRecentTDI46',
  IsTdiCaseTouched = 'isTdiCaseTouched',
  SignatureDate = 'signatureDate',

  RawEmployer = 'rawEmployer',

  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  TdiFormId = 'tdiFormId',
}

export enum IdentificationType {
  SSN = 'SSN',
  Passport = 'PASSPORT',
}

export const identificationTypeOptions = [
  { value: IdentificationType.SSN, label: 'Social Security Number' },
  { value: IdentificationType.Passport, label: 'Passport' },
];
