export enum WC14Key {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  RawNewAmend = 'rawNewAmend',
  RawDateReceived = 'rawDateReceived',
  WcCaseId = 'wcCaseId',
  CaseNumber = 'caseNumber',
  RawEmployerLiableForInjury = 'rawEmployerLiableForInjury',
  RawEmployeeName = 'rawEmployeeName',
  RawEmployeeIdType = 'rawEmployeeIdType',
  RawEmployeeIdNumber = 'rawEmployeeIdNumber',
  RawDateOfInjury = 'rawDateOfInjury',
  RawEmployeeOccupation = 'rawEmployeeOccupation',
  RawHourlyRate = 'rawHourlyRate',
  RawDateHired = 'rawDateHired',
  RawPresentlyEmployed = 'rawPresentlyEmployed',
  RawTerminationDate = 'rawTerminationDate',
  RawDateDisabilityBegan = 'rawDateDisabilityBegan',
  RawDateDisabilityEnded = 'rawDateDisabilityEnded',
  RawDateReturnedToWork = 'rawDateReturnedToWork',
  RawWorkSunday = 'rawWorkSunday',
  RawWorkMonday = 'rawWorkMonday',
  RawWorkTuesday = 'rawWorkTuesday',
  RawWorkWednesday = 'rawWorkWednesday',
  RawWorkThursday = 'rawWorkThursday',
  RawWorkFriday = 'rawWorkFriday',
  RawWorkSaturday = 'rawWorkSaturday',
  RawIndicateOther = 'rawIndicateOther',
  RawEmployerName = 'rawEmployerName',
  RawEmployerPhone = 'rawEmployerPhone',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerAddressLine2 = 'rawEmployerAddressLine2',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerState = 'rawEmployerState',
  RawEmployerZip = 'rawEmployerZip',
  RawPayrollInformation = 'rawPayrollInformation',
  RawSignature = 'rawSignature',
  RawSignatureDate = 'rawSignatureDate',
  RawSignatureTitle = 'rawSignatureTitle',
  DaysAndHrsWork = 'daysAndHrsWork',
  RecentWC14 = 'recentWC14',
  RawGrandAmountPaidOvertimeHours = 'rawGrandAmountPaidOvertimeHours',
  RawGrandAmountPaidRegularHours = 'rawGrandAmountPaidRegularHours',
  RawGrandTotalAmountPaid = 'rawGrandTotalAmountPaid',
  RawGrandTotalOvertimeHoursWorked = 'rawGrandTotalOvertimeHoursWorked',
  RawGrandTotalRegularHoursWorked = 'rawGrandTotalRegularHoursWorked',
  ClaimantName = 'claimantName',
  EmployerName = 'employerName',
  FormNumber = 'formNumber',
  CreatedUser = 'createdUser',
  RawAverageWeeklyWage = 'rawAverageWeeklyWage',
}

export enum PayrollInformationKey {
  LineNumber = 'lineNumber',
  PeriodStart = 'periodStart',
  PeriodEnd = 'periodEnd',
  DaysInPeriod = 'daysInPeriod',
  TotalRegularHoursWorked = 'totalRegularHoursWorked',
  TotalOvertimeHoursWorked = 'totalOvertimeHoursWorked',
  AmountPaidRegularHours = 'amountPaidRegularHours',
  AmountPaidOvertimeHours = 'amountPaidOvertimeHours',
  TotalAmountPaid = 'totalAmountPaid',
}
