import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { isEmpty } from 'src/modules/shared-main/common';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { WC5A } from './types';

export function useGetAllWC5As(
  options?: UseQueryOptions<PaginationResponseType<WC5A>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWC5As,
  } = useQuery<PaginationResponseType<WC5A>, Error>(
    [
      API_QUERIES.WC5As,
      {
        ...params,
        ...options,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<WC5A>>(apiClient.getAllWC5As, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !isEmpty(params),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllWC5As = () => queryClient.invalidateQueries(API_QUERIES.WC5As);

  const { data: wc5As, hasNext, payloadSize, totalRecords } = data || {};
  return {
    wc5As,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllWC5As,
    setParams,
    handleInvalidateAllWC5As,
  };
}
