import { formatTDICaseNumber } from 'src/modules/shared-main/common';
import { PaginationResponseType } from '../helpers';
import { TDISFCaseKey } from './keys';
import { TDISFCase } from './types';

export const toTDISFCases = (data): PaginationResponseType<TDISFCase> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [TDISFCaseKey.Employer]: item?.employer?.accountName,
    [TDISFCaseKey.Claimant]: item?.claimant?.accountName,
    [TDISFCaseKey.Status]: item?.caseStatus,
  })),
});

export const toTDICaseDetail = (data: TDISFCase) => ({
  ...data,
  [TDISFCaseKey.TDISFCaseFormattedNumber]: formatTDICaseNumber(data?.tdiSFCaseNumber),
  [TDISFCaseKey.ClaimantName]: data?.claimant?.accountName,
  [TDISFCaseKey.EmployerName]: data?.employer?.accountName,
});
