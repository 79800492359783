import cn from 'classnames';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { MenuItem } from 'src/appConfig/sidebar';
import { Image, Link, View } from 'src/modules/shared-main/components';
import { hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { IRootState } from 'src/redux/store';
import { Navigator } from '../../services';

const SidebarItem: React.FC<Props> = ({ item }) => {
  const location = useLocation();
  const { collapseSidebar, showMiniSidebar } = useSelector((state: IRootState) => state.common);
  const dispatch = useDispatch();

  const shouldHighlightItem = (menuItem: MenuItem): boolean => {
    if (
      matchPath(location.pathname, {
        path: menuItem.href,
        exact: false,
        strict: false,
      })
    ) {
      return true;
    }

    if (menuItem.subMenu) {
      for (const subMenuItem of menuItem.subMenu) {
        if (shouldHighlightItem(subMenuItem)) {
          return true;
        }
      }
    }

    return false;
  };

  const showContentDialog = () => {
    dispatch(
      showDialog({
        type: DIALOG_TYPES.OK_DIALOG,
        data: {
          title: 'Oops!',
          content: <View align="center">This feature is temporarily unavailable</View>,
          cancelText: 'Cancel',
          okText: 'Ok',
          onCancel: () => dispatch(hideDialog()),
          onOk: () => dispatch(hideDialog()),
        },
      })
    );
  };

  return (
    <Link
      href={null}
      className={cn(
        'cmp-sidebar__item', // Base class
        {
          'cmp-sidebar__item--closed': collapseSidebar && !showMiniSidebar, // Add closed class conditionally
          'cmp-sidebar__item--active': shouldHighlightItem(item), // Add active class conditionally
        }
      )}
      onClick={() => {
        if (!item.href) showContentDialog();
        else {
          Navigator.navigate(item.href);
        }
      }}
    >
      {item.iconSrc ? (
        <View
          className={cn('cmp-sidebar__item__icon', {
            'cmp-sidebar__item__icon--active': shouldHighlightItem(item),
          })}
        >
          <Image src={item.iconSrc} className={cn('cmp-sidebar__item__icon')} />
        </View>
      ) : (
        <View className="ctn-dashboard-list__icon">
          <View
            className={cn('cmp-sidebar__item__icon', {
              'cmp-sidebar__item__icon--active': shouldHighlightItem(item),
            })}
          >
            {item.icon}
          </View>
        </View>
      )}
      {item.title ? (
        <View
          className={cn('cmp-sidebar__item__text', {
            'cmp-sidebar__item__text--closed': collapseSidebar && !showMiniSidebar,
            'cmp-sidebar__item__subtext': !item?.isParentMenu,
            'cmp-sidebar__item__text--active': shouldHighlightItem(item), // Apply active class to text
          })}
        >
          {item.title}
        </View>
      ) : null}
    </Link>
  );
};

type Props = {
  item: MenuItem;
};

export default SidebarItem;
