import { useMutation, UseMutationOptions } from 'react-query';
import { responseWrapper } from 'src/modules/shared-main/queries/helpers';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType } from '../helpers';
import { TDIFormPayload } from './types';

export function useCreateTDIForm(
  options?: UseMutationOptions<ApiResponseType<{ id: string }>, Error, TDIFormPayload>
) {
  const { mutate: createTDIForm, isLoading } = useMutation<
    ApiResponseType<{ id: string }>,
    Error,
    TDIFormPayload
  >({
    mutationFn: (payload: TDIFormPayload) => responseWrapper(apiClient.createTDIForm, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDIForm,
    isLoading,
  };
}
