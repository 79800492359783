import React, { useState } from 'react';
import './styles.scss';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const NavSearch: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <SearchIcon />
          </IconButton>
        )}

        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <div className="ctn-navbar__search">
            <Input
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              <SearchIcon />
            </Button>
          </div>
        </Slide>
      </div>
    </ClickAwayListener>
  );
};

export default NavSearch;
