import { AppBar, Stack, Toolbar } from '@mui/material';
import { Location } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { MenuItem, sidebarList } from 'src/appConfig/sidebar';
import { Notification, Text } from 'src/modules/shared-main/components';
import { IRootState } from 'src/redux/rootReducer';
import NavAvatar from './NavAvatar';
import NavSearch from './NavSearch';
import './styles.scss';

const Navbar: React.FC<Props> = ({ location }) => {
  const { showNavbar, showSidebar, collapseSidebar } = useSelector(
    (state: IRootState) => state.common
  );
  const sidebarWidth = showSidebar ? (collapseSidebar ? 80 : 253) : 0;

  const getTitle = (menuItems: MenuItem[] = sidebarList): string => {
    let foundTitle: string | undefined = undefined;

    menuItems.forEach(({ href, subMenu, title }) => {
      if (subMenu) {
        const subMenuTitle = getTitle(subMenu);
        if (subMenuTitle) {
          foundTitle = subMenuTitle;
          return;
        }
      }
      if (
        matchPath(location.pathname, {
          path: href,
          exact: false,
          strict: false,
        })
      ) {
        foundTitle = title;
      }
    });

    return foundTitle;
  };

  if (!showNavbar) return null;

  return (
    <AppBar
      variant="elevation"
      position="fixed"
      sx={{
        width: `calc(100% - ${sidebarWidth}px)`,
        transition: '.628s',
        backgroundColor: 'var(--background)',
        boxShadow: 'none',
        height: '64px',
        zIndex: '1201',
      }}
    >
      <Toolbar variant="regular">
        <Stack width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
          <Stack flexDirection={'row'} alignItems="center">
            <Text size={26} className="ctn-navbar__text">
              {getTitle()}
            </Text>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'}>
            <NavSearch />
            <Notification />
            <NavAvatar pathname={location.pathname} />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

type Props = { location: Location };

export default Navbar;
