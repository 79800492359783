import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from '../Properties';
import { Hearing } from './types';

export function useGetAllRelatedHearings(
  options?: UseQueryOptions<PaginationResponseType<Hearing>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedHearings,
  } = useQuery<PaginationResponseType<Hearing>, Error>(
    [
      API_QUERIES.Hearings,
      {
        ...params,
        ...options,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Hearing>>(apiClient.getAllHearings, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: true,
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedHearings = () =>
    queryClient.invalidateQueries([API_QUERIES.Hearings]);

  const { data: relatedHearings, hasNext, totalRecords, payloadSize } = data || {};

  return {
    relatedHearings,
    error,
    hasNext,
    totalRecords,
    payloadSize,
    isError,
    isFetching,
    onGetAllRelatedHearings,
    setParams,
    handleInvalidateAllRelatedHearings,
  };
}
