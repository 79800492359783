import { WC1 } from 'src/queries/WC1';
import { WC3As } from 'src/queries/WC3A';
import { Account } from '../Account';
import { WC14 } from '../WC14';
import { WC3 } from '../WC3';
import { DCDCaseKey } from './keys';

export enum DCDCasePortalStatus {
  Inprogress = 'In Progress',
  New = 'New',
  Denied = 'Denied',
}

export interface DCDCase {
  [DCDCaseKey.Id]: string;
  [DCDCaseKey.CaseNumber]: string;
  [DCDCaseKey.RecentWC3]: WC3;
  [DCDCaseKey.RecentWC3A]: WC3As;
  [DCDCaseKey.PortalStatus]: DCDCasePortalStatus;
  [DCDCaseKey.PartyInsuranceCarrier]: Account;
  [DCDCaseKey.PartyAdjustingCompany]: Account;
  [DCDCaseKey.PartyClaimant]: Account;
  [DCDCaseKey.PartyInsuranceCarrier]: Account;
  [DCDCaseKey.PartyEmployer]: Account;
  [DCDCaseKey.Claimant]: Account;
  [DCDCaseKey.Employer]: Account;
  [DCDCaseKey.EmployerName]: string;
  [DCDCaseKey.ClaimantName]: string;
  [DCDCaseKey.Status]: string;
  [DCDCaseKey.DateOfInjury]: string;
  [DCDCaseKey.DateOfInjury]: string;
  [DCDCaseKey.ClaimantPhoneNumber]: string;
  [DCDCaseKey.StatusLastChangedDate]: string;
  [DCDCaseKey.ClaimEndedDate]: string;
  [DCDCaseKey.CaseOpenedDate]: string;
  [DCDCaseKey.CreatedByUser]: string;
  [DCDCaseKey.LastModifiedDate]: string;
  [DCDCaseKey.DateDisabilityBegan]: string;
  [DCDCaseKey.UpdatedBy]: string;
  [DCDCaseKey.CreatedBy]: string;
  [DCDCaseKey.RecentWC14]: WC14;
  [DCDCaseKey.RecentWC1]: WC1;
}
