export enum ProfileKey {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  FullName = 'fullName',
  Username = 'username',
  Email = 'email',
  UserType = 'userType',
  Status = 'status',
  ContactNumber = 'contactNumber',
  PortalUserTypeId = 'portalUserTypeId',
  PortalUserClassificationId = 'portalUserClassificationId',
  PortalReviewComments = 'portalReviewComments',
  Signature = 'signature',
  Avatar = 'avatar',
  AccountId = 'accountId',
  HasWC = 'hasWC',
  HasPHC = 'hasPHC',
  HasTDI = 'hasTDI',
  OrgInfo = 'orgInfo',
  PortalUserProgramTypes = 'portalUserProgramTypes',
  PortalUserType = 'portalUserType',
  PortalUserClassification = 'portalUserClassification',
  AdjustingServicesName = 'adjustingServicesName',
  AdjustingServices = 'adjustingServices',
  ThirdPartyAdminType = 'thirdPartyAdminType',
  EmployerName = 'employerName',
  NameOfParentCompany = 'nameOfParentCompany',
  OrganizationType = 'organizationType',
  NatureOfBusiness = 'natureOfBusiness',
  MailingZipCode = 'mailingZipCode',
  MailingState = 'mailingState',
  MailingCity = 'mailingCity',
  MailingAddress = 'mailingAddress',
  FederalIdNumber = 'federalIdNumber',
  ZipCode = 'zipCode',
  Address = 'address',
  City = 'city',
  State = 'state',
  InsuranceCarrierName = 'insuranceCarrierName',
  AdjusterId = 'adjusterId',
  IsThirdPartyAdmin = 'isThirdPartyAdmin',
}

export enum OrgInfoKey {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  AdjusterId = 'adjusterId',
  InsuranceCarrierName = 'insuranceCarrierName',
  FederalIdNumber = 'federalIdNumber',
  Address = 'address',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  ContactNumber = 'contactNumber',
  IsThirdPartyAdmin = 'isThirdPartyAdmin',
  ThirdPartyAdminType = 'thirdPartyAdminType',
  EmployerName = 'employerName',
  CompanyPhoneNumber = 'companyPhoneNumber',
  NameOfParentCompany = 'nameOfParentCompany',
  MailingAddress = 'mailingAddress',
  MailingCity = 'mailingCity',
  MailingState = 'mailingState',
  MailingZipCode = 'mailingZipCode',
  NatureOfBusiness = 'natureOfBusiness',
  OrganizationType = 'organizationType',
  AdjustingServicesName = 'adjustingServicesName',
  AdjustingServices = 'adjustingServices',
  OrgContactNumber = 'orgContactNumber',
}

export enum ThirdPartyAdminType {
  SelfInsured = 'Self-Insured',
  AdjustingCompany = 'Adjusting Company',
}

export enum PortalUserType {
  Employer = 'ER',
  SelfInsured = 'SI',
  Employee = 'EE',
  Dependent = 'DP',
  Spouse = 'SP',
  Carrier = 'CR',
  Adjuster = 'AJ',
  Other = 'OT',
  LegalGuardian = 'LG',
  AttorneyForClaimant = 'AC',
  AttorneyForEmployer = 'AE',
  AttorneyGeneralOffice = 'AT',
  HealthCareContractor = 'HC',
  Physical = 'PS',
  BillDispute = 'BD',
  Claimant = 'CL',
  VocationalRehabilitationCounselor = 'VRC',
}

export enum ProgramType {
  PHC = 'PHC',
  TDI = 'TDI',
  WC = 'WC',
}

export enum OrganizationType {
  Corporation = 'Corporation',
  PartnerShip = 'Partnership',
  SoleProprietor = 'Sole Proprietor',
  Other = 'Other',
}
export enum SiOrAdjustCompanyType {
  SelfInsured = 'Self-Insured',
  AdjustingCompany = 'Adjusting Company',
}

export enum ChangeSignatureType {
  UpLoadSignature = 'UpLoadSignature',
  DrawSignature = 'DrawSignature',
}

export const changeSignatureOptions = [
  {
    value: ChangeSignatureType.UpLoadSignature,
    label: 'Upload Signature',
  },
  {
    value: ChangeSignatureType.DrawSignature,
    label: 'Draw Signature',
  },
];

export const organizationTypeOptions = [
  { value: OrganizationType.Corporation, label: 'Corporation' },
  { value: OrganizationType.PartnerShip, label: 'PartnerShip' },
  { value: OrganizationType.SoleProprietor, label: 'Sole Proprietor' },
  { value: OrganizationType.Other, label: 'Other' },
];

export const SiOrAdjustCompanyOptions = [
  { value: SiOrAdjustCompanyType.SelfInsured, label: 'Self-Insured' },
  { value: SiOrAdjustCompanyType.AdjustingCompany, label: 'Adjusting Company' },
];
