import { Location } from 'history';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import { PATHS } from 'src/appConfig/paths';
import Navbar from 'src/components/Navbar';
import Sidebar from 'src/components/Sidebar';
import { useComponentDidMount } from 'src/hooks';
import { LoadingCommon } from 'src/modules/shared-main/components';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator, TenantService } from 'src/services';
import Dev from './Dev';
import AuthContainer from './StartupContainers/AuthContainer';
import DialogContainer from './StartupContainers/DialogContainer';
import LoadingContainer from './StartupContainers/LoadingContainer';
import NotFound from './StartupContainers/NotFound';
import ResponsiveContainer from './StartupContainers/ResponsiveContainer';
import ScrollToTop from './StartupContainers/ScrollToTop';
import SplashScreen from './StartupContainers/SplashScreen';
import ToastContainer from './StartupContainers/ToastContainer';
import UploadProgressContainer from './StartupContainers/UploadProgressContainer';

import { useSelector } from 'react-redux';
import PermissionRestrict from 'src/modules/shared-main/components/PermissionRestrict';
import { ProgramType } from 'src/queries';

const WCManagement = React.lazy(() => import('./WCManagement'));
const AccountManagement = React.lazy(() => import('./AccountManagement'));
const HomePage = React.lazy(() => import('./Homepage'));
const TDIManagement = React.lazy(() => import('./TDIManagement'));
const MyProfile = React.lazy(() => import('./MyProfile'));
const UpLoadFileManagement = React.lazy(() => import('./UploadFileManagement'));

const Routing: React.FC<RoutingProps> = ({ showSidebar, collapseSidebar }) => {
  Navigator.setTopHistory(useHistory());

  const location = useLocation();

  const isHomePage = location.pathname === PATHS.dashboard;

  useComponentDidMount(() => {
    const currentWebTenant = TenantService.getWebTenant();
    TenantService.setTenant({ name: currentWebTenant });
  });

  const sidebarWidth = showSidebar ? (collapseSidebar ? 80 : 253) : 0;

  return (
    <Box pt={5} m={3} mr={1}>
      {!isHomePage && <Navbar location={location} />}
      <Sidebar />
      <Suspense
        fallback={
          <Box p={4}>
            <LoadingCommon />
          </Box>
        }
      >
        <Box
          sx={{
            marginLeft: `${sidebarWidth}px`,
          }}
        >
          <Switch location={location}>
            <Route path={PATHS.root} render={() => <Redirect to={PATHS.homepage} />} exact />
            <CustomRoute pageRequiredAuth path={PATHS.homepage} component={HomePage} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.fileUpload}
              component={UpLoadFileManagement}
            />
            <CustomRoute pageRequiredAuth path={PATHS.myProfile} component={MyProfile} />
            <CustomRoute pageRequiredAuth path={PATHS.tdi} component={TDIManagement} />
            <CustomRoute pageRequiredAuth path={PATHS.account} component={AccountManagement} />
            <CustomRoute
              pageRequiredAuth
              path={PATHS.wc}
              component={WCManagement}
              programType={ProgramType.WC}
            />
            <Route path={PATHS.dev} component={Dev} />
            <Route component={NotFound} />
          </Switch>
        </Box>
      </Suspense>
      <ScrollToTop />
      <AuthContainer />
      <LoadingContainer />
      <DialogContainer />
      <ToastContainer />
      <ResponsiveContainer />
      <UploadProgressContainer />
    </Box>
  );
};

type RoutingProps = ReturnType<typeof routeMapStateToProps> &
  typeof routeMapDispatchToProps & { location: Location };

const routeMapStateToProps = (state: IRootState) => ({
  showSidebar: state.common.showSidebar,
  collapseSidebar: state.common.collapseSidebar,
});

const routeMapDispatchToProps = {};

export default connect(routeMapStateToProps, routeMapDispatchToProps)(Routing);

const CRouting: React.FC<Props> = ({ pageRequiredAuth, component, programType, ...rest }) => {
  const isAuthenticated = useSelector((state: IRootState) => state.auth.isAuthenticated);
  const portalUserProgramTypes = useSelector(
    (state: IRootState) => state.auth.user?.portalUserProgramTypes
  );

  const renderRoute = (Component: any) => (props: RouteProps) => {
    if (isAuthenticated === null) return <SplashScreen />;

    if ((isAuthenticated && pageRequiredAuth) || (!isAuthenticated && !pageRequiredAuth)) {
      const permissions = portalUserProgramTypes
        ?.filter((item) => item?.isPermitted)
        .map((item) => item.programTypeId);

      if (programType && !permissions.includes(programType)) return <PermissionRestrict />;

      return <Component {...props} />;
    }

    const redirectPath = PATHS.home;
    const redirectProps = {
      to: {
        pathname: redirectPath,
        state: { from: props.location },
      },
    };
    return <Redirect {...redirectProps} />;
  };

  return <Route {...rest} render={renderRoute(component)} />;
};

type Props = RouteProps & { pageRequiredAuth?: boolean; programType?: ProgramType };

const CustomRoute = CRouting;
