import { PaginationResponseType } from '../helpers';
import { PortalUserKey } from './keys';
import { RelatedPortalUser } from './types';

export const toAccountPortalUsers = (data): PaginationResponseType<RelatedPortalUser> => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    [PortalUserKey.ProgramTypes]: item?.[PortalUserKey.PortalUserProgramTypes]
      ?.map((item) => item?.id)
      ?.join(', '),
  })),
});
