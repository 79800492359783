export enum TDICoverageKey {
  Employer = 'employer',
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  LaborNumber = 'laborNumber',
  PlanType = 'planType',
  ActionTaken = 'actionTaken',
  EffectiveDate = 'effectiveDate',
  EmployeeClassification = 'employeeClass',
  // ========= filter key =========
  LaborNumberFilterKey = 'employer.laborNumber',
  PlanTypeFilterKey = 'tdiPlanType.planType',
}
