export enum WCFormKey {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  RecordNum = 'recordNum',
  DateSubmitted = 'dateSubmitted',
  FormType = 'formType',
  FormStatus = 'formStatus',
  WC1 = 'wc1',
  WC3A = 'wc3a',
  WC3 = 'wc3',
  WC77 = 'wc77',
  WC14 = 'wc14',
  WC77A = 'wc77a',
  FormNumber = 'wcFormNumber',

  ClaimantName = 'claimantName',
  CaseId = 'wcCase.id',
  ClaimantLastName = 'claimantLastName',
  ClaimantFirstName = 'claimantFirstName',
  CaseNumber = 'wcCase',
  DateOfInjury = 'dateOfInjury',
  WC1EmployeeLastName = 'employeeLastName',
  WC1EmployeeFirstName = 'employeeFirstName',
  RegisteredEmployer = 'registeredEmployer',
  insuranceCarrier = 'insuranceCarrier',
  UpdatedBy = 'updatedBy',
  WC3AInsuranceCarrierName = 'wc3a.rawInsuranceCarrierName',
  WC3Type = 'wc3.rawWc3Type',
  WC3PaymentYear = 'wc3.paymentYear',
}

export enum WC1DuplicatedCaseKey {
  AdjustingCompany = 'adjustingCompany',
  CaseNumber = 'caseNumber',
  ClaimantFullName = 'claimantFullName',
  DateOfII = 'dateOfInjury',
  Employer = 'employer',
  InsuranceCarrier = 'insuranceCarrier',
}
