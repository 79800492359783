export enum WC3AKey {
  Id = 'id',
  CaseId = 'caseId',
  FormNumber = 'formNumber',
  RawDateReceived = 'rawDateReceived',
  Case = 'case',
  Claimant = 'claimant',
  Employer = 'employer',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',

  CaseNumber = 'caseNumber',
  DCDCase = 'dcdCase',
  EmployerName = 'employerName',

  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  RawHeaderCaseNumber = 'rawHeaderCaseNumber',
  RawPrinterName = 'rawPrinterName',
  RawPrinterPhoneNumber = 'rawPrinterPhoneNumber',
  RawPrinterEmailAddress = 'rawPrinterEmailAddress',
  RawPrinterSignatureDate = 'rawPrinterSignatureDate',
  ClaimantId = 'claimantId',
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffix = 'rawClaimantSuffix',
  RawClaimantAddress = 'rawClaimantAddress',
  RawClaimantCity = 'rawClaimantCity',
  RawClaimantState = 'rawClaimantState',
  RawClaimantZipcode = 'rawClaimantZipcode',
  RawClaimantEmail = 'rawClaimantEmail',
  RawClaimantPhone = 'rawClaimantPhone',
  RawClaimantSocialSecurityNumber = 'rawClaimantSocialSecurityNumber',
  RawClaimantPassportNumber = 'rawClaimantPassportNumber',
  RawClaimantWeeklyCompensationRate = 'rawClaimantWeeklyCompensationRate',
  RawClaimantDateOfInjury = 'rawClaimantDateOfInjury',
  RawClaimantCompensationRate = 'rawClaimantCompensationRate',
  EmployerId = 'employerId',
  RawRegisteredEmployerName = 'rawRegisteredEmployerName',
  RawEmployerDbaName = 'rawEmployerDbaName',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerZipcode = 'rawEmployerZipcode',
  RawEmployerState = 'rawEmployerState',
  RawEmployerEmail = 'rawEmployerEmail',
  RawEmployerPhone = 'rawEmployerPhone',
  RawEmployerPointOfContact = 'rawEmployerPointOfContact',
  InsuranceCarrierId = 'insuranceCarrierId',
  RawInsuranceCarrierId = 'rawInsuranceCarrierId',
  RawInsuranceCarrierName = 'rawInsuranceCarrierName',
  RawCarrierCaseNumber = 'rawCarrierCaseNumber',
  AdjusterId = 'adjusterId',
  RawAdjusterId = 'rawAdjusterId',
  RawAdjusterName = 'rawAdjusterName',
  RawAdjusterEmail = 'rawAdjusterEmail',
  RawAdjusterPhone = 'rawAdjusterPhone',
  RawAdjusterAddress = 'rawAdjusterAddress',
  RawAdjusterCity = 'rawAdjusterCity',
  RawAdjusterState = 'rawAdjusterState',
  RawAdjusterZipcode = 'rawAdjusterZipcode',
  RawAdjusterBenefitAdjustmentReimbursement = 'rawAdjusterBenefitAdjustmentReimbursement',
  RawInsuranceCarrierComment = 'rawInsuranceCarrierComment',
  PhysicianId = 'physicianId',
  EmployerPointOfContactId = 'employerPointOfContactId',
  AdjustingCompanyId = 'adjustingCompanyId',
  Physician = 'physician',
  Adjuster = 'adjuster',
  AdjustingCompany = 'adjustingCompany',
  InsuranceCarrier = 'insuranceCarrier',

  RawNewAmend = 'rawNewAmend',

  WcCaseId = 'wcCaseId',
  RawSignature = 'rawSignature',
  NewAmend = 'newAmend',
  RawClaimantIdNumber = 'rawClaimantIdNumber',
  RawClaimantIdType = 'rawClaimantIdType',
  RawOnBehalfOf = 'rawOnBehalfOf',
  RawAdjustingCompanyId = 'rawAdjustingCompanyId',
  RawAdjustingCompanyName = 'rawAdjustingCompanyName',
  RawAdjustingCompanyEmail = 'rawAdjustingCompanyEmail',
  RawAdjustingCompanyPhone = 'rawAdjustingCompanyPhone',
  RawAdjustingCompanyAddress = 'rawAdjustingCompanyAddress',
  RawAdjustingCompanyCity = 'rawAdjustingCompanyCity',
  RawAdjustingCompanyState = 'rawAdjustingCompanyState',
  RawAdjustingCompanyZipcode = 'rawAdjustingCompanyZipcode',
  ScfReimbursementRequestPayment = 'scfReimbursementRequestPayment',
  ReimbursementGrandTotal = 'reimbursementGrandTotal',
  ReimbursementPeriod1From = 'reimbursementPeriod1From',
  ReimbursementPeriod1To = 'reimbursementPeriod1To',
  ReimbursementPeriod1WeeklyRate = 'reimbursementPeriod1WeeklyRate',
  ReimbursementPeriod1TotalRequested = 'reimbursementPeriod1TotalRequested',
  ReimbursementPeriod2From = 'reimbursementPeriod2From',
  ReimbursementPeriod2To = 'reimbursementPeriod2To',
  ReimbursementPeriod2WeeklyRate = 'reimbursementPeriod2WeeklyRate',
  ReimbursementPeriod2TotalRequested = 'reimbursementPeriod2TotalRequested',
  ReimbursementPeriod3From = 'reimbursementPeriod3From',
  ReimbursementPeriod3To = 'reimbursementPeriod3To',
  ReimbursementPeriod3WeeklyRate = 'reimbursementPeriod3WeeklyRate',
  ReimbursementPeriod3TotalRequested = 'reimbursementPeriod3TotalRequested',
  ReimbursementPeriod4From = 'reimbursementPeriod4From',
  ReimbursementPeriod4To = 'reimbursementPeriod4To',
  ReimbursementPeriod4WeeklyRate = 'reimbursementPeriod4WeeklyRate',
  ReimbursementPeriod4TotalRequested = 'reimbursementPeriod4TotalRequested',
  ReimbursementPeriod5From = 'reimbursementPeriod5From',
  ReimbursementPeriod5To = 'reimbursementPeriod5To',
  ReimbursementPeriod5WeeklyRate = 'reimbursementPeriod5WeeklyRate',
  ReimbursementPeriod5TotalRequested = 'reimbursementPeriod5TotalRequested',
  CarriersComments = 'carriersComments',
  CheckPayableTo = 'checkPayableTo',
  ReimbursementAddress = 'reimbursementAddress',
  ReimbursementCity = 'reimbursementCity',
  ReimbursementState = 'reimbursementState',
  ReimbursementZipcode = 'reimbursementZipcode',
  BenefitAdjusterReimbursement = 'benefitAdjusterReimbursement',
  RecentWC3A = 'recentWC3a',
  WCCase = 'wcCase',
  IsTouchedCase = 'isTouchedCase',
}

export enum BenefitAdjusterReimbursementKey {
  DateRangeTo = 'dateRangeTo',
  DateRangeFrom = 'dateRangeFrom',
  WeeklyRate = 'weeklyRate',
  TotalRequestedForReimbursement = 'totalRequestedForReimbursement',
  GrandTotal = 'grandTotal',
  CarrierComments = 'carrierComments',
  CheckPayableTo = 'checkPayableTo',
  Address = 'address',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
}

export enum WC3ANewAmend {
  New = 'NEW',
  Amend = 'AMEND',
}
