/* eslint-disable security/detect-object-injection */
import { WC3AKey } from 'src/queries/WC3A';
import { WC77AKey } from 'src/queries/WC77A';
import { PaginationResponseType, toAuditData } from '../helpers';
import { WC14Key } from '../WC14';
import { WC3Key } from '../WC3';
import { WCFormKey } from './keys';
import { WCForm, WCformKeyMap, WCFormType } from './types';

export const toWCFormData = (data): PaginationResponseType<WCForm> => {
  const getDateOfInjury = (formType, formData) => {
    if ([WCFormType.WC3, WCFormType.WC3A].includes(formType)) {
      return formData?.rawClaimantDateOfInjury || '';
    }
    if ([WCFormType.WC14, WCFormType.WC77, WCFormType.WC77A].includes(formType)) {
      return formData?.rawDateOfInjury || '';
    }
    return formType === WCFormType.WC1
      ? formData?.rawDiiDate || ''
      : formData?.rawClaimantDateOfInjury || '';
  };

  return {
    ...data,
    data: data?.data?.map((item) => {
      const formData = item?.[WCformKeyMap[item?.formType]];

      const registeredEmployer =
        item?.formType === WCFormType.WC1
          ? formData?.rawRegisteredEmployer || ''
          : item?.formType === WCFormType.WC3A
          ? formData?.rawRegisteredEmployerName || ''
          : formData?.rawEmployerName || '';

      return {
        ...item,
        [WCFormKey.UpdatedBy]: item?.updatedUser?.fullName || '',
        [WCFormKey.ClaimantName]: formData?.claimantName,
        [WCFormKey.CaseNumber]: formData?.wcCase?.caseNumber || '',
        [WCFormKey.DateOfInjury]: getDateOfInjury(item?.formType, formData),
        [WCFormKey.WC1EmployeeLastName]: formData?.rawEmployeeLastName || '',
        [WCFormKey.WC1EmployeeFirstName]: formData?.rawEmployeeFirstName || '',
        [WCFormKey.ClaimantFirstName]: formData?.rawClaimantFirstName || '',
        [WCFormKey.ClaimantLastName]: formData?.rawClaimantLastName || '',
        [WCFormKey.WC3AInsuranceCarrierName]: formData?.rawInsuranceCarrierName || '',
        [WCFormKey.WC3Type]: formData?.rawWc3Type || '',
        [WCFormKey.WC3PaymentYear]: formData?.paymentYear || '',
        [WCFormKey.RegisteredEmployer]: registeredEmployer,
        ...toAuditData(item),
      };
    }),
  };
};

export const getWCForm = (data): WCForm => ({
  ...data,
  [WCFormKey.WC77A]: {
    ...data?.wc77a,
    [WC77AKey.CaseNumber]: data?.wc77a?.wcCase?.caseNumber,
  },
  [WCFormKey.WC3A]: {
    ...data?.wc3a,
    [WC3AKey.RecentWC3A]: data?.wc3a,
    [WC3AKey.CaseNumber]: data?.wc3a?.wcCase?.caseNumber,
  },
  [WCFormKey.WC3]: {
    ...data?.wc3,
    [WC3Key.RecentWC3]: data?.wc3,
    [WC3Key.CaseNumber]: data?.wc3?.wcCase?.caseNumber,
  },
  [WCFormKey.WC14]: {
    ...data?.wc14,
    [WC14Key.CaseNumber]: data?.wc14?.wcCase?.caseNumber,
    [WC14Key.RecentWC14]: data?.wc14,
  },
});
