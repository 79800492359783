export enum TDI30Key {
  ID = 'id',
  TDI30Number = 'tdi30Number',
  TDI30FormattedNumber = 'tdi30FormattedNumber',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FirstDateDisabled = 'firstDateDisabled',
  DateRecovered = 'dateRecovered',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  Employer = 'employer',
  Claimant = 'claimant',
  DoctorAPRN = 'doctorAPRN',
  TdiSfCase = 'tdiSFCase',
}
