import { TDI62Key } from './keys';

export enum TDI62ActionTaken {
  NewlyEnrolled = 'Newly Enrolled',
  Reinstated = 'Reinstated',
  Canceled = 'Canceled',
  AmendedNewlyEnrolled = 'Amended - Newly Enrolled',
  AmendedCanceled = 'Amended – Canceled',
  FlatDelete = 'Flat Delete',
  NoActionTaken = 'No Action Taken',
}

export enum CoverageAction {
  ProvideStatutoryCoverage = 'PROVIDE_STATUTORY_COVERAGE',
  ProvideCoverage = 'PROVIDE_COVERAGE',
  CancelThePolicy = 'CANCEL',
  ReinstateThePolicy = 'REINSTATE',
  AmendThePolicy = 'AMEND',
}

export enum AmendType {
  NewlyEnrolled = 'NEWLY_ENROLLED',
  Cancellation = 'CANCELLATION',
}

export interface TDI62 {
  [TDI62Key.TDI62EmployerName]: string;
  [TDI62Key.TDI62ActionTaken]: TDI62ActionTaken;
  [TDI62Key.TDI62DolNumber]: string;
  [TDI62Key.TDI62EffectiveDater]: Date;

  [TDI62Key.ActionTaken]: string;
  [TDI62Key.RejectionReason]: string;

  [TDI62Key.InsuranceCarrier]: string;
  [TDI62Key.InsuranceCarrierId]: string;
  [TDI62Key.InsuranceCarrierName]: string;
  [TDI62Key.AuthorizedRepresentativeName]: string;
  [TDI62Key.Address]: string;
  [TDI62Key.City]: string;
  [TDI62Key.State]: string;
  [TDI62Key.ZipCode]: string;

  [TDI62Key.DOLNumber]: string;
  [TDI62Key.EmployerRegisteredName]: string;

  [TDI62Key.EmployeesClassCovered]: string;
  [TDI62Key.CoverageAction]: CoverageAction;
  [TDI62Key.RequestEffectiveDate]: string;
  [TDI62Key.WeeklyBenefit]: string;
  [TDI62Key.DayBenefitsBeginForAccident]: number;
  [TDI62Key.MaximumBenefitPeriod]: string;
  [TDI62Key.DayBenefitsBeginForSickness]: number;
  [TDI62Key.AdditionalInformation]: string;
  [TDI62Key.ReasonForCancellation]: string;
  [TDI62Key.Signature]: string;
  [TDI62Key.SignatureDate]: string;
  [TDI62Key.SignatureEmail]: string;
  [TDI62Key.SignaturePhone]: string;
  [TDI62Key.TypeOfAmend]: AmendType;
  [TDI62Key.AmendNewlyEnrolledEffectiveDate]: string;
  [TDI62Key.AmendCancellationEffectiveDate]: string;
}
