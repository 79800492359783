import { PolicyType } from '../WCInsurancePolicies';
import { WCCoverageKey } from './keys';

export enum CoverageStatus {
  Active = 'ACTIVE',
  Duplicate = 'DUPLICATE',
  Exemption = 'EXEMPTION',
  Expired = 'EXPIRED',
  NonCompliant = 'NON_COMPLIANT',
  Reinstate = 'REINSTATE',
}

export interface WCCoverage {
  [WCCoverageKey.Id]: string;
  [WCCoverageKey.EffectiveDate]: string;
  [WCCoverageKey.ExpirationDate]: string;
  [WCCoverageKey.CoverageStatus]: CoverageStatus;
  [WCCoverageKey.PolicyNumber]: string;
  [WCCoverageKey.PolicyType]: PolicyType;
  [WCCoverageKey.PolicyStatusDate]: string;
  [WCCoverageKey.InsuranceCarrier]: string;
  [WCCoverageKey.CarrierID]: string;
  [WCCoverageKey.IsMaster]: boolean;
  [WCCoverageKey.WrapUp]: boolean;
  [WCCoverageKey.IsMultipleEmployers]: boolean;
  [WCCoverageKey.EmployerName]: string;
  [WCCoverageKey.DOLNumber]: string;
  [WCCoverageKey.EmployerId]: string;
  [WCCoverageKey.InsurancePolicyId]: string;
}
