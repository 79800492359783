export const PATHS = {
  root: '/',
  home: '/home',
  homepage: '/homepage',
  dev: '/dev',
  welcome: '/welcome',
  signIn: '/login',
  signUp: '/sign-up',
  event: '/event',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  myAccount: '/my-accounts',
  dashboard: '/dashboard',
  logout: '/logout',

  // ======== Profile ========
  myProfile: '/me',
  fileUpload: '/file-upload',
  account: '/account',
  phc: '/phc',
  detailTdi: '/tdi/:id',
  wcDetail: '/wc/case/:id',
  fraudComplaintDetail: '/wc/case/:caseId/fraud-complaint/:id',
  changePassword: '/me/change-password',
  editMyProfile: '/me/edit',
  configureNotification: '/me/notification',

  // ======== Account ========
  accounts: '/accounts',
  relatedAccountPortalUsers: '/account/portal-users',
  relatedAccountContact: '/account/related-contacts',

  //======== TDI ========
  tdi: '/tdi',
  tdiCase: '/tdi/tdi-cases/:id',
  tdiCases: '/tdi/tdi-cases',
  relatedTDICaseDecisions: '/tdi/tdi-cases/:id/decisions',
  relatedTDICaseHearings: '/tdi/tdi-cases/:id/hearings',
  relatedTDICase46s: '/tdi/tdi-cases/:id/tdi-46s',
  tdiCoverages: '/tdi/tdi-coverages',
  tdiSFCases: '/tdi/tdi-sf-cases',
  tdiSFCase: '/tdi/tdi-sf-cases/:id',
  relatedTDISFCaseDecisions: '/tdi/tdi-sf-cases/:id/decisions',
  relatedTDISFHearings: '/tdi/tdi-sf-cases/:id/hearings',
  formTdiSFCaseTdi30s: '/tdi/tdi-sf-cases/:id/tdi30s',
  tdiGuidelines: '/tdi/tdi-guidelines',

  //======== TDI - 46 ========
  tdi46: '/tdi/tdi-cases/tdi-46s/:id',
  tdi46s: '/tdi/tdi-cases/tdi-46s',

  tdiForm: '/tdi/tdi-forms/:formType/:id',
  newTDIForm: '/tdi/tdi-forms/:formType/new',

  //======== TDI - Form ========
  tdiForms: '/tdi/tdi-forms',

  //======== WC Case ========
  wc: '/wc',
  wcForms: '/wc/wc-forms',
  wcGuidelines: '/wc/guidelines',
  wcForm: '/wc/wc-forms/:formType/:id',
  newWCForm: '/wc/wc-forms/:formType/new',
  wcCases: '/wc/wc-cases',
  wcCase: '/wc/wc-cases/:id',
  relatedWC3s: '/wc/wc-cases/:id/wc-3s',
  relatedWC3As: '/wc/wc-cases/:id/wc-3as',
  relatedWC5As: '/wc/wc-cases/:id/wc-5as',
  relatedWC77s: '/wc/wc-cases/:id/wc-77s',
  relatedWC5s: '/wc/wc-cases/:id/wc-5s',
  relatedDCDCaseDecisions: '/wc/wc-cases/:id/decisions',
  relatedDCDCaseHearings: '/wc/wc-cases/:id/hearings',
  relatedWC1s: '/wc/wc-cases/:id/wc-1s',
  relatedWC2s: '/wc/wc-cases/:id/wc-2s',
  relatedWC14s: '/wc/wc-cases/:id/wc-14s',
  wc1: '/wc/wc-cases/wc-1s/:id',
  wc2: '/wc/wc-cases/wc-2s/:id',
  wc3: '/wc/wc-cases/wc-3s/:id',
  wc77: '/wc/wc-cases/wc-77s/:id',
  wc5a: '/wc/wc-cases/wc-5as/:id',
  wc5: '/wc/wc-cases/wc-5s/:id',
  wc3A: '/wc/wc-cases/wc-3as/:id',
  wc14: '/wc/wc-cases/wc-14s/:id',

  wcInsurancePolicies: '/wc/insurance-policies',
  wcInsurancePolicy: '/wc/insurance-policies/:id',
  relatedInsurancePolicyCoverages: '/wc/insurance-policies/:id/wc-coverages',

  //======== WC Coverages ========
  wcCoverages: '/wc/wc-coverages',
};

export const PATH_HEADERS = {
  [PATHS.myProfile]: 'My Profile',
  [PATHS.editMyProfile]: 'My Profile',
  [PATHS.changePassword]: 'Change Password', // pragma: allowlist secret
};

export const HIDE_NAV_PATHS = [];
