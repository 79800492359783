import { useMutation, UseMutationOptions } from 'react-query';
import { Callback } from 'src/redux/types';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { File, UploadFilesPayload } from './types';

export function useUploadFiles(
  options?: UseMutationOptions<any, Error, File[]> & {
    setProgress?: Callback;
  }
) {
  const {
    mutate: uploadFiles,
    isSuccess,
    isLoading,
  } = useMutation<UploadFilesPayload[], Error, any>({
    mutationFn: (payload: UploadFilesPayload[]) => {
      return responseWrapper<any>(apiClient.uploadFiles, [
        {
          payload,
          setProgress: options.setProgress,
        },
      ]);
    },
    onError: (error) => {
      ErrorService.handler(error);
    },
    ...options,
  });

  return {
    loading: isLoading,
    isSuccess,
    uploadFiles,
  };
}
