import { WC3As } from 'src/queries/WC3A';
import { FormStatus } from '../types';
import { WC1 } from '../WC1';
import { WC14FormPayload } from '../WC14';
import { WC3 } from '../WC3';
import { WC77A } from '../WC77A';
import { WC1DuplicatedCaseKey, WCFormKey } from './keys';
import { WC77 } from './WC77s';

export enum WCFormType {
  WC1 = 'WC-1',
  WC3A = 'WC-3A',
  WC3 = 'WC-3',
  WC77 = 'WC-77',
  WC14 = 'WC-14',
  WC77A = 'WC-77A',
}

export const WCformKeyMap = {
  [WCFormType.WC1]: WCFormKey.WC1,
  [WCFormType.WC3A]: WCFormKey.WC3A,
  [WCFormType.WC3]: WCFormKey.WC3,
  [WCFormType.WC77]: WCFormKey.WC77,
  [WCFormType.WC14]: WCFormKey.WC14,
  [WCFormType.WC77A]: WCFormKey.WC77A,
};

export interface WCForm {
  [WCFormKey.WC1]?: WC1;
  [WCFormKey.WC3A]?: WC3As;
  [WCFormKey.WC3]?: WC3;
  [WCFormKey.WC77]?: WC77;
  [WCFormKey.WC14]?: WC14FormPayload;
  [WCFormKey.WC77A]?: WC77A;
  [WCFormKey.FormStatus]: FormStatus;
  [WCFormKey.FormType]: WCFormType;
  [WCFormKey.FormNumber]?: WCFormType;
  [WCFormKey.Id]: string;
  [WCFormKey.CreatedAt]?: string;
  [WCFormKey.UpdatedAt]?: string;
  [WCFormKey.RecordNum]?: number;
  [WCFormKey.DateSubmitted]?: string;
}

export type EditWCFormPayload = WCForm & {
  id: string;
};

export type WC1Payload = Partial<WC1>;

export interface WC1DuplicatedCase {
  id: string;
  [WC1DuplicatedCaseKey.AdjustingCompany]: string;
  [WC1DuplicatedCaseKey.CaseNumber]: string;
  [WC1DuplicatedCaseKey.ClaimantFullName]: string;
  [WC1DuplicatedCaseKey.DateOfII]: string;
  [WC1DuplicatedCaseKey.Employer]: string;
  [WC1DuplicatedCaseKey.InsuranceCarrier]: string;
}
