import { Callback } from 'src/redux/types';
import { PortalUserType } from '../Profile';
import { DocumentCategory, FileUploadKey, FillingParty, InsuranceType } from './keys';

export type FileUpload = {
  [FileUploadKey.Id]: string;
  [FileUploadKey.InsuranceType]: string;
  [FileUploadKey.File]: File[];
  [FileUploadKey.DocumentType]: string;
  [FileUploadKey.DocumentTitle]: string;
  [FileUploadKey.CaseNumber]: string;
  [FileUploadKey.DocumentClass]: string;
  [FileUploadKey.DocumentCategory]: string;
  [FileUploadKey.HealthCareContractor]: string;
  [FileUploadKey.FinancialYear]: string;
  [FileUploadKey.DocumentCategory]: string;
  [FileUploadKey.HealthCareContractor]: string;
  [FileUploadKey.FinancialYear]: string;
  [FileUploadKey.DolNumber]: string;
  [FileUploadKey.NewOrAmend]: string;
  [FileUploadKey.EmployerName]: string;
  [FileUploadKey.EmployerId]: string;
  [FileUploadKey.TdiCaseId]: string;
  [FileUploadKey.TdiSFCaseId]: string;
  [FileUploadKey.ClaimantFirstName]: string;
  [FileUploadKey.ClaimantLastName]: string;
  [FileUploadKey.EmployerDbaName]: string;
  [FileUploadKey.WcCaseId]: string;
  [FileUploadKey.DocumentDescription]: string;
  [FileUploadKey.DateOfInjury]: string;
  [FileUploadKey.LinkTo]: string;
  [FileUploadKey.InsuranceCarrierId]: string;
  [FileUploadKey.DecisionId]: string;
  [FileUploadKey.HearingId]: string;
  [FileUploadKey.EmployerAuditId]: string;
  [FileUploadKey.FillingParty]: string;
};

export interface UploadedFileResponse {
  [FileUploadKey.Id]: string;
  [FileUploadKey.InsuranceType]: string;
  [FileUploadKey.DocumentType]: string;
  [FileUploadKey.DocumentCategory]: string;
  [FileUploadKey.DocumentName]: string;
  [FileUploadKey.DocumentTitle]: string;
  [FileUploadKey.CaseNumber]: string;
  [FileUploadKey.AttachmentURL]: string;
  [FileUploadKey.CreatedAt]: string;
  [FileUploadKey.CreatedBy]: string;
  [FileUploadKey.UpdatedAt]: string;
  [FileUploadKey.EmployerName]: string;
  [FileUploadKey.InsuranceCarrier]: string;
  [FileUploadKey.WcCaseId]: string;
  [FileUploadKey.ClaimantFirstName]: string;
  [FileUploadKey.ClaimantLastName]: string;
}

export type UploadFilesPayload = {
  url: string;
  fileData: File;
  contentType: string;
  setProgress: Callback;
};

export type GetPresignedDownloadPayload = {
  filePath: string;
};

export type GetPresignedDownloadResponse = {
  url: string;
};

export type GetPresignedFileUploadPayload = {
  fileName: string;
  contentType: string;
  type: string;
};

export type File = { attachmentUrl: string; documentName: string; size: number };

export type Documents = {
  category: DocumentCategory;
  label: string;
  value: string;
  class: string;
  portals: PortalUserType[];
};

export type InsuranceDocuments = {
  [InsuranceType.PrepaidHealthCare]: Documents[];
  [InsuranceType.TemporaryDisabilityInsurance]: Documents[];
  [InsuranceType.WCEmployee]: Documents[];
  [InsuranceType.WCNonEmployee]?: Documents[];
};

export const getFilingParty = (userType: PortalUserType): FillingParty => {
  switch (userType) {
    case PortalUserType.VocationalRehabilitationCounselor:
    case PortalUserType.Physical:
    case PortalUserType.HealthCareContractor:
      return FillingParty.Other;

    case PortalUserType.LegalGuardian:
    case PortalUserType.AttorneyForClaimant:
    case PortalUserType.AttorneyForEmployer:
      return FillingParty.AttorneyOrRepresentative;

    case PortalUserType.Employer:
    case PortalUserType.SelfInsured:
      return FillingParty.Employer;

    case PortalUserType.Claimant:
      return FillingParty.Claimant;

    case PortalUserType.Carrier:
    case PortalUserType.Adjuster:
      return FillingParty.InsuranceCarrierOrAdjuster;
  }
};
