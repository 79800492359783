import React from 'react';
import View from '../View';
import Image from '../Image';
import { IMAGES } from '../../common/appConfig/images';
import Text from '../Text';
import LoadingCommon from '../LoadingCommon';

const PermissionRestrict: React.FC<Props> = ({ isLoading = false }) => {
  return (
    <View style={{ height: 'calc(100vh - 192px)' }} justify="center" align="center">
      <View renderIf={isLoading}>
        <LoadingCommon />
      </View>
      <View renderIf={!isLoading} fullWidth justify={'center'} align={'center'}>
        <Image src={IMAGES.permissionRestrict} />
        <Text size={18} className="fw-bold mt-4">
          No permission
        </Text>
        <Text size={18}>You do not have permission to access this page.</Text>
      </View>
    </View>
  );
};

type Props = {
  isLoading?: boolean;
};

export default PermissionRestrict;
