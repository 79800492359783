export enum TDICaseKey {
  ID = 'id',
  TDICaseNumber = 'tdiCaseNumber',
  TDICaseFormattedNumber = 'tdiCaseFormattedNumber',
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  AdjustingCompany = 'adjustingCompany',
  AdjustingCompanyId = 'adjustingCompanyId',
  AdjustingCompanyName = 'adjustingCompanyName',
  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierName = 'insuranceCarrierName',
  Employer = 'employer',
  EmployerName = 'employerName',
  FirstDateOfDisabilityClaimed = 'firstDateOfDisabilityClaimed',
  ClaimOrFileNumber = 'claimOrFileNumber',
  PortalStatus = 'portalStatus',
  DateClaimFiled = 'dateClaimFiled',
  AppealFiledDate = 'appealFiledDate',
  AppealWithdrawnDate = 'appealWithdrawnDate',
  ClaimantID = 'claimantId',
  EmployerID = 'employerId',
  MostRecentTDI46 = 'mostRecentTDI46',
  DateOfInjury = 'dateOfInjury',
}
