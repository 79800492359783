import { PortalUserType } from '../Profile';

export enum FileUploadKey {
  Id = 'id',
  InsuranceType = 'insuranceType',
  File = 'files',
  DocumentType = 'documentType',
  DocumentCategory = 'documentCategory',
  HealthCareContractor = 'healthCareContractor',
  DocumentClass = 'documentClass',
  FinancialYear = 'financialYear',
  DolNumber = 'dolNumber',
  NewOrAmend = 'newOrAmend',
  DocumentTitle = 'documentTitle',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  TdiCaseId = 'tdiCaseId',
  TdiSFCaseId = 'tdiSFCaseId',
  ClaimantFirstName = 'claimantFirstName',
  ClaimantLastName = 'claimantLastName',
  EmployerDbaName = 'employerDbaName',
  WcCaseId = 'wcCaseId',
  CaseNumber = 'caseNumber',
  DocumentDescription = 'documentDescription',
  DateOfInjury = 'dateOfInjury',
  LinkTo = 'linkTo',
  InsuranceCarrierId = 'insuranceCarrierId',
  DecisionId = 'decisionId',
  HearingId = 'hearingId',
  EmployerAuditId = 'employerAuditId',
  FillingParty = 'fillingParty',
  DcdFillingDate = 'dcdFillingDate',
  DateDocumentReceived = 'dateDocumentReceived',
  DocumentDate = 'documentDate',
  DocumentName = 'documentName',
  AttachmentURL = 'attachmentURL',
  Size = 'size',
  UploadStatus = 'uploadStatus',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  WcCase = 'wcCase',
  TdiCase = 'tdiCase',
  TdiSFCase = 'tdiSFCase',
  Decision = 'decision',
  Hearing = 'hearing',
  EmployerAudit = 'employerAudit',
  InsuranceCarrier = 'insuranceCarrier',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  Employer = 'employer',
}

export enum InsuranceType {
  PrepaidHealthCare = 'PHC',
  TemporaryDisabilityInsurance = 'TDI',
  WCEmployee = 'WC_EMPLOYEE',
  WCNonEmployee = 'WC_NON_EMPLOYEE',
}

export enum DocumentCategory {
  Form = 'FORM',
  Letter = 'LETTER',
  Report = 'REPORT',
  Checklist = 'CHECKLIST',
  None = 'NONE',
}

export enum DocumentClass {
  PHCDocuments = 'PHC',
  TDICase = 'TDI_CASE',
  TDIDocuments = 'TDI_DOCUMENTS',
  TDISpecialFund = 'TDI_SPECIAL_FUND',
  PlansBranchEmployer = 'PLANS_BRANCH_EMPLOYER',
  TDI46 = 'TDI_46',
  TDI62 = 'TDI_462',
  WCEmployee = 'WC_EMPLOYEE',
  WCNonEmployee = 'WC_NON_EMPLOYEE',
}

export enum FillingParty {
  AttorneyOrRepresentative = 'ATTORNEY_OR_REPRESENTATIVE',
  Claimant = 'CLAIMANT',
  DcdOrLirab = 'DCD_OR_LIRAB',
  Employer = 'EMPLOYER',
  InsuranceCarrierOrAdjuster = 'INSURANCE_CARRIER_OR_ADJUSTER',
  Other = 'OTHER',
}

export enum DocumentsType {
  Wc14EmployeesWageReportForFiftyTwoWeeks = 'WC14EmployeesWageReportForFiftyTwoWeeks',
  Wc17ApprovalOfAttorneyFees = 'WC17ApprovalOfAttorneyFees',
  Wc18WcClaimDenialLetter = 'WC18WCClaimDenialLetter',
  Wc76RequestForReportsFromCarriers = 'WC76RequestForReportsFromCarriers',
  Wc2PhysiciansReport = 'WC2PhysiciansReport',
  Wc3ACarriersBenefitAdjustmentReport = 'WC3ACarriersBenefitAdjustmentReport',
  Wc5EmployeesClaimForWorkersCompensationBenefits = 'WC5EmployeesClaimForWorkersCompensationBenefits',
  Wc44RequestForWc1 = 'WC44RequestForWC1',
  SubpoenaMedicalRecords = 'SubpoenaMedicalRecords',
  Affidavits = 'Affidavits',
  AppealLetter = 'AppealLetter',
  AttorneyFeeRequest = 'AttorneyFeeRequest',
  ChangeOfPhysicianRequest = 'ChangeOfPhysicianRequest',
  Tdi15SelfInsurerPlanCertificationAgreementForm = 'Tdi15SelfInsurerPlanCertificationAgreementForm',
  ChangeOfPhysicianResponse = 'ChangeOfPhysicianResponse',
  ClaimantAddressChange = 'ClaimantAddressChange',
  ClaimantNameChange = 'ClaimantNameChange',
  ClaimantLetterOfWithdrawalOfClaim = 'ClaimantLetterOfWithdrawalOfClaim',
  ClinicalOrOfficeVisitNotes = 'ClinicalOrOfficeVisitNotes',
  Dc300PtdNotarizedResponse = 'DC300PTDNotarizedResponse',
  DenialLetter = 'DenialLetter',
  DisabilityCertificate = 'DisabilityCertificate',
  DisfigurementPhotos = 'DisfigurementPhotos',
  DpiDeferredPendingInvestigationLetter = 'DPIDeferredPendingInvestigationLetter',
  EmployerCarrierLetterOfAcceptance = 'EmployerCarrierLetterOfAcceptance',
  EmployerCarrierLetterOfDenial = 'EmployerCarrierLetterOfDenial',
  EmployerCarrierRequestForExtension = 'EmployerCarrierRequestForExtension',
  FraudComplaint = 'FraudComplaint',
  FraudComplaintResponse = 'FraudComplaintResponse',
  HearingExhibits = 'HearingExhibits',
  HearingNoticeWc = 'HearingNoticeWC',
  ImeIndependentMedicalExaminationReport = 'IMEIndependentMedicalExaminationReport',
  ImeIpeRequestForDiscovery = 'IMEIPERequestForDiscovery',
  ImeIpeRequestForExecutedAuthorizations = 'IMEIPERequestForExecutedAuthorizations',
  ImeIpeRequestForOrders = 'IMEIPERequestForOrders',
  ImeIpeResponseForDiscovery = 'IMEIPEResponseForDiscovery',
  ImeIpeResponseForOrders = 'IMEIPEResponseForOrders',
  ImeIpeResponseToOrdersForExecutedAuthorization = 'IMEIPEResponseToOrdersForExecutedAuthorization',
  ImePpdAppointmentNotice = 'IMEPPDAppointmentNotice',
  InterrogatoryAnswers = 'InterrogatoryAnswers',
  IpeIndependentPsychologicalExaminationReport = 'IPEIndependentPsychologicalExaminationReport',
  LetterOfNegotiation1st = 'LetterOfNegotiation1st',
  LetterOfNegotiation2nd = 'LetterOfNegotiation2nd',
  LetterOfRepresentation = 'LetterOfRepresentation',
  LetterOfRepresentationLimitedCounsel = 'LetterOfRepresentationLimitedCounsel',
  LetterOfRepresentationWithdrawal = 'LetterOfRepresentationWithdrawal',
  LetterOfRepresentationAndOrSettlementNotice = 'LetterOfRepresentationAndOrSettlementNotice',
  MedicalPayment = 'MedicalPayment',
  MedicalReleaseRequest = 'MedicalReleaseRequest',
  MemoToInsuranceCarrierRegardingCancellationNotice = 'MemoToInsuranceCarrierRegardingCancellationNotice',
  MileageRequestForm = 'MileageRequestForm',
  MotorVehicleAccidentReport = 'MotorVehicleAccidentReport',
  IMEIPEResponseForExecutedAuthorizations = 'IMEIPEResponseForExecutedAuthorizations',
  OrderDeposition = 'OrderDeposition',
  OrderInterrogatories = 'OrderInterrogatories',
  OrderRequestForDeposition = 'OrderRequestForDeposition',
  OrderRequestForInterrogatories = 'OrderRequestForInterrogatories',
  PlansFormBb1Uc1BasicBusinessApplication = 'PlansFormBB1UC1BasicBusinessApplication',
  PtdClaimantLetterOfOverpayment = 'PTDClaimantLetterOfOverpayment',
  RequestForBillDispute = 'RequestForBillDispute',
  RequestForCredit = 'RequestForCredit',
  RequestForIntervention = 'RequestForIntervention',
  RequestForReconsiderationLetter = 'RequestForReconsiderationLetter',
  SettlementsAndStipulations = 'SettlementsAndStipulations',
  TerminationOfTemporaryTotalDisabilityBenefits = 'TerminationOfTemporaryTotalDisabilityBenefits',
  TransferOfCaseChangeInAdjustingCompany = 'TransferOfCaseChangeInAdjustingCompany',
  TreatmentPlan = 'TreatmentPlan',
  TreatmentPlanDenial = 'TreatmentPlanDenial',
  TreatmentPlanRequest = 'TreatmentPlanRequest',
  VrRequestForFormalReviewToUnit = 'VRRequestForFormalReviewToUnit',
  VrRequestForReconsideration = 'VRRequestForReconsideration',
  VrResponseToReconsiderationLetter = 'VRResponseToReconsiderationLetter',
  Wc1SupportingDocuments = 'WC1SupportingDocuments',
  Wc14Paystubs = 'WC14Paystubs',
  Wc2SupportingDocuments = 'WC2SupportingDocuments',
  Wc3SupportingDocuments = 'WC3SupportingDocuments',
  Wc5SupportingDocuments = 'WC5SupportingDocuments',
  Wc5ASupportingDocuments = 'WC5ASupportingDocuments',
  Wc77HearingRequest = 'WC77HearingRequest',
  Wc77SupportingDocuments = 'WC77SupportingDocuments',
  WitnessStatements = 'WitnessStatements',
  B24StateAccountingFormOfficialReceipt = 'B24StateAccountingFormOfficialReceipt',
  RequestForInformationOrPhotocopies = 'RequestForInformationOrPhotocopies',
  Tdi15TdiSelfInsurersPlanCertificationAndAgreement = 'TDI15TDISelfInsurersPlanCertificationAndAgreement',
  Tdi21AnnualReport = 'TDI21AnnualReport',
  Tdi30ClaimForTemporaryDisabilityBenefitsByUnemployedClaimant = 'TDI30ClaimForTemporaryDisabilityBenefitsByUnemployedClaimant',
  Tdi46DenialOfClaimForDisabilityBenefits = 'TDI46DenialOfClaimForDisabilityBenefits',
  Tdi39EvaluationOfClaimantsDisability = 'TDI39EvaluationOfClaimantsDisability',
  TimeDepositSignatureCard = 'TimeDepositSignatureCard',
  AppealSupportingDocuments = 'AppealSupportingDocuments',
  AppealOfBenefitAmountOrOverpayment = 'AppealOfBenefitAmountOrOverpayment',
  ApprovalOfAttorneyFees = 'ApprovalOfAttorneyFees',
  CarrierCorrespondenceToState = 'CarrierCorrespondenceToState',
  CarriersDesignationOfAuthorizedRepresentativesForm = 'CarriersDesignationOfAuthorizedRepresentativesForm',
  ClaimantNameAddressChange = 'ClaimantNameAddressChange',
  CollectiveBargainingAgreementOrDc17 = 'CollectiveBargainingAgreementOrDC17',
  PositionStatements = 'PositionStatements',
  ReconsiderationOfAppeal = 'ReconsiderationOfAppeal',
  RequestForInformationOrDocuments = 'RequestForInformationOrDocuments',
  RequestForMedicalReleaseForm = 'RequestForMedicalReleaseForm',
  SecurityDepositBondRequest = 'SecurityDepositBondRequest',
  SelfInsuranceSupplementalDocuments = 'SelfInsuranceSupplementalDocuments',
  SelfInsuredBondTdi12 = 'SelfInsuredBondTDI12',
  Tdi15SelfInsuredPlanSupplementalDocuments = 'TDI15SelfInsuredPlanSupplementalDocuments',
  Tdi31ClaimCertificationForTrustFundBenefits = 'TDI31ClaimCertificationForTrustFundBenefits',
  Tdi33NoticeOfDenialOfClaimForTemporaryDisabilityBenefits = 'TDI33NoticeOfDenialOfClaimForTemporaryDisabilityBenefits',
  Tdi35AEligibilityDeterminationForUnemployedClaimant = 'TDI35aEligibilityDeterminationForUnemployedClaimant',
  Tdi45ClaimForDisabilityBenefit = 'TDI45ClaimForDisabilityBenefit',
  Tdi46SupplementalDocuments = 'TDI46SupplementalDocuments',
  Tdi62SupplementalDocuments = 'TDI62SupplementalDocuments',
  TerminationAttorneyClientRelationshipLetter = 'TerminationAttorneyClientRelationshipLetter',
  DeterminationOfSelfInsurance = 'DeterminationOfSelfInsurance',
  Hc7PlanReviewApplication = 'HC7PlanReviewApplication',
  Hc7A2ServiceTypePlansOfHealthPlanContractor = 'HC7a2ServiceTypePlansOfHealthPlanContractor',
  IntentToCancelPolicy = 'IntentToCancelPolicy',
  InvestigativeSurveillanceVideos = 'InvestigativeSurveillanceVideos',
  NoticeOfUiDecision = 'NoticeOfUIDecision',
  PlanWorksheet = 'PlanWorksheet',
  ReimbursementTypePlanHmo = 'ReimbursementTypePlanHMO',
  Hc15SupplementalDocuments = 'HC15SupplementalDocuments',
  Hc5EmployeeNotificationToEmployer = 'HC5EmployeeNotificationToEmployer',
  Hc61SupplementalDocuments = 'HC61SupplementalDocuments',
  Hc7AReimbursementTypePlan = 'HC7aReimbursementTypePlan',
  RecordOfDistribution = 'RecordOfDistribution',
  UiNotificationIneligibleForUiAccount = 'UINotificationIneligibleForUIAccount',
}

export type Portal = PortalUserType.Adjuster | PortalUserType.Carrier | PortalUserType.SelfInsured;

export enum FileUploadType {
  Assets = 'assets',
  Ecms = 'ecms',
  TdiCases = 'tdi_cases',
  PhcCoverage = 'phc_coverage',
  TdiCoverage = 'tdi_coverage',
  Attachments = 'attachments',
}
