import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toDCDCaseDetail } from './helpers';
import { DCDCase } from './types';

export function useGetDCDCase(
  options?: UseQueryOptions<ApiResponseType<{ case: DCDCase }>, Error, DCDCase> & {
    id?: string;
  }
) {
  const {
    data: dcdCase,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetDCDCaseById,
  } = useQuery<ApiResponseType<{ case: DCDCase }>, Error, DCDCase>(
    [API_QUERIES.DCD_CASES, { ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ case: DCDCase }>>(apiClient.getDCDCase, params);
      },
      enabled: !!options?.id,
      select: ({ data }) => toDCDCaseDetail(data?.case),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDCDCase = () =>
    queryClient.invalidateQueries([API_QUERIES.DCD_CASES, { ...options }]);

  return {
    dcdCase,
    error,
    isError,
    isLoading,
    onGetDCDCaseById,
    handleInvalidateDCDCase,
  };
}
