/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames';
import React from 'react';
import { IoMdLogOut } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { COLOR_CODE } from 'src/appConfig/constants';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import { sidebarList } from 'src/appConfig/sidebar';
import { Text, Icon, Image, Link, View } from 'src/modules/shared-main/components';
import { IRootState } from 'src/redux/rootReducer';
import { Navigator } from 'src/services';
import { isEmpty } from 'src/validations';
import { useLogout } from '../../queries';
import Logo from '../Logo';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import './styles.scss';
import { setCollapseSidebar } from 'src/redux/common/commonSlice';

const Sidebar: React.FC = () => {
  const { collapseSidebar, showMiniSidebar } = useSelector((state: IRootState) => state.common);

  const dispatch = useDispatch();

  const { logout } = useLogout();

  const handleLogOut = () => {
    logout();
  };

  return (
    <div>
      <Icon
        className={cn('cmp-sidebar__toggle-button', {
          'cmp-sidebar__toggle-button--closed': collapseSidebar,
          'is--mini': showMiniSidebar,
        })}
        name={'ic_chevron-left'}
        onClick={() => dispatch(setCollapseSidebar(!collapseSidebar))}
      />
      <div
        className={cn('cmp-sidebar', {
          'cmp-sidebar--collapse': collapseSidebar,
          'cmp-sidebar--mini': showMiniSidebar,
        })}
      >
        <View className={cn('cmp-sidebar__container')}>
          <View
            isRowWrap
            align="center"
            justify="space-between"
            className={cn('cmp-sidebar__header cursor-pointer')}
            onClick={() => {
              Navigator.navigate(PATHS.homepage);
            }}
          >
            {collapseSidebar && !showMiniSidebar ? (
              <Image className={cn('cmp-sidebar__logo')} src={IMAGES.logo} />
            ) : (
              <View isRowWrap className="mt-24 px-8">
                <Logo logoSize="40" className={'p-0'} />
                <div>
                  <Text size={12} style={{ color: COLOR_CODE.PRIMARY }} className={'fw-normal p-0'}>
                    State of Hawaii
                  </Text>
                  <Text
                    className={'fw-bold p-0'}
                    style={{ whiteSpace: 'nowrap', color: COLOR_CODE.PRIMARY }}
                  >
                    Disability Compensation
                  </Text>
                  <Text className={'fw-bold p-0'} style={{ color: COLOR_CODE.PRIMARY }}>
                    Division
                  </Text>
                </div>
              </View>
            )}
          </View>
          <View
            className={cn('cmp-sidebar__setup', {
              'cmp-sidebar__setup-collapse': collapseSidebar && !showMiniSidebar,
            })}
          >
            {sidebarList
              ?.filter(({ hidden }) => !hidden)
              .map((item, index) =>
                isEmpty(item.subMenu) ? (
                  <SidebarItem key={index} item={item} />
                ) : (
                  <SidebarItemCollapse key={index} item={item} />
                )
              )}
          </View>
          <View className="cmp-sidebar__logout">
            <Link href={null} onClick={() => handleLogOut()}>
              <View className="cmp-sidebar__item__wrapper" isRowWrap>
                <View className={cn('cmp-sidebar__item__icon')}>
                  <IoMdLogOut color={COLOR_CODE.PRIMARY_BLUE} />
                </View>
                <View
                  className={cn('cmp-sidebar__item__text', {
                    'cmp-sidebar__item__text-collapse': collapseSidebar,
                  })}
                >
                  Log out
                </View>
              </View>
            </Link>
          </View>
        </View>
      </div>
    </div>
  );
};

export default Sidebar;
