export const DEFAULT_CASES: any = [
  {
    id: '1',
    number: 'WC',
    status: 'OPEN',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'CLOSED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'DENIED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'OPEN',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'CLOSED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'DENIED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'OPEN',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'CLOSED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'DENIED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'OPEN',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'CLOSED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
  {
    id: '1',
    number: 'WC',
    status: 'DENIED',
    claimant: 'Claimant',
    employer: 'Employer',
    date: '12/01/2022',
  },
];

export const DEFAULT_CASE_DETAIL: any = {
  status: 'CLOSED',
  date: '12/01/2022',
  openedDate: '12/01/2022',
  reopenedDate: '12/01/2022',
  employer: 'Ms. Claimant Esther Nguyen SF',
  endedDate: '12/01/2022',
  withdrawnDate: '12/01/2022',
  claimant: 'Ms. Claimant Esther Nguyen SF',
  form: [
    {
      name: 'name',
      number: '00001',
      date: '12/01/2022',
    },
    {
      name: 'name',
      number: '00001',
      date: '12/01/2022',
    },
    {
      name: 'name',
      number: '00001',
      date: '12/01/2022',
    },
    {
      name: 'name',
      number: '00001',
      date: '12/01/2022',
    },
    {
      name: 'name',
      number: '00001',
      date: '12/01/2022',
    },
    {
      name: 'name',
      number: '00001',
      date: '12/01/2022',
    },
  ],
  hearings: [
    {
      id: 'H-20221129-00257s',
      status: 'WAITING',
      date: '12/01/2022',
    },
    {
      id: 'H-20221129-00257s',
      status: 'HELD',
      date: '12/01/2022',
    },
  ],
  decisions: [
    {
      id: 'H-20221129-00257s',
      status: 'IN_PROGRESS',
    },
    {
      id: 'H-20221129-00257s',
      status: 'COMPLETED',
    },
  ],
  fraudComplaints: [
    {
      id: 'H-20221129-00257s',
      status: 'RECEIVED',
      against: 'Hanna',
    },
  ],
};
