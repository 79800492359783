import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from '../Properties';
import { toCoverageData } from './helpers';
import { WCCoverage } from './types';

export function useGetAllWCCoverages(
  options?: UseQueryOptions<PaginationResponseType<WCCoverage>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWCCoverages,
  } = useQuery<PaginationResponseType<WCCoverage>, Error>(
    [API_QUERIES.WCCoverages, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<WCCoverage>>(
          apiClient.getAllWCCoverages,
          params
        );
      },
      select: (data) => toCoverageData(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllWCCoverages = () =>
    queryClient.invalidateQueries(API_QUERIES.WCCoverages);

  const { data: wcCoverages, hasNext, totalRecords, payloadSize } = data || {};

  return {
    wcCoverages,
    error,
    hasNext,
    totalRecords,
    payloadSize,
    isError,
    isFetching,
    onGetAllWCCoverages,
    setParams,
    handleInvalidateAllWCCoverages,
  };
}
