import { UseQueryOptions } from 'react-query';

export enum API_QUERIES {
  PROFILE = '/me',
  CONTENT = '/content',
  ZIP_CODE = '/zip-code',
  TRIPS = '/trips',
  PROPERTY_LIST = '/property-list',
  PROPERTY = '/property',
  USER_ID = '/user-id',
  BANK = '/bank',
  NAICS_CODE = '/naics-code',
  UC1_FORM = '/uc1-form',
  ACCOUNT = '/account',
  CONTACTS = '/contacts',
  TDI_CASE = '/tdi-case',
  TDI_SF_CASES = '/tdi-sf-cases',
  DECISIONS = '/decisions',
  HEARINGS = '/hearings',
  TDI_DETAIL = '/tdi-detail',
  WC_CASES = '/wc-cases',
  WC_CASE_DETAIL = '/wc-case-detail',
  PRESIGNED_DOWNLOAD_URL = '/presigned-download-url',
  ACCOUNT_PORTAL_USERS = '/account-portal-users',
  ACCOUNT_RELATED_CONTACTS = '/account-related-contacts',
  TDI30s = '/tdi-30s',
  TDI_COVERAGES = 'TDICoverage',

  TDICases = 'TDICase',
  Accounts = 'Accounts',
  TDISFCase = 'TDISFCase',
  TDI46s = 'TDI46s',
  Hearings = 'Hearings',
  DCD_CASES = 'WCCase',
  HOMEPAGE = 'Homepage',

  WC1s = 'WC1s',
  WC2s = 'WC2s',

  UPLOAD_FILE = 'FileUpload',
  TDIForms = 'TDIForm',
  DuplicatedCase = 'DuplicatedCase',

  WC3s = 'WC3s',
  WC3As = 'WC3A',
  WC5s = 'WC5s',
  WC5As = 'WC5A',
  WC77s = 'WC77s',
  WC14s = 'WC14s',
  WCForm = 'WCForm',
  WCInsurancePolicies = 'InsurancePolicy',
  WCCoverages = 'Coverage',
  WC1DuplicatedCases = 'WC1DuplicatedCases',
}

export enum AuditKey {
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}

export enum NewAmend {
  New = 'NEW',
  Amend = 'AMEND',
}

export type QueryOptions<T> = Omit<UseQueryOptions, 'QueryKey'> & { QueryKey: T };
