import { FormNewAmend } from 'src/appConfig/constants';

export enum TDI46Key {
  Id = 'id',
  TDI46Number = 'tdi46Number',
  TDICase = 'tdiCase',
  TDICaseNumber = 'tdiCaseNumber',
  TDICaseId = 'tdiCaseId',
  DateReceived = 'dateReceived',

  // ============= Header =============
  Type = 'type',
  NewAmend = 'newAmend',
  AppealReceivedDate = 'appealReceivedDate',

  // ============= Claimant =============
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffixName = 'rawClaimantSuffix',
  RawClaimantAddress = 'rawClaimantAddress',
  RawClaimantCity = 'rawClaimantCity',
  RawClaimantState = 'rawClaimantState',
  RawClaimantZipCode = 'rawClaimantZipcode',
  RawClaimantEmail = 'rawClaimantEmail',
  RawClaimantPhone = 'rawClaimantPhone',
  RawClaimantSSN = 'rawClaimantSSN',
  RawClaimantPassport = 'rawClaimantPassport',
  RawClaimantFirstDateOfDisabilityClaimed = 'rawClaimantFirstDateOfDisabilityClaimed',
  RawClaimantFiledDate = 'rawClaimantFiledDate',
  RawClaimantClaimOrFileNumber = 'rawClaimantClaimOrFileNumber',
  RawClaimantDateNoticeSentDLIR = 'rawClaimantDateNoticeSentDLIR',
  RawClaimantDateNoticeSentClaimant = 'rawClaimantDateNoticeSentClaimant',
  ClaimantName = 'claimantName',
  EmployerName = 'employerName',

  // ============= Denial Reasons =============
  IsFailureToMeetEligibility = 'isDenyFailureToMeetEligibility',
  IsDidNotPerformRegularService = 'isDenyDidNotPerformRegularService',
  IsNotDisabledBeyond7Days = 'isDenyNotDisabledBeyond7Days',
  IsReceived26WeeksOfBenefitPayment = 'isDenyReceived26WeeksOfBenefitPayment',
  Denial26WeeksOfBenefitStartDate = 'denial26WeeksOfBenefitStartDate',
  Denial26WeeksOfBenefitEndDate = 'denial26WeeksOfBenefitEndDate',
  IsLateFillingAfter90Days = 'isDenyLateFillingAfter90Days',
  LateFillingClaimedFiledOn = 'denialLateFillingClaimedFiledOn',
  DenialLateFillingNoBenefitArePayable = 'denialLateFillingNoBenefitArePayable',
  IsPaymentStart14DaysBeforeFileDate = 'isPaymentStart14DaysBeforeFileDate',
  IsClaimingBenefitUnderWCLaw = 'isDenyClaimingBenefitUnderWCLaw',
  IsNotDisabledAccordingMedicalRecord = 'isDenyNotDisabledAccordingMedicalRecord',
  AbleToPerformRegularWorkOnDate = 'ableToPerformRegularWorkOnDate',
  PaymentOfBenefitIsDeniedAfterDate = 'paymentOfBenefitIsDeniedAfterDate',
  IsMedCertDoesntEstablishUnableToWork = 'isDenyMedCertDoesntEstablishUnableToWork',
  IsNotUnderCareOfPhysician = 'isDenyNotUnderCareOfPhysician',
  NotUnderCarePhysicianStartDate = 'notUnderCarePhysicianStartDate',
  NotUnderCarePhysicianEndDate = 'notUnderCarePhysicianEndDate',
  NoBenefitsArePayable = 'notUnderCarePhysicianNoBenefitArePayable',
  PaymentsWillStart = 'notUnderCarePhysicianPaymentWillStart',
  PaymentsWillStartOn = 'notUnderCarePhysicianPaymentWillStartOn',
  IsBenefitEntitledUnderUnionContract = 'isDenyBenefitEntitledUnderUnionContract',
  IsNotInsuranceCarrierForEmployer = 'isDenyNotInsuranceCarrierForEmployer',
  IsClaimHasBeenForwarded = 'notCarrierForEmplIsClaimForwarded',
  ClaimHasBeenForwardedTo = 'notCarrierForEmplClaimForwardedTo',
  IsClaimIsReturned = 'notCarrierForEmplIsClaimReturned',
  IsOtherReasonsForDenial = 'isDenyOtherReason',
  OtherReasonsForDenial = 'denialOtherReasons',

  // ============= Employer =============
  RawEmployerName = 'rawEmployerName',
  RawEmployerDolNumber = 'rawEmployerDolNumber',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerState = 'rawEmployerState',
  RawEmployerZipCode = 'rawEmployerZipcode',

  // ============= Insurance Carrier =============
  RawInsuranceCarrierName = 'rawCarrierName',
  RawInsuranceCarrierClaimAdjustingServiceName = 'rawCarrierClaimAdjustingServiceName',
  RawInsuranceCarrierAddress = 'rawCarrierAdjustingAddress',
  RawInsuranceCarrierCity = 'rawCarrierCity',
  RawInsuranceCarrierState = 'rawCarrierState',
  RawInsuranceCarrierZipCode = 'rawCarrierZipcode',
  RawInsuranceCarrierPointOfContactName = 'rawCarrierPointOfContactName',
  RawInsuranceCarrierPhone = 'rawCarrierPhone',
  RawInsuranceCarrierEmail = 'rawCarrierEmail',
  RawCarrierId = 'rawCarrierId',

  // ============= Authorized Representative =============
  AuthorizedRepresentativeName = 'representativeName',
  AuthorizedRepresentativeTitle = 'representativeTitle',

  // ============= Signature  =============
  SignaturePrintedName = 'signaturePrintedName',
  SignatureEmail = 'signatureEmail',
  SignatureDate = 'signatureDate',

  // ============= Claimant’s Appeal  =============
  ClaimantAppeal = 'claimantAppeal',

  // ============= Claimant’s Signature  =============
  SignatureClaimantLastName = 'signatureClaimantLastName',
  SignatureClaimantFirstName = 'signatureClaimantFirstName',
  SignatureClaimantMiddleInitial = 'signatureClaimantMiddleInitial',
  SignatureClaimantSuffix = 'signatureClaimantSuffix',
  SignatureClaimantReceiveDenialNoticeDate = 'signatureClaimantReceiveDenialNoticeDate',
  SignatureClaimantEmail = 'signatureClaimantEmail',
  SignatureClaimantDate = 'signatureClaimantDate',

  // ============= Form Review  =============
  IsCount = 'isCount',
  ReasonNotCounted = 'reasonNotCounted',

  TDI46ClaimantName = 'tdi46.rawClaimantName',
  TDI46EmployerName = 'tdi46.rawEmployerName',
  TDI46EmployerDolNumber = 'tdi46.rawEmployerDolNumber',
  TDI46ClaimantFirstDateOfDisabilityClaimed = 'tdi46.rawClaimantFirstDateOfDisabilityClaimed',
  TDI46ClaimantFiledDate = 'tdi46.rawClaimantFiledDate',
}

export enum TDI46Type {
  Denial = 'DENIAL',
  Appeal = 'APPEAL',
}

export enum ReasonNotCounted {
  Duplicate = 'DUPLICATE',
  Incomplete = 'INCOMPLETE',
  Other = 'OTHER',
}

export interface TDI46 {
  [TDI46Key.Id]?: string;
  [TDI46Key.TDI46Number]?: string;
  [TDI46Key.Type]?: TDI46Type;
  [TDI46Key.NewAmend]?: FormNewAmend;
  [TDI46Key.TDICase]?: string;
  [TDI46Key.TDICaseNumber]?: string;
  [TDI46Key.TDICaseId]?: string;
  [TDI46Key.DateReceived]?: string;

  [TDI46Key.RawClaimantFirstName]?: string;
  [TDI46Key.RawClaimantLastName]?: string;
  [TDI46Key.RawClaimantMiddleInitial]?: string;
  [TDI46Key.RawClaimantSuffixName]?: string;
  [TDI46Key.RawClaimantAddress]?: string;
  [TDI46Key.RawClaimantCity]?: string;
  [TDI46Key.RawClaimantState]?: string;
  [TDI46Key.RawClaimantZipCode]?: string;
  [TDI46Key.RawClaimantEmail]?: string;
  [TDI46Key.RawClaimantPhone]?: string;
  [TDI46Key.RawClaimantSSN]?: string;
  [TDI46Key.RawClaimantPassport]?: string;
  [TDI46Key.RawClaimantFirstDateOfDisabilityClaimed]?: string;
  [TDI46Key.RawClaimantFiledDate]?: string;
  [TDI46Key.RawClaimantClaimOrFileNumber]?: string;
  [TDI46Key.RawClaimantDateNoticeSentDLIR]?: string;
  [TDI46Key.RawClaimantDateNoticeSentClaimant]?: string;

  [TDI46Key.IsFailureToMeetEligibility]?: boolean;
  [TDI46Key.IsDidNotPerformRegularService]?: boolean;
  [TDI46Key.IsNotDisabledBeyond7Days]?: boolean;
  [TDI46Key.IsReceived26WeeksOfBenefitPayment]?: boolean;
  [TDI46Key.Denial26WeeksOfBenefitStartDate]?: string;
  [TDI46Key.Denial26WeeksOfBenefitEndDate]?: string;
  [TDI46Key.IsLateFillingAfter90Days]?: boolean;
  [TDI46Key.LateFillingClaimedFiledOn]?: string;
  [TDI46Key.DenialLateFillingNoBenefitArePayable]?: boolean;
  [TDI46Key.IsPaymentStart14DaysBeforeFileDate]?: boolean;
  [TDI46Key.IsClaimingBenefitUnderWCLaw]?: boolean;
  [TDI46Key.IsNotDisabledAccordingMedicalRecord]?: boolean;
  [TDI46Key.AbleToPerformRegularWorkOnDate]?: string;
  [TDI46Key.PaymentOfBenefitIsDeniedAfterDate]?: string;
  [TDI46Key.IsMedCertDoesntEstablishUnableToWork]?: boolean;
  [TDI46Key.IsNotUnderCareOfPhysician]?: boolean;
  [TDI46Key.NotUnderCarePhysicianStartDate]?: string;
  [TDI46Key.NotUnderCarePhysicianEndDate]?: string;
  [TDI46Key.NoBenefitsArePayable]?: boolean;
  [TDI46Key.PaymentsWillStart]?: boolean;
  [TDI46Key.PaymentsWillStartOn]?: string;
  [TDI46Key.IsBenefitEntitledUnderUnionContract]?: boolean;
  [TDI46Key.IsNotInsuranceCarrierForEmployer]?: boolean;
  [TDI46Key.IsClaimHasBeenForwarded]?: boolean;
  [TDI46Key.ClaimHasBeenForwardedTo]?: string;
  [TDI46Key.IsClaimIsReturned]?: boolean;
  [TDI46Key.IsOtherReasonsForDenial]?: boolean;
  [TDI46Key.OtherReasonsForDenial]?: string;

  [TDI46Key.RawEmployerName]?: string;
  [TDI46Key.RawEmployerDolNumber]?: string;
  [TDI46Key.RawEmployerAddress]?: string;
  [TDI46Key.RawEmployerCity]?: string;
  [TDI46Key.RawEmployerState]?: string;
  [TDI46Key.RawEmployerZipCode]?: string;

  [TDI46Key.RawInsuranceCarrierName]?: string;
  [TDI46Key.RawInsuranceCarrierClaimAdjustingServiceName]?: string;
  [TDI46Key.RawInsuranceCarrierAddress]?: string;
  [TDI46Key.RawInsuranceCarrierCity]?: string;
  [TDI46Key.RawInsuranceCarrierState]?: string;
  [TDI46Key.RawInsuranceCarrierZipCode]?: string;
  [TDI46Key.RawInsuranceCarrierPointOfContactName]?: string;
  [TDI46Key.RawInsuranceCarrierPhone]?: string;
  [TDI46Key.RawInsuranceCarrierEmail]?: string;
  [TDI46Key.RawCarrierId]?: string;

  [TDI46Key.AuthorizedRepresentativeName]?: string;
  [TDI46Key.AuthorizedRepresentativeTitle]?: string;

  [TDI46Key.SignaturePrintedName]?: string;
  [TDI46Key.SignatureEmail]?: string;
  [TDI46Key.SignatureDate]?: string;

  [TDI46Key.ClaimantAppeal]?: string;
  [TDI46Key.SignatureClaimantLastName]?: string;
  [TDI46Key.SignatureClaimantFirstName]?: string;
  [TDI46Key.SignatureClaimantMiddleInitial]?: string;
  [TDI46Key.SignatureClaimantSuffix]?: string;
  [TDI46Key.SignatureClaimantReceiveDenialNoticeDate]?: string;
  [TDI46Key.SignatureClaimantEmail]?: string;
  [TDI46Key.SignatureClaimantDate]?: string;

  [TDI46Key.TDI46ClaimantName]?: string;
  [TDI46Key.TDI46ClaimantFiledDate]?: Date;
  [TDI46Key.TDI46EmployerName]?: string;
  [TDI46Key.TDI46ClaimantFirstDateOfDisabilityClaimed]?: string;
  [TDI46Key.TDI46EmployerDolNumber]?: string;
}
