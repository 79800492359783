import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Collapse, List } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from 'src/appConfig/sidebar';
import { IRootState } from '../../redux/store';
import SidebarItem from './SidebarItem';
import { Image, Link, View } from 'src/modules/shared-main/components';
import { isEmpty } from 'src/modules/shared-main/common';

const SidebarItemCollapse: React.FC<Props> = ({ item }) => {
  const [open, setOpen] = useState(false);

  const { collapseSidebar, showMiniSidebar } = useSelector((state: IRootState) => state.common);
  const { user } = useSelector((state: IRootState) => state.auth);

  return (
    <View className={cn({ 'cmp-sidebar__item__minisidebar': open })}>
      <Link
        href={null}
        className={cn('cmp-sidebar__item', {
          'cmp-sidebar__item--closed': collapseSidebar && !showMiniSidebar,
        })}
        onClick={() => setOpen((prev) => !prev)}
      >
        {item.iconSrc ? (
          <View className={cn('cmp-sidebar__item__icon')}>
            <Image src={item.iconSrc} className={cn('cmp-sidebar__item__icon--item')} />
          </View>
        ) : (
          <View className="ctn-dashboard-list__icon">
            <View className={cn('cmp-sidebar__item__icon--item')}>{item.icon}</View>
          </View>
        )}
        {item.title ? (
          <View
            className={cn('cmp-sidebar__item__text', {
              'cmp-sidebar__item__text--closed': collapseSidebar && !showMiniSidebar,
            })}
          >
            {item.title}
          </View>
        ) : null}
        <Box className={cn({ 'd-none': collapseSidebar && !showMiniSidebar })}>
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </Box>
      </Link>
      <Collapse in={open && !collapseSidebar} timeout="auto">
        <List
          sx={{
            padding: '0',
          }}
        >
          {item.subMenu
            ?.filter(({ hidden }) => !hidden)
            .map((route, index) => {
              if (
                !isEmpty(route.userTypePermission) &&
                !route?.userTypePermission?.includes(user?.portalUserTypeId)
              ) {
                return null;
              }

              return route.subMenu ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <Box pl={2} key={index}>
                  <SidebarItem key={index} item={route} />
                </Box>
              );
            })}
        </List>
      </Collapse>
    </View>
  );
};

type Props = {
  item: MenuItem;
  isChildren?: boolean;
};

export default SidebarItemCollapse;
