import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { Decision } from './types';

export function useGetAllDecisions(
  options?: UseQueryOptions<PaginationResponseType<Decision>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetDecisions,
  } = useQuery<PaginationResponseType<Decision>, Error>(
    [API_QUERIES.DECISIONS, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Decision>>(apiClient.getDecisions, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDecisions = () => queryClient.invalidateQueries(API_QUERIES.DECISIONS);

  const { data: decisions, hasNext, payloadSize, totalRecords } = data || {};

  return {
    decisions,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetDecisions,
    handleInvalidateDecisions,
    setParams,
  };
}
