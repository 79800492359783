export enum WC5Key {
  Id = 'id',
  FormNumber = 'formNumber',
  RawDateReceived = 'rawDateReceived',
  Case = 'case',
  CaseId = 'caseId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  RawNewAmend = 'rawNewAmend',
  RawHeaderCaseNumber = 'rawHeaderCaseNumber',
  ClaimantId = 'claimantId',
  EmployerId = 'employerId',
  InsuranceCarrierId = 'insuranceCarrierId',
  AdjusterId = 'adjusterId',
  PhysicianId = 'physicianId',
  EmployerPointOfContactId = 'employerPointOfContactId',
  AdjustingCompstringId = 'adjustingCompstringId',
  RawEmployeePrinterName = 'rawEmployeePrinterName',
  RawEmployeeSignatureType = 'rawEmployeeSignatureType',
  RawEmployeeDate = 'rawEmployeeDate',
  RawEmployeeRepresentedBy = 'rawEmployeeRepresentedBy',
  RawEmployeePhone = 'rawEmployeePhone',
  RawEmployeeEmail = 'rawEmployeeEmail',
  RawEmployeeAddress = 'rawEmployeeAddress',
  RawEmployeeCity = 'rawEmployeeCity',
  RawEmployeeState = 'rawEmployeeState',
  RawEmployeeZipcode = 'rawEmployeeZipcode',
  RawInjuredPersonLastname = 'rawInjuredPersonLastname',
  RawInjuredPersonFirstname = 'rawInjuredPersonFirstname',
  RawInjuredPersonMiddleInitial = 'rawInjuredPersonMiddleInitial',
  RawInjuredPersonSuffix = 'rawInjuredPersonSuffix',
  RawInjuredPersonSsn = 'rawInjuredPersonSsn',
  RawInjuredPersonPassport = 'rawInjuredPersonPassport',
  RawInjuredPersonAddress = 'rawInjuredPersonAddress',
  RawInjuredPersonAdditionalAddress = 'rawInjuredPersonAdditionalAddress',
  RawInjuredPersonCity = 'rawInjuredPersonCity',
  RawInjuredPersonState = 'rawInjuredPersonState',
  RawInjuredPersonZipcode = 'rawInjuredPersonZipcode',
  RawInjuredPersonEmail = 'rawInjuredPersonEmail',
  RawInjuredPersonPhone = 'rawInjuredPersonPhone',
  RawInjuredPersonOccupation = 'rawInjuredPersonOccupation',
  RawInjuredPersonBirthday = 'rawInjuredPersonBirthday',
  RawInjuredPersonGender = 'rawInjuredPersonGender',
  RawInjuredPersonIsATranslatorRequiredForHearing = 'rawInjuredPersonIsATranslatorRequiredForHearing',
  RawInjuredPersonTranslatorLanguage = 'rawInjuredPersonTranslatorLanguage',
  RawInjuredEmployerName = 'rawInjuredEmployerName',
  RawInjuredEmployerNatureOfBusiness = 'rawInjuredEmployerNatureOfBusiness',
  RawInjuredEmployerAddress = 'rawInjuredEmployerAddress',
  RawInjuredEmployerAdditionalAddress = 'rawInjuredEmployerAdditionalAddress',
  RawInjuredEmployerCity = 'rawInjuredEmployerCity',
  RawInjuredEmployerState = 'rawInjuredEmployerState',
  RawInjuredEmployerZipcode = 'rawInjuredEmployerZipcode',
  RawInjuredEmployerPoc = 'rawInjuredEmployerPoc',
  RawInjuredEmployerJobTitle = 'rawInjuredEmployerJobTitle',
  RawInjuredEmployerPhone = 'rawInjuredEmployerPhone',
  RawInjuredEmployerEmail = 'rawInjuredEmployerEmail',
  RawInjuredEmployerDateHired = 'rawInjuredEmployerDateHired',
  RawInjuredEmployerWasJob = 'rawInjuredEmployerWasJob',
  RawInjuredEmployerGrossPay = 'rawInjuredEmployerGrossPay',
  RawInjuredEmployerHowOftenPaid = 'rawInjuredEmployerHowOftenPaid',
  RawInjuredEmployerEmployeeFurnished = 'rawInjuredEmployerEmployeeFurnished',
  RawInjuredEmployerLostTimeOffAtOtherEmployments = 'rawInjuredEmployerLostTimeOffAtOtherEmployments',
  RawIiDate = 'rawIiDate',
  RawIiTime = 'rawIiTime',
  RawIiTimeOfDay = 'rawIiTimeOfDay',
  RawIiDisabilityBeganDay = 'rawIiDisabilityBeganDay',
  RawIiOnEmployerPremise = 'rawIiOnEmployerPremise',
  RawIiNotOnPremiseWhere = 'rawIiNotOnPremiseWhere',
  RawIiCity = 'rawIiCity',
  RawIiState = 'rawIiState',
  RawIiZipcode = 'rawIiZipcode',
  RawIiDescribeHowIiOccurred = 'rawIiDescribeHowIiOccurred',
  RawIiDescribeIi = 'rawIiDescribeIi',
  RawIiNatureOfIi = 'rawIiNatureOfIi',
  RawIiMultipleBodyPart = 'rawIiMultipleBodyPart',
  RawInjury1LeftRightSide = 'rawInjury1LeftRightSide',
  RawInjury1FrontBackSide = 'rawInjury1FrontBackSide',
  RawInjury1PartOfBody = 'rawInjury1PartOfBody',
  RawInjury1Disfigurement = 'rawInjury1Disfigurement',
  RawInjury1Burn = 'rawInjury1Burn',
  RawInjury2LeftRightSide = 'rawInjury2LeftRightSide',
  RawInjury2FrontBackSide = 'rawInjury2FrontBackSide',
  RawInjury2PartOfBody = 'rawInjury2PartOfBody',
  RawInjury2Disfigurement = 'rawInjury2Disfigurement',
  RawInjury2Burn = 'rawInjury2Burn',
  RawInjury3LeftRightSide = 'rawInjury3LeftRightSide',
  RawInjury3FrontBackSide = 'rawInjury3FrontBackSide',
  RawInjury3PartOfBody = 'rawInjury3PartOfBody',
  RawInjury3Disfigurement = 'rawInjury3Disfigurement',
  RawInjury3Burn = 'rawInjury3Burn',
  RawInjuryReasonForFiling = 'rawInjuryReasonForFiling',
  RawInjuryOtherFilingReason = 'rawInjuryOtherFilingReason',
  RawInjuryStopWorking = 'rawInjuryStopWorking',
  RawInjuryStopWorkingDate = 'rawInjuryStopWorkingDate',
  RawInjuryReturnToWork = 'rawInjuryReturnToWork',
  RawInjuryTypeOfWorkDuty = 'rawInjuryTypeOfWorkDuty',
  RawWitnessIsMoreThanOne = 'rawWitnessIsMoreThanOne',
  RawWitness1Lastname = 'rawWitness1Lastname',
  RawWitness1Firstname = 'rawWitness1Firstname',
  RawWitness1MiddleInitial = 'rawWitness1MiddleInitial',
  RawWitness1Suffix = 'rawWitness1Suffix',
  RawWitness1Address = 'rawWitness1Address',
  RawWitness1City = 'rawWitness1City',
  RawWitness1State = 'rawWitness1State',
  RawWitness1Zipcode = 'rawWitness1Zipcode',
  RawWitness1Email = 'rawWitness1Email',
  RawWitness1WorkPhone = 'rawWitness1WorkPhone',
  RawWitness1HomePhone = 'rawWitness1HomePhone',
  RawWitness2Lastname = 'rawWitness2Lastname',
  RawWitness2Firstname = 'rawWitness2Firstname',
  RawWitness2MiddleInitial = 'rawWitness2MiddleInitial',
  RawWitness2Suffix = 'rawWitness2Suffix',
  RawWitness2Address = 'rawWitness2Address',
  RawWitness2City = 'rawWitness2City',
  RawWitness2State = 'rawWitness2State',
  RawWitness2Zipcode = 'rawWitness2Zipcode',
  RawWitness2Email = 'rawWitness2Email',
  RawWitness2WorkPhone = 'rawWitness2WorkPhone',
  RawWitness2HomePhone = 'rawWitness2HomePhone',
  RawNoticeDidYouNotifyEmployerOfIi = 'rawNoticeDidYouNotifyEmployerOfIi',
  RawNoticeNotifyEmployerHow = 'rawNoticeNotifyEmployerHow',
  RawNoticeDate = 'rawNoticeDate',
  RawNoticeTimeOfNotification = 'rawNoticeTimeOfNotification',
  RawNoticeTimeOfDay = 'rawNoticeTimeOfDay',
  RawNoticeToWhom = 'rawNoticeToWhom',
  RawNoticePhone = 'rawNoticePhone',
  RawInsuranceCarrierName = 'rawInsuranceCarrierName',
  RawInsuranceCarrierPoc = 'rawInsuranceCarrierPoc',
  RawInsuranceCarrierPhone = 'rawInsuranceCarrierPhone',
  RawInsuranceCarrierEmail = 'rawInsuranceCarrierEmail',
  RawInsuranceCarrierAddress = 'rawInsuranceCarrierAddress',
  RawInsuranceCarrierAdditionalAddress = 'rawInsuranceCarrierAdditionalAddress',
  RawInsuranceCarrierCity = 'rawInsuranceCarrierCity',
  RawInsuranceCarrierState = 'rawInsuranceCarrierState',
  RawInsuranceCarrierZipcode = 'rawInsuranceCarrierZipcode',
  RawAttendingPhysicianName = 'rawAttendingPhysicianName',
  RawAttendingPhysicianPhone = 'rawAttendingPhysicianPhone',
  RawAttendingPhysicianEmail = 'rawAttendingPhysicianEmail',
  RawAttendingPhysicianAddress = 'rawAttendingPhysicianAddress',
  RawAttendingPhysicianCity = 'rawAttendingPhysicianCity',
  RawAttendingPhysicianState = 'rawAttendingPhysicianState',
  RawAttendingPhysicianZipcode = 'rawAttendingPhysicianZipcode',
  RawMedicalFacilityName = 'rawMedicalFacilityName',
  RawMedicalFacilityAddress = 'rawMedicalFacilityAddress',
  RawMedicalFacilityCity = 'rawMedicalFacilityCity',
  RawMedicalFacilityState = 'rawMedicalFacilityState',
  RawMedicalFacilityZipcode = 'rawMedicalFacilityZipcode',
  RawTreatmentFirstDay = 'rawTreatmentFirstDay',
  RawTreatmentFirstPlace = 'rawTreatmentFirstPlace',
  RawTreatmentStillBeing = 'rawTreatmentStillBeing',
  Physician = 'physician',
  Claimant = 'claimant',
  Adjuster = 'adjuster',
  AdjustingCompstring = 'adjustingCompstring',
  InsuranceCarrier = 'insuranceCarrier',
  Employer = 'employer',
  EmployerPointOfContact = 'employerPointOfContact',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}
