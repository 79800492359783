import { AccountKey, AccountType, RelatedContactKey } from './keys';

interface ServiceAccount {
  id: string;
  accountName: string;
}

export enum AccountRecordType {
  Organization = 'ORGANIZATION',
  Individual = 'INDIVIDUAL',
}

export interface Account {
  id: string;
  [AccountKey.AccountName]: string;
  [AccountKey.Type]: AccountType;
  [AccountKey.CarrierId]: string;
  [AccountKey.Phone]: string;
  [AccountKey.SuccessorDolNumber]: string;
  [AccountKey.SubsidiaryDolNumber]: string;
  [AccountKey.PredecessorDolNumber]: string;
  [AccountKey.DBAName]: string;
  [AccountKey.DCDStatus]: string;
  [AccountKey.DCDStatusDate]: string;
  [AccountKey.UIStatus]: string;
  [AccountKey.UIStatusDate]: string;
  [AccountKey.Website]: string;
  [AccountKey.Fax]: string;
  [AccountKey.Email]: string;
  [AccountKey.AdjusterId]: string;
  [AccountKey.LaborNumber]: string;
  [AccountKey.Street]: string;
  [AccountKey.City]: string;
  [AccountKey.Country]: string;
  [AccountKey.ZipCode]: string;
  [AccountKey.County]: string;
  [AccountKey.State]: string;
  [AccountKey.District]: string;
  [AccountKey.WCClaimServiceAccount]: ServiceAccount;
  [AccountKey.TDIClaimServiceAccount]: ServiceAccount;
  [AccountKey.FirstName]: string;
  [AccountKey.LastName]: string;
  [AccountKey.MiddleInitial]: string;
  [AccountKey.Suffix]: string;
  [AccountKey.SSN]: string;
  [AccountKey.Passport]: string;
  [AccountKey.CreatedBy]: string;
  [AccountKey.CreatedAt]: string;
  [AccountKey.UpdatedAt]: string;
  [AccountKey.UpdatedBy]: string;
}

export type RelatedContact = {
  [RelatedContactKey.Id]: string;
  [RelatedContactKey.Salutation]: string;
  [RelatedContactKey.FirstName]: string;
  [RelatedContactKey.MiddleInitial]: string;
  [RelatedContactKey.LastName]: string;
  [RelatedContactKey.Suffix]: string;
  [RelatedContactKey.Email]: string;
  [RelatedContactKey.Phone]: string;
  [RelatedContactKey.Title]: string;
  [RelatedContactKey.Type]: string;
  [RelatedContactKey.CreatedAt]: string;
  [RelatedContactKey.UpdatedAt]: string;
  [RelatedContactKey.UpdatedByName]: string;
  [RelatedContactKey.CreatedByName]: string;
};
