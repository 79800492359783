import { wrapIntoResponse } from "../helpers";
import { TDI_CASE, TDI_DETAIL } from "./data";

const getTDICase = (params) => {
  const data = TDI_CASE;
  return wrapIntoResponse(data);
};

const getTDIDetail = params => {
  const data = TDI_DETAIL;
  return wrapIntoResponse(data)
}

export default {
  getTDICase,
  getTDIDetail
}