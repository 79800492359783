import { Account } from '../Account';
import { TDICaseKey } from './keys';

export enum TDICaseStatus {
  Open = 'Open',
  Ended = 'Ended',
}

export interface TDICase {
  id: string;
  [TDICaseKey.TDICaseNumber]?: string;
  [TDICaseKey.Claimant]?: Account;
  [TDICaseKey.ClaimantName]?: string;
  [TDICaseKey.AdjustingCompany]?: Account;
  [TDICaseKey.AdjustingCompanyName]?: string;
  [TDICaseKey.InsuranceCarrier]?: Account;
  [TDICaseKey.InsuranceCarrierName]?: string;
  [TDICaseKey.Employer]?: Account;
  [TDICaseKey.FirstDateOfDisabilityClaimed]?: string;
  [TDICaseKey.ClaimOrFileNumber]?: string;
  [TDICaseKey.PortalStatus]: TDICaseStatus;
  [TDICaseKey.DateClaimFiled]?: string;
  [TDICaseKey.AppealFiledDate]?: string;
  [TDICaseKey.AppealWithdrawnDate]?: string;
  [TDICaseKey.MostRecentTDI46]?: Case;
  [TDICaseKey.DateOfInjury]?: string;
}

type Case = {
  id: string;
  tdi46Number: string;
  newAmend: string;
  type: string;
  tdiCaseId: string;
  tdiCaseNumber: string;
  dateReceived: string;
};
