export enum ViewObject {
  User = 'USER',
  TDICase = 'TDICase',
  TDISFCase = 'TDISFCase',
  TDICoverage = 'TDICoverage',
  TDIForm = 'TDIForm',
  FileUpload = 'FileUpload',
  DCDCase = 'WCCase',
  WCInsurancePolicies = 'InsurancePolicy',
  WCCoverage = 'Coverage',
  WCForm = 'WCForm',
}

export enum Tag {}
