export enum WCCoverageKey {
  Id = 'id',
  EffectiveDate = 'effectiveDate',
  ExpirationDate = 'expirationDate',
  CoverageStatus = 'coverageStatus',
  PolicyNumber = 'policyNumber',
  PolicyType = 'policyType',
  PolicyStatusDate = 'policyStatusDate',
  InsuranceCarrier = 'insuranceCarrier',
  CarrierID = 'carrierId',
  IsMaster = 'isMaster',
  WrapUp = 'wrapUp',
  IsMultipleEmployers = 'isMultiEmployer',
  EmployerName = 'employerName',
  Employer = 'employer',
  DOLNumber = 'dolNumber',
  EmployerId = 'employerId',
  InsurancePolicyId = 'insurancePolicyId',
  InsurancePolicy = 'insurancePolicy',

  // ========= filter key =========
  LaborNumberFilterKey = 'employer.laborNumber',
  WrapUpFilterKey = 'insurancePolicy.wrapUp',
  InsurancePolicyFilterKey = 'insurancePolicy.policyNumber',
}
