import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDI46 } from './helpers';
import { TDI46 } from './types';

export function useGetTDI46(
  options?: UseQueryOptions<ApiResponseType<{ tdi46: TDI46 }>, Error, TDI46> & {
    id: string;
  }
) {
  const {
    data: tdi46,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDI46ById,
  } = useQuery<ApiResponseType<{ tdi46: TDI46 }>, Error, TDI46>(
    [API_QUERIES.TDI46s, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdi46: TDI46 }>>(apiClient.getTDI46, params);
      },
      enabled: !!options.id,
      select: ({ data }) => toTDI46(data?.tdi46),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI46 = () =>
    queryClient.invalidateQueries([API_QUERIES.TDI46s, { id: options.id }]);

  return {
    tdi46,
    error,
    isError,
    isLoading,
    onGetTDI46ById,
    handleInvalidateTDI46,
  };
}
