import { TDICoverageKey } from './keys';

export const toTDICoverages = (data) => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [TDICoverageKey.Employer]: item?.employer?.accountName,
    [TDICoverageKey.LaborNumberFilterKey]: item?.employer?.laborNumber,
    [TDICoverageKey.PlanTypeFilterKey]: item?.tdiPlanType?.planType,
  })),
});
