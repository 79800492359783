import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { isEmpty } from 'src/modules/shared-main/common';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { WC77 } from '../WCForm';

export function useGetAllWC77s(
  options?: UseQueryOptions<PaginationResponseType<WC77>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWc77s,
  } = useQuery<PaginationResponseType<WC77>, Error>(
    [API_QUERIES.WC77s, { ...options, ...params }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<WC77>>(apiClient.getAllWC77s, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !isEmpty(params),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllWc77s = () => queryClient.invalidateQueries(API_QUERIES.WC77s);

  const { data: wc77s, hasNext, payloadSize, totalRecords } = data || {};

  return {
    wc77s,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllWc77s,
    setParams,
    handleInvalidateAllWc77s,
  };
}
