import { useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useDebounce } from 'src/hooks';
import { Option } from 'src/modules/shared-main/common/appConfig/constants';
import { HomepageKey } from 'src/queries/HomePage/keys';
import { Homepage } from 'src/queries/HomePage/types';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazySearchGlobal(
  options?: UseInfiniteQueryOptions<{ data: Homepage[] }, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getGlobals,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Homepage[] }, Error>(
    [API_QUERIES.HOMEPAGE, pageParam, debounceSearch],
    (props): Promise<{ data: Homepage[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Homepage[] }>(apiClient.getAllGlobal, [
        { ...pageParam, search },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const searchResults: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (searchResults, idx): Option => ({
            label: searchResults[HomepageKey.DisplayName as string],
            value: searchResults[HomepageKey.LinkTo as string],
            subLabel: searchResults[HomepageKey.Object as string],
            index: pageIdx * 10 + idx,
            ...searchResults,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    searchResults,
    error,
    isError,
    loading: isFetching,
    getGlobals,
    fetchNextPage,
    setInputSearch,
  };
}
