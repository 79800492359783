import { useMutation, UseMutationOptions } from 'react-query';
import { responseWrapper } from 'src/modules/shared-main/queries/helpers';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { EditTDIFormPayload } from './types';

export function useEditTDIForm(
  options?: UseMutationOptions<EditTDIFormPayload, Error, EditTDIFormPayload>
) {
  const { mutate: editTDIForm, isLoading } = useMutation<
    EditTDIFormPayload,
    Error,
    EditTDIFormPayload
  >({
    mutationFn: (payload: EditTDIFormPayload) => responseWrapper(apiClient.editTDIForm, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editTDIForm,
    isLoading,
  };
}
