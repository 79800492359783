import { wrapIntoResponse } from '../helpers';
import { DEFAULT_CASES, DEFAULT_CASE_DETAIL } from './data';

const getWCCases = () => {
  const data = DEFAULT_CASES;
  return wrapIntoResponse(data);
};

const getWCCaseById = () => {
  const data = DEFAULT_CASE_DETAIL;
  return wrapIntoResponse(data);
};

export default { getWCCases, getWCCaseById };
