import { Skeleton } from '@mui/material';
import React, { Suspense } from 'react';
import { SearchableSelectType } from 'src/components/common/Table/helpers';

const SearchClaimant = React.lazy(() => import('src/components/SearchClaimant'));
const SearchEmployerField = React.lazy(() => import('src/components/SearchEmployerField'));
const SearchTDISFCaseField = React.lazy(() => import('src/components/SearchTDISFCaseField'));
const SearchTDICaseField = React.lazy(() => import('src/components/SearchTDICaseField'));
const SearchAdjustingCompany = React.lazy(() => import('src/components/SearchAdjustingCompany'));
const SearchDCDCaseField = React.lazy(() => import('src/components/SearchDCDCaseField'));

const SearchableField: React.FC<Props> = ({ selectedSearchableFieldType, ...props }) => {
  // TODO: Will implement later
  const searchableFieldMap = {
    [SearchableSelectType.Claimant]: <SearchClaimant isInFilter {...props} />,
    [SearchableSelectType.Employer]: <SearchEmployerField isInFilter {...props} />,
    [SearchableSelectType.DCDCase]: <SearchDCDCaseField isInFilter {...props} />,
    [SearchableSelectType.TDICase]: <SearchTDICaseField isInFilter {...props} />,
    [SearchableSelectType.TDISFCase]: <SearchTDISFCaseField isInFilter {...props} />,
    [SearchableSelectType.AdjustingCompany]: <SearchAdjustingCompany isInFilter {...props} />,
  };

  return (
    // eslint-disable-next-line security/detect-object-injection
    <Suspense fallback={<Skeleton />}>{searchableFieldMap[selectedSearchableFieldType]}</Suspense>
  );
};

type Props = {
  name: string;
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  errorMessage: string;
  onBlur: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  value: Array<string> | string;
  selectedSearchableFieldType: SearchableSelectType;
  isMulti: boolean;
  labelControl: string;
};

export default SearchableField;
