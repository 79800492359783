import { PaginationResponseType } from '../helpers';
import { FileUploadKey } from './keys';
import { UploadedFileResponse } from './types';

export const toUploadedFiles = (data): PaginationResponseType<UploadedFileResponse> => ({
  ...data,
  data: data.data.map((file) => ({
    ...file,
    [FileUploadKey.WcCaseId]: file?.wcCase?.id,
    [FileUploadKey.InsuranceCarrier]: file?.insuranceCarrier?.accountName,
    [FileUploadKey.EmployerName]: file?.employer?.accountName,
    [FileUploadKey.CaseNumber]: file?.caseNumber || file?.wcCase?.caseNumber,
    [FileUploadKey.CreatedBy]: file?.createdUser?.fullName,
  })),
});
