import { QueryFunction, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Profile } from './types';

export function useProfile(options?: UseQueryOptions<ApiResponseType<Profile>, Error, Profile>) {
  const handleGetProfile: QueryFunction<ApiResponseType<Profile>, API_QUERIES> = () =>
    responseWrapper<ApiResponseType<Profile>>(apiClient.getMyProfile);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getMyProfile,
  } = useQuery<ApiResponseType<Profile>, Error, Profile>([API_QUERIES.PROFILE], {
    queryFn: handleGetProfile,
    refetchOnMount: false,
    select: getResponseData,
    notifyOnChangeProps: ['data'],
    ...options,
  });

  const queryClient = useQueryClient();

  const handleSetStaleProfile = () => queryClient.invalidateQueries([API_QUERIES.PROFILE]);

  return {
    profile: data,
    error,
    isError,
    loading: isFetching,
    getMyProfile,
    handleSetStaleProfile,
  };
}
