export enum WCInsurancePoliciesKey {
  Id = 'id',
  PolicyNumber = 'policyNumber',
  PolicyType = 'policyType',
  PolicyStatus = 'policyStatus',
  PolicyStatusDate = 'policyStatusDate',
  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierName = 'insuranceCarrierName',
  Employer = 'employer',
  EmployerName = 'employerName',
  EffectiveDate = 'effectiveDate',
  ExpirationDate = 'expirationDate',
}

export enum PolicyStatus {
  Active = 'ACTIVE',
  Exemption = 'EXEMPTION',
  Reinstate = 'REINSTATE',
  Cancel = 'CANCEL',
  FlatDelete = 'FLAT_DELETE',
}

export enum PolicyType {
  InsuredPlan = 'INSURED_PLAN',
  SelfInsured = 'SELF_INSURED',
}
