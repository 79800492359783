import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toInsurancePolicyDetails } from './helpers';
import { WCInsurancePolicies } from './types';

export function useGetWCInsurancePolicy(
  options?: UseQueryOptions<
    ApiResponseType<{ insurancePolicy: WCInsurancePolicies }>,
    Error,
    WCInsurancePolicies
  > & {
    id: string;
  }
) {
  const {
    data: wcInsurancePolicy,
    error,
    isError,
    isFetching: isLoading,
    refetch: getWCInsurancePolicy,
  } = useQuery<
    ApiResponseType<{ insurancePolicy: WCInsurancePolicies }>,
    Error,
    WCInsurancePolicies
  >([API_QUERIES.WCInsurancePolicies, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ insurancePolicy: WCInsurancePolicies }>>(
        apiClient.getInsurancePolicy,
        params
      );
    },
    enabled: !!options.id,
    select: ({ data }) => toInsurancePolicyDetails(data?.insurancePolicy),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWCInsurancePolicy = () =>
    queryClient.invalidateQueries([API_QUERIES.WCInsurancePolicies, { id: options.id }]);

  return {
    wcInsurancePolicy,
    error,
    isError,
    isLoading,
    getWCInsurancePolicy,
    handleInvalidateWCInsurancePolicy,
  };
}
