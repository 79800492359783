export enum RelatedWC1Key {
  FormNumber = 'formNumber',
  DateReceived = 'rawDateReceived',
  IsWC1CaseTouched = 'isWC1Touched',

  //===================== Header and Signature =====================
  NewAmend = 'rawNewAmend',
  InitialCaseNumber = 'rawHeaderCaseNumber',
  DateClaimReceivedByDCD = 'rawDateClaimReceived',
  SignerTitle = 'rawSignerTitle',
  SignedDate = 'rawSignedDate',
  Signature = 'rawSignature',

  EmployeeFirstName = 'rawEmployeeFirstName',
  EmployeeLastName = 'rawEmployeeLastName',
  EmployeeMiddleName = 'rawEmployeeMiddleInitial',
  Suffix = 'rawEmployeeSuffix',
  Gender = 'rawGender',
  MartialStatus = 'rawMaritalStatus',
  IdNumber = 'rawIdNumber',
  IdentityType = 'rawIdentityType',
  SSN = 'rawSSN',
  PassportNumber = 'rawPassportNumber',
  DateOfBirth = 'rawDateOfBirth',
  AddressEmployee = 'rawEmployeeAddress',
  AddressAdditionEmployee = 'rawEmployeeAdditionAddress',
  CityEmployee = 'rawEmployeeCity',
  StateEmployee = 'rawEmployeeState',
  ZipCodeEmployee = 'rawEmployeeZipcode',
  EmailEmployee = 'rawEmployeeEmail',
  PhoneEmployee = 'rawEmployeePhone',
  DateHiredEmployee = 'rawEmployeeDateHired',
  YearEmployedCode = 'rawEmployeeYearEmployedCode',
  Occupation = 'rawOccupation',
  Department = 'rawDepartment',
  PayrollCompClassCode = 'rawPayrollCompClassCode',
  SocCode = 'rawSocCode',
  OccCode = 'rawOccCode',

  //==================== Employer Information =====================
  RegisteredEmployer = 'rawRegisteredEmployer',
  DBAName = 'rawDbaName',
  AddressEmployer = 'rawEmployerAddress',
  CityEmployer = 'rawEmployerCity',
  StateEmployer = 'rawEmployerState',
  ZipCodeEmployer = 'rawEmployerZipcode',
  EmployerPointOfContact = 'rawEmployerPointOfContact',
  PhoneEmployer = 'rawEmployerPhone',
  EmailEmployer = 'rawEmployerEmail',
  EmployerNatureBusiness = 'rawEmployerNatureBusiness',
  PreFabricated = 'rawPreFabricated',
  EmployerDolNumber = 'rawEmployerDolNumber',
  FederalIdNumber = 'rawFederalIdNumber',

  //==================== Detail of Injury/ Illness (I/I) - Section 2 =====================
  DiiReportedDate = 'rawDiiReportedDate',
  DiiDate = 'rawDiiDate',
  DiiTime = 'rawDiiTime',
  DiiTimeOfDay = 'rawDiiTimeOfDay',
  DiiOnEmployerPremise = 'rawDiiOnEmployerPremise',
  DiiDidEmployeeWorkFullShift = 'rawDiiDidEmployeeWorkFullShift',
  DiiWhereInjuryOccur = 'rawDiiWhereInjuryOccur',
  DiiCity = 'rawDiiCity',
  DiiState = 'rawDiiState',
  DiiZipcode = 'rawDiiZipcode',
  DiiHowOccur = 'rawDiiHow',
  DiiTimeWorkShiftBegan = 'rawDiiTimeWorkShiftBegan',
  DiiTimeWorkShiftEndedTimeOfDate = 'rawDiiTimeWorkShiftEndedTimeOfDate',
  DiiTimeWorkShiftBeganTimeOfDate = 'rawDiiTimeWorkShiftBeganTimeOfDate',
  DiiTimeWorkShiftEndTimeOfDate = 'rawDiiTimeWorkShiftEndTimeOfDate',
  DiiTimeWorkShiftEnd = 'rawDiiTimeWorkShiftEnd',
  DiiSourceOfInjury = 'rawDiiSourceOfInjury',
  DiiEvent = 'rawDiiEvent',
  DiiTask = 'rawDiiTask',
  DiiActivity = 'rawDiiActivity',
  DiiInjuryFactor = 'rawDiiInjuryFactor',
  DiiAos = 'rawDiiAos',
  DiiWhatWasEeDoing = 'rawDiiWhatWasEeDoing',
  DiiObjectInjuredEmployee = 'rawDiiObjectInjuredEmployee',

  //==================== Detail of Injury/ Illness (I/I) - Section 2 (Continued) =====================
  DiiDescribeDetail = 'rawDiiDescribeDetail',
  DiiMultipleBodyParts = 'rawDiiMultipleBodyParts',
  DiiNatureOfInjury = 'rawDiiNatureOfInjury',
  DiiPartOfBodyCode = 'rawDiiPartOfBodyCode',

  //==================== Side of Injury/Illness #1 =====================
  Injury1LeftRightSide = 'rawInjury1LeftRightSide',
  Injury1PartOfBody = 'rawInjury1PartOfBody',
  Injury1FrontBackSide = 'rawInjury1FrontBackSide',
  Injury1Disfigurement = 'rawInjury1Disfigurement',
  Injury1Burn = 'rawInjury1Burn',

  //==================== Side of Injury/Illness #2 =====================
  Injury2LeftRightSide = 'rawInjury2LeftRightSide',
  Injury2PartOfBody = 'rawInjury2PartOfBody',
  Injury2FrontBackSide = 'rawInjury2FrontBackSide',
  Injury2Disfigurement = 'rawInjury2Disfigurement',
  Injury2Burn = 'rawInjury2Burn',

  //==================== Side of Injury/Illness #3 =====================
  Injury3LeftRightSide = 'rawInjury3LeftRightSide',
  Injury3PartOfBody = 'rawInjury3PartOfBody',
  Injury3FrontBackSide = 'rawInjury3FrontBackSide',
  Injury3Disfigurement = 'rawInjury3Disfigurement',
  Injury3Burn = 'rawInjury3Burn',

  //==================== Side of Injury/Illness #4 =====================
  Injury4LeftRightSide = 'rawInjury4LeftRightSide',
  Injury4PartOfBody = 'rawInjury4PartOfBody',
  Injury4FrontBackSide = 'rawInjury4FrontBackSide',
  Injury4Disfigurement = 'rawInjury4Disfigurement',
  Injury4Burn = 'rawInjury4Burn',

  //==================== Side of Injury/Illness #5 =====================
  Injury5LeftRightSide = 'rawInjury5LeftRightSide',
  Injury5PartOfBody = 'rawInjury5PartOfBody',
  Injury5FrontBackSide = 'rawInjury5FrontBackSide',
  Injury5Disfigurement = 'rawInjury5Disfigurement',
  Injury5Burn = 'rawInjury5Burn',

  //==================== Time Lost Information - Section 3 =====================
  DateDisabilityBegan = 'rawDateDisabilityBegan',
  EmployeeFurnishesMealsTipsLodging = 'rawWasEmployeeFurnishesMealsTipsLodging',
  AvgWeeklyWage = 'rawAvgWeeklyWage',
  DateBackToWork = 'rawDateBackToWork',
  EmployeePaidForFullDayOfInjury = 'rawIsEmployeePaidForFullDayOfInjury',
  HourlyWage = 'rawHourlyWage',
  MonthlySalary = 'rawMonthlySalary',
  HoursWorkedWeek = 'rawHoursWorkedWeek',
  WeightingFactor = 'rawWeightingFactor',

  //==================== Dependent 1 =====================
  Dep1LastName = 'rawDep1LastName',
  Dep1FirstName = 'rawDep1FirstName',
  Dep1MiddleInitial = 'rawDep1MiddleInitial',
  Dep1Suffix = 'rawDep1Suffix',
  Dep1RelationToDeceased = 'rawDep1RelationToDeceased',
  Dep1Address = 'rawDep1Address',
  Dep1City = 'rawDep1City',
  Dep1State = 'rawDep1State',
  Dep1Zipcode = 'rawDep1Zipcode',
  Dep1Phone = 'rawDep1Phone',

  //==================== Dependent 2 =====================
  Dep2LastName = 'rawDep2LastName',
  Dep2FirstName = 'rawDep2FirstName',
  Dep2MiddleInitial = 'rawDep2MiddleInitial',
  Dep2Suffix = 'rawDep2Suffix',
  Dep2RelationToDeceased = 'rawDep2RelationToDeceased',
  Dep2Address = 'rawDep2Address',
  Dep2City = 'rawDep2City',
  Dep2State = 'rawDep2State',
  Dep2Zipcode = 'rawDep2Zipcode',
  Dep2Phone = 'rawDep2Phone',

  //==================== Dependent 3 =====================
  Dep3LastName = 'rawDep3LastName',
  Dep3FirstName = 'rawDep3FirstName',
  Dep3MiddleInitial = 'rawDep3MiddleInitial',
  Dep3Suffix = 'rawDep3Suffix',
  Dep3RelationToDeceased = 'rawDep3RelationToDeceased',
  Dep3Address = 'rawDep3Address',
  Dep3City = 'rawDep3City',
  Dep3State = 'rawDep3State',
  Dep3Zipcode = 'rawDep3Zipcode',
  Dep3Phone = 'rawDep3Phone',

  //==================== Dependent 4 =====================
  Dep4LastName = 'rawDep4LastName',
  Dep4FirstName = 'rawDep4FirstName',
  Dep4MiddleInitial = 'rawDep4MiddleInitial',
  Dep4Suffix = 'rawDep4Suffix',
  Dep4RelationToDeceased = 'rawDep4RelationToDeceased',
  Dep4Address = 'rawDep4Address',
  Dep4City = 'rawDep4City',
  Dep4State = 'rawDep4State',
  Dep4Zipcode = 'rawDep4Zipcode',
  Dep4Phone = 'rawDep4Phone',

  //==================== Treatment (obtain name of treating physician from employee) - Section 5 =====================
  TrNameOfPhysician = 'rawTrNameOfPhysician',
  TrPhysicianPhone = 'rawTrPhysicianPhone',
  TrPhysicianEmail = 'rawTrPhysicianEmail',
  TrPhysicianAddress = 'rawTrPhysicianAddress',
  TrPhysicianCity = 'rawTrPhysicianCity',
  TrPhysicianState = 'rawTrPhysicianState',
  TrPhysicianZipcode = 'rawTrPhysicianZipcode',
  TrInpatientOvernight = 'rawTrInpatientOvernight',
  TrEmergencyRoomOnly = 'rawTrEmergencyRoomOnly',
  TrMedicalFacilityName = 'rawTrMedicalFacilityName',
  TrMedicalFacilityAddress = 'rawTrMedicalFacilityAddress',
  TrMedicalFacilityCity = 'rawTrMedicalFacilityCity',
  TrMedicalFacilityState = 'rawTrMedicalFacilityState',
  TrMedicalFacilityZipcode = 'rawTrMedicalFacilityZipcode',

  //==================== Insurance Carrier =====================
  IcName = 'rawIcName',
  IcCarrierId = 'rawIcCarrierId',
  IcIsLiabilityDenied = 'rawIcIsLiabilityDenied',
  IcLiabilityDeniedWhy = 'rawIcLiabilityDeniedWhy',
  IcAdjustingCompanyName = 'rawIcAdjustingCompanyName',
  IcAdjusterName = 'rawIcAdjusterName',
  IcAdjusterEmail = 'rawIcAdjusterEmail',
  IcAdjusterPhone = 'rawIcAdjusterPhone',
  IcAdjusterId = 'rawIcAdjusterId',
  IcCarrierClaimNumber = 'rawIcCarrierClaimNumber',
  IcPolicyNumber = 'rawIcPolicyNumber',
  IcPolicyPeriodFrom = 'rawIcPolicyPeriodFrom',
  IcPolicyPeriodTo = 'rawIcPolicyPeriodTo',
  IcMedicalDeductible = 'rawIcMedicalDeductible',

  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  Status = 'status',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  DateOfII = 'injuryDate',

  //===================== Audit Infomation =====================
  CreateUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  CreatedBy = 'createdBy',

  EmployeeDeceasedDate = 'rawEmployeeDeceasedDate',
  WCCaseID = 'wcCaseId',
  RawInjuries = 'rawInjuries',
  RecentWC1 = 'recentWC1',
  WcCase = 'wcCase',

  RawInjury = 'rawInjury',
  FrontBackSide = 'FrontBackSide',
  LeftRightSide = 'LeftRightSide',
  Disfigurement = 'Disfigurement',
  PartOfBody = 'PartOfBody',
  Burn = 'Burn',
}

export enum IIType {
  Injury = 'Injury',
  Illness = 'Illness',
}

export enum SideOfII {
  Left = 'Left',
  Right = 'Right',
  Middle = 'Middle',
  NA = 'N/A',
}

export enum BodyInjuryPart {
  Shoulder = 'Shoulder',
  UpperArm = 'Upper Arm',
  Forearm = 'Forearm',
  Elbow = 'Elbow',
  Hand = 'Hand',
  Wrist = 'Wrist',
  Finger = 'Finger',
  Hip = 'Hip',
  UpperLeg = 'Upper Leg',
  Knee = 'Knee',
  LowerLeg = 'Lower Leg',
  Ankle = 'Ankle',
  Foot = 'Foot',
  Toe = 'Toe',
  Neck = 'Neck',
  UpperBack = 'Upper Back',
  LowerBack = 'Lower Back',
  Chest = 'Chest',
  Rib = 'Rib',
  Abdominal = 'Abdominal',
  Head = 'Head',
  Face = 'Face',
  Eye = 'Eye',
  Nose = 'Nose',
  Ear = 'Ear',
  Mouth = 'Mouth',
  Other = 'Other',
}

export enum BodyIllnessPart {
  Stress = 'Stress',
  StressMental = 'Stress - Mental',
  DifficultyBreathing = 'Difficulty Breathing',
  Fainting = 'Fainting',
  Headache = 'Headache',
  Anxiety = 'Anxiety',
  HeartAttack = 'Heart Attack',
  Stroke = 'Stroke',
  Depression = 'Depression',
  DepressionSuicide = 'Depression - Suicide',
  Vomiting = 'Vomiting',
  BloodClots = 'Blood Clots',
  SkinIssue = 'Skin Issue',
  Burn = 'Burn',
  Disaster = 'Disaster',
  PTSD = 'PTSD',
  Disease = 'Disease',
  DiseaseCancer = 'Disease - Cancer',
  CRPS = 'CRPS',
  Vertigo = 'Vertigo',
  Seizures = 'Seizures',
  Other = 'Other',
}

export enum IIReportKey {
  Type = 'type',
  IISide = 'side',
  BodyPart = 'partOfBody',
  Other = 'other',
  Disfigurement = 'disfigurement',
  Burn = 'burn',
}
