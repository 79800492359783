import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { toUploadedFiles } from './helpers';
import { UploadedFileResponse } from './types';

export function useGetAllUploadedFile(
  options?: UseQueryOptions<PaginationResponseType<UploadedFileResponse>, Error> & {
    [key: string]: string | number | boolean | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllUploadedFile,
  } = useQuery<PaginationResponseType<UploadedFileResponse>, Error>(
    [API_QUERIES.UPLOAD_FILE, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<UploadedFileResponse>>(
          apiClient.getAllUploadFiles,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toUploadedFiles,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllUploadedFile = () =>
    queryClient.invalidateQueries(API_QUERIES.UPLOAD_FILE);

  const { data: uploadedFiles, hasNext, payloadSize, totalRecords } = data || {};

  return {
    uploadedFiles,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllUploadedFile,
    setParams,
    handleInvalidateAllUploadedFile,
  };
}
