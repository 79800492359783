import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toAccountDetail } from './helpers';
import { Account } from './types';

export function useGetAccount(
  options?: UseQueryOptions<ApiResponseType<{ account: Account }>, Error, Account>
) {
  const {
    data: account,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAccount,
  } = useQuery<ApiResponseType<{ account: Account }>, Error, Account>(
    [API_QUERIES.ACCOUNT, options],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ account: Account }>>(apiClient.getAccount, params);
      },
      select: ({ data }) => toAccountDetail(data?.account),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAccount = () => queryClient.invalidateQueries(API_QUERIES.ACCOUNT);

  return {
    account,
    error,
    isError,
    isLoading,
    onGetAccount,
    handleInvalidateAccount,
  };
}
