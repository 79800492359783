import { YesOrNo } from 'src/queries/types';
import { TDI21Key } from './keys';

export enum TypeOfPlanBeingReported {
  InsurancePlan = 'Insured Plan, Statutory',
  SelfInsurePlan = 'Self-Insured Plan',
  CollectiveBargainingPlan = 'Collective Bargaining Plan',
  InsurancePlanBetterThanStatutory = 'Insured Plan, Better Than Statutory',
}

export interface TDI21 {
  [TDI21Key.CalendarYear]: number;
  [TDI21Key.DOLNumber]: string;
  [TDI21Key.EmployerName]: string;
  [TDI21Key.DBAName]: string;
  [TDI21Key.EmployerAddress]: string;
  [TDI21Key.EmployerCity]: string;
  [TDI21Key.EmployerState]: string;
  [TDI21Key.EmployerZipCode]: string;
  [TDI21Key.CarrierID]: string;
  [TDI21Key.CarrierName]: string;
  [TDI21Key.POCName]: string;
  [TDI21Key.POCEmail]: string;
  [TDI21Key.InsuranceAddress]: string;
  [TDI21Key.InsuranceCity]: string;
  [TDI21Key.InsuranceState]: string;
  [TDI21Key.InsuranceZipCode]: string;
  [TDI21Key.PlanTypeOfBeingReported]: TypeOfPlanBeingReported;
  [TDI21Key.EmployeeCoveredClass]: string;
  [TDI21Key.MaleEmployeeJanuary]: number;
  [TDI21Key.MaleEmployeeFebruary]: number;
  [TDI21Key.MaleEmployeeMarch]: number;
  [TDI21Key.MaleEmployeeFebruary]: number;
  [TDI21Key.MaleEmployeeApril]: number;
  [TDI21Key.MaleEmployeeMay]: number;
  [TDI21Key.MaleEmployeeJune]: number;
  [TDI21Key.MaleEmployeeJuly]: number;
  [TDI21Key.MaleEmployeeAugust]: number;
  [TDI21Key.MaleEmployeeSeptember]: number;
  [TDI21Key.MaleEmployeeOctober]: number;
  [TDI21Key.MaleEmployeeNovember]: number;
  [TDI21Key.MaleEmployeeDecember]: number;

  [TDI21Key.FemaleEmployeeJanuary]: number;
  [TDI21Key.FemaleEmployeeFebruary]: number;
  [TDI21Key.FemaleEmployeeMarch]: number;
  [TDI21Key.FemaleEmployeeFebruary]: number;
  [TDI21Key.FemaleEmployeeApril]: number;
  [TDI21Key.FemaleEmployeeMay]: number;
  [TDI21Key.FemaleEmployeeJune]: number;
  [TDI21Key.FemaleEmployeeJuly]: number;
  [TDI21Key.FemaleEmployeeAugust]: number;
  [TDI21Key.FemaleEmployeeSeptember]: number;
  [TDI21Key.FemaleEmployeeOctober]: number;
  [TDI21Key.FemaleEmployeeNovember]: number;
  [TDI21Key.FemaleEmployeeDecember]: number;

  [TDI21Key.TotalWages]: number;
  [TDI21Key.TaxableWages]: number;
  [TDI21Key.EmployerContribution]: number;
  [TDI21Key.EmployeeContribution]: number;
  [TDI21Key.MaleBenefitAmount]: number;
  [TDI21Key.NumberOfDifferentMaleEmployees]: number;
  [TDI21Key.FemalePaidNumberOfWeeks]: number;
  [TDI21Key.MalePaidNumberOfSeparateDisabilityPeriods]: number;
  [TDI21Key.MaleTotalDeniedClaims]: number;
  [TDI21Key.NumberOfDifferentFemaleEmployees]: number;
  [TDI21Key.MalePaidNumberOfWeeks]: number;
  [TDI21Key.FemalePaidNumberOfSeparateDisabilityPeriods]: number;
  [TDI21Key.FemaleTotalDeniedClaims]: number;
  [TDI21Key.SubrogationPaymentsReceived]: number;

  [TDI21Key.CoveredByAssociationOrUnionPlan]: YesOrNo;
  [TDI21Key.AssociationOrUnionName]: string;
  [TDI21Key.AssociationOrUnionNumber]: string;

  [TDI21Key.Title]: string;
  [TDI21Key.SignatureDate]: Date;
  [TDI21Key.PhoneNumber]: string;

  [TDI21Key.TDI21CarrierName]: string;
  [TDI21Key.TDI21TypeOfPlan]: TypeOfPlanBeingReported;
  [TDI21Key.TDI21EmployerName]: string;
}
