import contact from 'src/assets/images/contact.svg';
import defaultAvatar from 'src/assets/images/default-avatar.svg';
import defaultUser from 'src/assets/images/default-user.png';
import documentView from 'src/assets/images/document-view.png';
import iconAccountHomePage from 'src/assets/images/homePage/account-icon.svg';
import backgroundHomePage from 'src/assets/images/homePage/header-background.svg';
import iconPHCHomePage from 'src/assets/images/homePage/phc-icon.svg';
import iconTDICaseHome from 'src/assets/images/homePage/tdi-case-home-icon.svg';
import iconTDIHomePage from 'src/assets/images/homePage/tdi-icon.svg';
import iconTDISFHome from 'src/assets/images/homePage/tdi-sfc-home-icon.svg';
import temporaryDisabilityInsurance from 'src/assets/images/homePage/temporaryDisabilityInsurance.svg';
import iconWCCoverageHome from 'src/assets/images/homePage/wc-coverages.svg';
import iconWCHomePage from 'src/assets/images/homePage/wc-icon.svg';
import iconWorker from 'src/assets/images/homePage/worker-icon.svg';
import workerCompensation from 'src/assets/images/homePage/workerCompensation.svg';
import account from 'src/assets/images/icons/account.svg';
import fileUpload from 'src/assets/images/icons/file-upload.svg';
import iconComputer from 'src/assets/images/icons/ic_computer.png';
import pdfIcon from 'src/assets/images/icons/ic_pdf.svg';
import fileUploadIcon from 'src/assets/images/icons/ic_upload.svg';
import phcIcon from 'src/assets/images/icons/ic_woker_phc.svg';
import tdiIcon from 'src/assets/images/icons/ic_woker_tdi.svg';
import wcIcon from 'src/assets/images/icons/ic_woker_wc.svg';

import chineseTraditional from 'src/assets/images/language/chinese-traditional.svg';
import chineseSimplified from 'src/assets/images/language/chinesesimplified.svg';
import chuukese from 'src/assets/images/language/chuukese.svg';
import english from 'src/assets/images/language/english.svg';
import ilokano from 'src/assets/images/language/ilokano.svg';
import japanese from 'src/assets/images/language/japanese.svg';
import korean from 'src/assets/images/language/korean.svg';
import spanish from 'src/assets/images/language/spanish.svg';
import tagalog from 'src/assets/images/language/tagalog.svg';
import vietnamese from 'src/assets/images/language/vietnamese.svg';
import logo from 'src/assets/images/logos/logo.svg';
import multiPickList from 'src/assets/images/multi_picklist.svg';
import noInformation from 'src/assets/images/no-information.svg';
import pendingAccount from 'src/assets/images/pending-account.png';
import iconAccount from 'src/assets/related-icons/account-icon.svg';
import iconDCDCase from 'src/assets/related-icons/dcd-case-icon.svg';
import iconDecision from 'src/assets/related-icons/decision-icon.svg';
import hearingIcon from 'src/assets/related-icons/hearing.svg';
import iconPortalUser from 'src/assets/related-icons/portal-user-icon.svg';
import iconWCForm from 'src/assets/related-icons/related-wc-form.svg';
import iconTDICase from 'src/assets/related-icons/tdi-case-icon.svg';
import iconTDIForm from 'src/assets/related-icons/tdi-form-icon.svg';
import iconTDISFCase from 'src/assets/related-icons/tdi-sf-case-icon.svg';
import iconWCCoverage from 'src/assets/related-icons/wc-coverage-icon.svg';
import iconInsurancePolicy from 'src/assets/related-icons/wc-insurance-policy.svg';

const homePage = {
  temporaryDisabilityInsurance,
  workerCompensation,
  iconAccountHomePage,
  iconPHCHomePage,
  iconTDIHomePage,
  iconWCHomePage,
  backgroundHomePage,
};

export const IMAGES = {
  iconDCDCase,
  iconTDICase,
  logo,
  defaultUser,
  defaultAvatar,
  iconComputer,
  homePage,
  account,
  pendingAccount,
  pdfIcon,
  contact,
  iconAccount,
  fileUploadIcon,
  wcIcon,
  phcIcon,
  tdiIcon,
  iconTDISFCase,
  documentView,
  iconPortalUser,
  noInformation,
  iconDecision,
  multiPickList,
  hearingIcon,
  iconWCForm,
  iconInsurancePolicy,
  iconWCCoverage,
  iconWorker,
  iconWCCoverageHome,
  iconTDICaseHome,
  iconTDISFHome,
  fileUpload,
  iconTDIForm,

  english,
  ilokano,
  tagalog,
  chineseSimplified,
  chineseTraditional,
  spanish,
  japanese,
  chuukese,
  korean,
  vietnamese,
};
