import { useMutation, UseMutationOptions } from 'react-query';
import { responseWrapper } from 'src/modules/shared-main/queries/helpers';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType } from '../helpers';
import { WCForm } from './types';

export function useCreateWCForm(
  options?: UseMutationOptions<ApiResponseType<{ id: string }>, Error, WCForm>
) {
  const { mutate: createWCForm, isLoading } = useMutation<
    ApiResponseType<{ id: string }>,
    Error,
    WCForm
  >({
    mutationFn: (payload: WCForm) => responseWrapper(apiClient.createWCForm, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createWCForm,
    isLoading,
  };
}
