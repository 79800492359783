export enum WC2Key {
  FormNumber = 'formNumber',
  CaseNumber = 'caseNumber',
  RawCaseNumber = 'rawHeaderCaseNumber',
  PhysicianAccountName = 'physicianAccountName',
  DateReceived = 'rawDateReceived',
  ReportType = 'rawReportType',
  PatientFirstName = 'rawPatientFirstName',
  PatientLastName = 'rawPatientLastName',
  PatientMiddleInitial = 'rawPatientMiddleInitial',
  PatientSuffix = 'rawPatientSuffix',
  PatientAddress = 'rawPatientAddress',
  PatientCity = 'rawPatientCity',
  PatientState = 'rawPatientState',
  PatientZipCode = 'rawPatientZipcode',
  PatientEmail = 'rawPatientEmail',
  PatientPhone = 'rawPatientPhone',
  PatientSocialSecurityNumber = 'rawPatientSocialSecurityNumber',
  PatientPassportNumber = 'rawPatientPassportNumber',
  PatientDateOfInjury = 'rawPatientDateOfInjury',
  PatientDateOfTreatment = 'rawPatientDateOfTreatment',
  PatientDeceasedDate = 'rawPatientDeceasedDate',
  RegisteredEmployerName = 'rawRegisteredEmployerName',
  EmployerDBAName = 'rawEmployerDbaName',
  EmployerAddress = 'rawEmployerAddress',
  EmployerCity = 'rawEmployerCity',
  EmployerZipCode = 'rawEmployerZipcode',
  EmployerState = 'rawEmployerState',
  EmployerEmail = 'rawEmployerEmail',
  EmployerPhone = 'rawEmployerPhone',
  RawEmployerPointOfContact = 'rawEmployerPointOfContact',
  RawCarrier = 'rawCarrier',
  CarrierCaseNumber = 'rawCarrierCaseNumber',
  CarrierAddress = 'rawCarrierAddress',
  CarrierCity = 'rawCarrierCity',
  CarrierState = 'rawCarrierState',
  CarrierZipCode = 'rawCarrierZipcode',
  RawAdjustingCompany = 'rawAdjustingCompany',
  AdjustingCompany = 'adjustingCompany',
  AdjustingCompanyId = 'adjustingCompanyId',
  AdjusterName = 'rawAdjusterName',
  AdjusterEmail = 'rawAdjusterEmail',
  AdjusterPhone = 'rawAdjusterPhone',
  PhysicianPrintedDateSigned = 'rawPhysicianPrintedDateSigned',
  PhysicianPrintedName = 'rawPhysicianPrintedName',
  PhysicianName = 'rawPhysicianName',
  PhysicianPhone = 'rawPhysicianPhone',
  PhysicianEmail = 'rawPhysicianEmail',
  PhysicianAddress = 'rawPhysicianAddress',
  PhysicianCity = 'rawPhysicianCity',
  PhysicianState = 'rawPhysicianState',
  PhysicianZipCode = 'rawPhysicianZipcode',
  PhysicianIsTheAttending = 'rawPhysicianIsTheAttending',
  PhysicianHasPatientBeenBurned = 'rawPhysicianHasPatientBeenBurned',
  PhysicianPossibilityOfOtherDisfigurement = 'rawPhysicianPosibilityOfOtherDisfigurement',
  PhysicianPhysicalRehabilitationNecessary = 'rawPhysicianPhysicalRehabilitationNecessary',
  PhysicianMedicalRehabilitationNecessary = 'rawPhysicianMedicalRehabilitationNecessary',
  InjuryHow = 'rawInjuryHow',
  InjuryDescription = 'rawInjuryDescription',
  InjuryIsMultipleBodyParts = 'rawInjuryIsMultipleBodyParts',
  Injury1LeftRightSide = 'rawInjury1LeftRightSide',
  Injury1FrontBackSide = 'rawInjury1FrontBackSide',
  Injury1PartOfBody = 'rawInjury1PartOfBody',
  Injury1Disfigurement = 'rawInjury1Disfigurement',
  Injury1Burn = 'rawInjury1Burn',
  Injury2LeftRightSide = 'rawInjury2LeftRightSide',
  Injury2FrontBackSide = 'rawInjury2FrontBackSide',
  Injury2PartOfBody = 'rawInjury2PartOfBody',
  Injury2Disfigurement = 'rawInjury2Disfigurement',
  Injury2Burn = 'rawInjury2Burn',
  Injury3LeftRightSide = 'rawInjury3LeftRightSide',
  Injury3FrontBackSide = 'rawInjury3FrontBackSide',
  Injury3PartOfBody = 'rawInjury3PartOfBody',
  Injury3Disfigurement = 'rawInjury3Disfigurement',
  Injury3Burn = 'rawInjury3Burn',
  Injury4LeftRightSide = 'rawInjury4LeftRightSide',
  Injury4FrontBackSide = 'rawInjury4FrontBackSide',
  Injury4PartOfBody = 'rawInjury4PartOfBody',
  Injury4Disfigurement = 'rawInjury4Disfigurement',
  Injury4Burn = 'rawInjury4Burn',
  Injury5LeftRightSide = 'rawInjury5LeftRightSide',
  Injury5FrontBackSide = 'rawInjury5FrontBackSide',
  Injury5PartOfBody = 'rawInjury5PartOfBody',
  Injury5Disfigurement = 'rawInjury5Disfigurement',
  Injury5Burn = 'rawInjury5Burn',
  PhysicianIsInjuryCause = 'rawPhysicianIsInjuryCause',
  PhysicianStateContributingCauses = 'rawPhysicianStateContributingCauses',
  PhysicianEngagedServicesPerson = 'rawPhysicianEngagedServicesPerson',
  PhysicianIsFurtherTreatmentRequired = 'rawPhysicianIsFurtherTreatmentRequired',
  PhysicianFurtherTreatmentRequiredHowLong = 'rawPhysicianFurtherTreatmentRequiredHowLong',
  PhysicianIsTakenXray = 'rawPhysicianIsTakenXray',
  PhysicianDiagnosis = 'rawPhysicianDiagnosis',
  PhysicianTakenXrayPerson = 'rawPhysicianTakedXrayPerson',
  PhysicianXrayDate = 'rawPhysicianXrayDate',
  PhysicianIsTakeByOthers = 'rawPhysicianIsTakeByOthers',
  PhysicianXrayParticipants = 'rawPhysicianXrayParticipants',
  PhysicianParticipantsTreatedDate = 'rawPhysicianParticipantsTreatedDate',
  PhysicianIsPatientHospitalized = 'rawPhysicianIsPatientHospitalized',
  PhysicianDateOfAdmission = 'rawPhysicianDateOfAdmission',
  PhysicianDateOfDischarge = 'rawPhysicianDateOfDischarge',
  PhysicianHospitalName = 'rawPhysicianHospitalName',
  PhysicianHospitalAddress = 'rawPhysicianHospitalAddress',
  PhysicianHospitalCity = 'rawPhysicianHospitalCity',
  PhysicianHospitalState = 'rawPhysicianHospitalState',
  PhysicianHospitalZipCode = 'rawPhysicianHospitalZipcode',
  PhysicianTreatmentDescription = 'rawPhysicianTreatmentDescription',
  PhysicianDoResultDisabilityForWork = 'rawPhysicianDoResultDisabilityForWork',
  PhysicianDisabilityBeganDate = 'rawPhysicianDisabilityBeganDate',
  PhysicianIsPatientBeAbleToResumeWork = 'rawPhysicianIsPatientBeAbleToResumeWork',
  PhysicianTypeOfWork = 'rawPhysicianTypeOfWork',
  PhysicianResumeWorkOnDate = 'rawPhysicianResumeWorkOnDate',
  PhysicianPatientStopTreatmentDate = 'rawPhysicianPatientStopTreatmentDate',
  PhysicianDischargedAsCuredDate = 'rawPhysicianDischargedAsCuredDate',
  PhysicianDoPatientHaveDefect = 'rawPhysicianDoPatientHaveDefect',
  PhysicianPatientDisfigurementDescription = 'rawPhysicianPatientDisfigurementDescription',
  PhysicianFinalDiagnosis = 'rawPhysicianFinalDiagnosis',
  RawCarrierId = 'rawCarrierId',
  AdjusterNumber = 'rawAdjusterId',
}
