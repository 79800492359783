import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { FileUpload } from './types';
import { responseWrapper } from '../helpers';

export function useSubmitFileUploadForm(
  options?: UseMutationOptions<FileUpload, Error, FileUpload>
) {
  const {
    mutate: submitFileUploadForm,
    isLoading,
    isSuccess,
  } = useMutation<FileUpload, Error, FileUpload>({
    mutationFn: (payload: FileUpload) => responseWrapper(apiClient.submitUploadFile, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    submitFileUploadForm,
    isLoading,
    isSuccess,
  };
}
