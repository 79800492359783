import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { DCDCase } from './types';

export function useGetAllDCDCases(
  options?: UseQueryOptions<PaginationResponseType<DCDCase>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllDCDCases,
  } = useQuery<PaginationResponseType<DCDCase>>(
    [API_QUERIES.DCD_CASES, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<DCDCase>>(apiClient.getAllDCDCases, params);
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDCDCases = () => queryClient.invalidateQueries(API_QUERIES.DCD_CASES);

  const { data: dcdCases, hasNext, payloadSize, totalRecords } = data || {};

  return {
    dcdCases,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllDCDCases,
    setParams,
    handleInvalidateDCDCases,
  };
}
