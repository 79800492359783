import { Menu, MenuItem, Stack } from '@mui/material';
import React, { Fragment, useState } from 'react';
import { IoIosLogOut } from 'react-icons/io';
import { IoPersonCircleOutline } from 'react-icons/io5';
import { IMAGES } from 'src/appConfig/images';
import { PATHS } from 'src/appConfig/paths';
import { Image, Text } from 'src/modules/shared-main/components';
import { ProfileKey, useGetPresignedDownloadUrl, useLogout, useProfile } from 'src/queries';
import { Navigator } from 'src/services';
import './styles.scss';

const NavAvatar: React.FC<Props> = ({ pathname }) => {
  const { logout } = useLogout();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { profile } = useProfile();

  const { url: avatarUrl } = useGetPresignedDownloadUrl({
    filePath: profile?.[ProfileKey.Avatar],
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };

  const handleViewProfile = () => {
    setAnchorEl(null);
    Navigator.navigate(PATHS.myProfile);
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Image
          src={avatarUrl ? avatarUrl : IMAGES.defaultUser}
          width={44}
          height={44}
          className="ctn-navbar__avatar mr-8"
        />
        <Text className="has-text-black fw-bold">{profile?.[ProfileKey.FullName] || ''}</Text>
      </Stack>
      <Menu
        id={'simple-Menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ mt: 1 }}
        elevation={1}
      >
        <MenuItem onClick={handleViewProfile}>
          <IoPersonCircleOutline size={20} />
          <Text className="ml-8">My Profile</Text>
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <IoIosLogOut size={20} />
          <Text className="ml-8">Log out</Text>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

type Props = { pathname: string };

export default NavAvatar;
