import { formatTDICaseNumber } from 'src/modules/shared-main/common';
import { TDICaseKey } from './keys';
import { TDICase } from './types';

export const toTDICaseDetail = (data: TDICase) => ({
  ...data,
  [TDICaseKey.ClaimantName]: data?.claimant?.accountName,
  [TDICaseKey.AdjustingCompanyName]: data?.adjustingCompany?.accountName,
  [TDICaseKey.EmployerName]: data?.employer?.accountName,
  [TDICaseKey.InsuranceCarrierName]: data?.insuranceCarrier?.accountName,
  [TDICaseKey.TDICaseFormattedNumber]: formatTDICaseNumber(data?.tdiCaseNumber),
});

export const toTDICases = (data) => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [TDICaseKey.Employer]: item?.employer?.accountName,
    [TDICaseKey.Claimant]: item?.claimant?.accountName,
  })),
});
