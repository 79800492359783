import { isEmpty } from 'src/modules/shared-main/common';
import {
  PaginationResponseType,
  TDI21Key,
  TDI46Key,
  TDI62Key,
  TDIForm,
  TDIFormKey,
  TDIFormType,
  toAuditData,
} from '..';

export const getClaimantInfo = (data, infoType) => {
  if (isEmpty(data)) return '';
  if (data?.formType === TDIFormType.TDI46) {
    const employerName = data?.tdi46?.rawEmployerName;
    return infoType === TDIFormKey.EmployerName ? employerName : '';
  }
  if (data?.formType === TDIFormType.TDI62) {
    const employerName = data?.tdi62?.rawEmployerName;
    return infoType === TDIFormKey.EmployerName ? employerName : '';
  }
  if (data?.formType === TDIFormType.TDI21) {
    const employerName = data?.tdi21?.employerName;
    return infoType === TDIFormKey.EmployerName ? employerName : '';
  }
};

export const getDOLNumberInfo = (data, infoType) => {
  if (isEmpty(data)) return '';

  if (data?.formType === TDIFormType.TDI46) {
    const dolNumber = data?.tdi46?.rawEmployerDolNumber;
    return infoType === TDIFormKey.DolNumber ? dolNumber : '';
  }
  if (data?.formType === TDIFormType.TDI62) {
    const employerName = data?.tdi62?.rawDolNumber;
    return infoType === TDIFormKey.DolNumber ? employerName : '';
  }
};

export const toTdiFormData = (data): PaginationResponseType<TDIForm> => {
  return {
    ...data,
    data: data?.data?.map((item) => {
      return {
        ...item,
        [TDIFormKey.EmployerName]: getClaimantInfo(item, TDIFormKey.EmployerName),
        [TDIFormKey.DolNumber]: getDOLNumberInfo(item, TDIFormKey.DolNumber),
        [TDI21Key.TDI21CarrierName]: item?.tdi21?.carrierName,
        [TDI21Key.TDI21TypeOfPlan]: item?.tdi21?.typeOfPlan,
        [TDI21Key.TDI21EmployerName]: item?.tdi21?.employerName,
        [TDI46Key.TDI46ClaimantName]: item?.tdi46?.rawClaimantName,
        [TDI46Key.TDI46EmployerName]: item?.tdi46?.rawEmployerName,
        [TDI46Key.TDI46EmployerDolNumber]: item?.tdi46?.rawEmployerDolNumber,
        [TDI46Key.TDI46ClaimantFirstDateOfDisabilityClaimed]:
          item?.tdi46?.rawClaimantFirstDateOfDisabilityClaimed,
        [TDI46Key.TDI46ClaimantFiledDate]: item?.tdi46?.rawClaimantFiledDate,
        [TDI62Key.TDI62DolNumber]: item?.tdi62?.rawDolNumber,
        [TDI62Key.TDI62EmployerName]: item?.tdi62?.rawEmployerName,
        [TDI62Key.TDI62EffectiveDater]: item?.tdi62?.rawRequestedEffectiveDater,
        [TDI62Key.TDI62ActionTaken]: item?.tdi62?.actionTaken,
        ...toAuditData(item),
      };
    }),
  };
};
