import { Account } from '../Account';
import { TDISFCaseKey } from './keys';

export enum DenialReason {
  NotReceiving = 'NOT RECEIVING UI',
  Denied = 'DENIED UI',
  Received = 'RECEIVED',
  BenefitYearExpired = 'BENEFIT YEAR EXPIRED',
  Eligible = 'ELIGIBLE',
  Other = 'OTHER',
}

export interface TDISFCase {
  id: string;
  [TDISFCaseKey.CaseNumber]: string;
  [TDISFCaseKey.TDISFCaseFormattedNumber]?: string;
  [TDISFCaseKey.Status]?: string;
  [TDISFCaseKey.PortalStatus]?: TDISFCaseStatus;
  [TDISFCaseKey.ReceivedDate]?: string;
  [TDISFCaseKey.DisabilityDate]?: string;
  [TDISFCaseKey.DenialReason]?: string;
  [TDISFCaseKey.DenialOtherReason]?: string;
  [TDISFCaseKey.Claimant]?: Account;
  [TDISFCaseKey.ClaimantName]?: string;
  [TDISFCaseKey.Employer]?: Account;
  [TDISFCaseKey.EmployerName]?: string;
}

export enum TDISFCaseStatus {
  Open = 'Open',
  Ended = 'Ended',
}
