import React, { PropsWithChildren } from 'react';
import cn from 'classnames';
import './styles.scss';
import { View } from 'src/modules/shared-main/components';

const Card: React.FC<PropsWithChildren<Props>> = ({
  title,
  subTitle,
  children,
  customIcon,
  containerClassName,
  childrenFullWidth,
}) => {
  return (
    <View className={cn('cmp-card', containerClassName)}>
      <View className="cmp-card__header">
        {customIcon && (
          <View className="icon" style={{ width: 'fit-content' }}>
            {customIcon}
          </View>
        )}
        <View>
          {subTitle && <View className="cmp-card__sub-text">{subTitle}</View>}
          <View className="cmp-card__text-title">{title}</View>
        </View>
      </View>
      <View className={cn('cmp-card__body', childrenFullWidth ? 'cmp-card__body-full-width' : '')}>
        {children}
      </View>
    </View>
  );
};

type Props = {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  customIcon?: React.ReactElement;
  containerClassName?: string;
  childrenFullWidth?: boolean;
};

export default Card;
