import { useMutation, UseMutationOptions } from 'react-query';
import { responseWrapper } from 'src/modules/shared-main/queries/helpers';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { EditWCFormPayload } from './types';

export function useEditWCForm(
  options?: UseMutationOptions<EditWCFormPayload, Error, EditWCFormPayload>
) {
  const { mutate: editWCForm, isLoading } = useMutation<
    EditWCFormPayload,
    Error,
    EditWCFormPayload
  >({
    mutationFn: (payload: EditWCFormPayload) => responseWrapper(apiClient.editWCForm, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editWCForm,
    isLoading,
  };
}
