import { ReactNode } from 'react';
import { GoHome } from 'react-icons/go';
import { MdOutlinePersonOutline } from 'react-icons/md';
import { PortalUserType } from 'src/queries';
import { COLOR_CODE } from './constants';
import { IMAGES } from './images';
import { PATHS } from './paths';

export type MenuItem = {
  title: string;
  icon?: ReactNode;
  subMenu?: MenuItem[];
  href?: string;
  iconSrc?: string;
  isParentMenu?: boolean;
  hidden?: boolean;
  userTypePermission?: PortalUserType[];
};

export const sidebarList: MenuItem[] = [
  {
    title: 'Home',
    icon: <GoHome color={COLOR_CODE.TEXT_CONTROL} />,
    href: `${PATHS.homepage}`,
    isParentMenu: true,
  },
  {
    title: 'Account',
    icon: <MdOutlinePersonOutline color={COLOR_CODE.TEXT_CONTROL} />,
    href: `${PATHS.account}`,
    isParentMenu: true,
  },
  {
    title: 'File Upload',
    href: `${PATHS.fileUpload}`,
    iconSrc: IMAGES.fileUpload,
    isParentMenu: true,
  },
  {
    title: 'WC',
    iconSrc: IMAGES.homePage.iconWCHomePage,
    href: `${PATHS.wc}`,
    isParentMenu: true,
    subMenu: [
      {
        title: 'WC Cases',
        href: `${PATHS.wcCases}`,
      },
      {
        title: 'WC Coverages',
        href: `${PATHS.wcCoverages}`,
        userTypePermission: [PortalUserType.Carrier, PortalUserType.SelfInsured],
      },
      {
        title: 'WC Insurance Policies',
        href: `${PATHS.wcInsurancePolicies}`,
        userTypePermission: [PortalUserType.Carrier, PortalUserType.SelfInsured],
      },
      {
        title: 'WC Forms',
        href: `${PATHS.wcForms}`,
      },
      {
        title: 'WC-3s',
        href: `${PATHS.wc3}`,
        hidden: true,
      },
      {
        title: 'WC-2s',
        href: `${PATHS.wc2}`,
        hidden: true,
      },
      {
        title: 'WC-3As',
        href: `${PATHS.wc3A}`,
        hidden: true,
      },
      {
        title: 'WC-5s',
        href: `${PATHS.wc5}`,
        hidden: true,
      },
      {
        title: 'WC-77s',
        href: `${PATHS.wc77}`,
        hidden: true,
      },
      {
        title: 'Guidelines',
        href: `${PATHS.wcGuidelines}`,
      },
    ],
  },
  {
    title: 'TDI',
    iconSrc: IMAGES.homePage.iconTDIHomePage,
    href: `${PATHS.tdi}`,
    isParentMenu: true,
    subMenu: [
      {
        title: 'TDI Cases',
        href: `${PATHS.tdiCases}`,
      },
      {
        title: 'TDI Coverages',
        href: `${PATHS.tdiCoverages}`,
        userTypePermission: [PortalUserType.Carrier, PortalUserType.SelfInsured],
      },
      {
        title: 'TDI Special Fund Case',
        href: `${PATHS.tdiSFCases}`,
      },
      {
        title: 'TDI Forms',
        href: `${PATHS.tdiForms}`,
      },
      {
        title: 'Guidelines',
        href: `${PATHS.tdiGuidelines}`,
      },
    ],
  },
  {
    title: 'PHC',
    iconSrc: IMAGES.homePage.iconPHCHomePage,
    href: `${PATHS.phc}`,
    isParentMenu: true,
  },
  {
    title: 'TDI-46s',
    href: PATHS.tdi46s,
    hidden: true,
  },
  {
    title: 'WC-1s',
    href: PATHS.wc1,
    hidden: true,
  },
  {
    title: 'WC-5As',
    href: PATHS.wc5a,
    hidden: true,
  },
  {
    title: 'Profile',
    href: PATHS.myProfile,
    hidden: true,
  },
];
