import { FormStatus } from '../types';
import { FormTDI46, FormTDI46Payload } from './FormTDI46s';
import { DuplicatedCaseKey, TDIFormKey } from './keys';
import { TDI21 } from './TDI21s';
import { TDI62 } from './TDI62s';

export enum TDIFormType {
  TDI21 = 'TDI-21',
  TDI46 = 'TDI-46',
  TDI62 = 'TDI-62',
}

export interface TDIFormPayload {
  [TDIFormKey.FormType]: TDIFormType;
  [TDIFormKey.Status]: FormStatus;
  [TDIFormKey.TDI21]?: TDI21;
  [TDIFormKey.TDI46]?: FormTDI46Payload;
}

export interface TDIForm {
  [TDIFormKey.FormType]: TDIFormType;
  [TDIFormKey.Status]: FormStatus;
  [TDIFormKey.ID]: string;
  [TDIFormKey.TDI21]?: TDI21;
  [TDIFormKey.TDI46]?: FormTDI46;
  [TDIFormKey.FormNumber]: string;
  [TDIFormKey.EmployerName]?: string;
  [TDIFormKey.DolNumber]?: string;
  [TDIFormKey.TDI62]: TDI62;
  [TDIFormKey.UpdatedAt]: string;
  [TDIFormKey.UpdatedBy]: string;
  [TDIFormKey.DateSubmitted]: string;
}

export type EditTDIFormPayload = TDIFormPayload & {
  id: string;
};

export type DuplicatedCase = {
  [DuplicatedCaseKey.ID]: string;
  [DuplicatedCaseKey.TDICaseNumber]: string;
  [DuplicatedCaseKey.AdjustingCompany]: string;
  [DuplicatedCaseKey.ClaimantName]: string;
  [DuplicatedCaseKey.ClaimantFirstDateOfDisabilityClaimed]: string;
  [DuplicatedCaseKey.ClaimantIdType]: string;
  [DuplicatedCaseKey.ClaimantPassport]: string;
  [DuplicatedCaseKey.ClaimantSSN]: string;
  [DuplicatedCaseKey.ClaimantZipcode]: string;
  [DuplicatedCaseKey.EmployerName]: string;
};
