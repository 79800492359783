import { Account } from '../Account';
import { TDICoverageKey } from './keys';

export enum PlanType {
  Cba = 'CBA',
  BetterThanStatutory = 'BETTER_THAN_STATUTORY',
  Statutory = 'STATUTORY',
  SelfInsured = 'SELF_INSURED',
  Combo = 'COMBO',
}

export enum ActionTaken {
  NewlyEnrolled = 'Newly Enrolled',
  Reinstated = 'Reinstated',
  Canceled = 'Canceled',
  AmendedNewlyEnrolled = 'Amended - Newly Enrolled',
  AmendedCanceled = 'Amended - Canceled',
  FlatDelete = 'Flat Delete',
}

export interface TDICoverage {
  [TDICoverageKey.Employer]: Account;
  [TDICoverageKey.EmployerName]: string;
  [TDICoverageKey.LaborNumber]: string;
  [TDICoverageKey.PlanType]: PlanType;
  [TDICoverageKey.ActionTaken]: ActionTaken;
  [TDICoverageKey.EffectiveDate]: string;
  [TDICoverageKey.EmployeeClassification]: string;
}
