import { Skeleton } from '@mui/material';
import React, { Suspense } from 'react';
import { SearchableSelectType } from 'src/components/common/Table/helpers';

const SearchableValueTDICase = React.lazy(() => import('./SearchableValueTDICase'));
const SearchableValueAccount = React.lazy(() => import('./SearchableValueAccount'));
const SearchableValueDCDCase = React.lazy(() => import('./SearchableValueDCDCase'));

const SearchableValue: React.FC<Props> = ({ values, type }) => {
  const renderByType = () => {
    switch (type) {
      case SearchableSelectType.Employer:
      case SearchableSelectType.Claimant:
      case SearchableSelectType.AdjustingCompany:
        return <SearchableValueAccount values={values} />;
      case SearchableSelectType.DCDCase:
        return <SearchableValueDCDCase values={values} />;
      case SearchableSelectType.TDICase:
        return <SearchableValueTDICase values={values} />;
    }
  };

  return <Suspense fallback={<Skeleton />}>{renderByType()}</Suspense>;
};

type Props = {
  values: string[];
  type: SearchableSelectType;
};

export default SearchableValue;
