import { PaginationResponseType } from '../helpers';
import { WCCoverageKey } from './keys';
import { WCCoverage } from './types';

export const toCoverageData = (data): PaginationResponseType<WCCoverage> => ({
  ...data,
  data: data.data.map((coverage) => ({
    ...coverage,
    [WCCoverageKey.Employer]: coverage?.employer?.accountName,
    [WCCoverageKey.LaborNumberFilterKey]: coverage?.employer?.laborNumber,
    [WCCoverageKey.InsurancePolicy]: coverage?.insurancePolicy?.policyNumber,
    [WCCoverageKey.WrapUpFilterKey]: coverage?.insurancePolicy?.wrapUp,
  })),
});
