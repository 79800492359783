export interface Option {
  label: string;
  subLabel?: string;
  index?: number;
  value: string;
  additionalOptions?: any;
}

export enum FormStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
}
