import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Profile } from './types';

export function useUpdateProfile(options?: UseMutationOptions<any, Error, Profile>) {
  const {
    mutate: onUpdateProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, Profile>({
    mutationFn: (payload: Profile) => responseWrapper(apiClient.updateMyProfile, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onUpdateProfile,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
