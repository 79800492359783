import { HearingKey } from './keys';

export interface Hearing {
  id: string;
  [HearingKey.HearingNumber]: string;
  [HearingKey.HearingEventStatus]: HearingEventStatus;
  [HearingKey.ScheduleStartTime]: string;
  [HearingKey.MissingDocuments]: boolean;
  [HearingKey.HearingDateTime]: string;
}

export enum HearingEventStatus {
  WaitingToBeScheduled = 'WAITING TO BE SCHEDULED',
  Scheduled = 'SCHEDULED',
  HearingHeld = 'HEARING HELD',
  DecisionCreated = 'DECISION CREATED',
  Cancel = 'CANCELLED',
}
export enum MissingDocuments {
  Checked = 'Checked',
  UnChecked = 'UnChecked',
}
