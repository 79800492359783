import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { toTDICoverages } from './helpers';
import { TDICoverage } from './types';

export function useGetAllTDICoverages(
  options?: UseQueryOptions<PaginationResponseType<TDICoverage>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllTDICoverages,
  } = useQuery<PaginationResponseType<TDICoverage>, Error>(
    [API_QUERIES.TDI_COVERAGES, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDICoverage>>(
          apiClient.getAllTDICoverages,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toTDICoverages,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllTDICoverages = () =>
    queryClient.invalidateQueries(API_QUERIES.TDI_COVERAGES);

  const { data: tdiCoverages, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdiCoverages,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllTDICoverages,
    setParams,
    handleInvalidateAllTDICoverages,
  };
}
