export enum DCDCaseKey {
  Id = 'id',
  CaseNumber = 'caseNumber',
  AccountName = 'accountName',
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  InsuranceCarrierName = 'insuranceCarrierName',
  EmployerName = 'employerName',
  ClaimantId = 'claimantId',
  DateOfInjury = 'dateOfInjury',
  ClaimantPhoneNumber = 'claimantPhoneNumber',
  Employer = 'employer',
  Status = 'status',
  PortalStatus = 'portalStatus',
  ClaimantIDNumber = 'claimantIdNumber',
  StatusLastChangedDate = 'statusUpdatedAt',
  CreatedByUser = 'createdUser',
  CreatedAt = 'createdAt',
  UpdatedByUser = 'updatedUser',
  LastModifiedDate = 'updatedAt',
  APRepresentative = 'apRepresentative',
  SCFRepresentative = 'scfRepresentative',
  LocationOfInjuryCode = 'injuryLocation',
  DCDCaseLiaisonId = 'dcdLiaisonId',
  DCDCaseLiaison = 'dcdLiaison',
  EmployerPosition = 'employerPosition',
  AppealStatus = 'appealStatus',
  Court = 'court',
  MinimumWCR = 'minWcr',
  MaximumWCR = 'maxWcr',
  MostRecentWC1 = 'mostRecentWC1',
  RecentWc1Id = 'recentWc1Id',
  ClaimWithdrawnDate = 'claimWithdrawDate',
  IMERequestedDate = 'imeRequestedDate',
  Wc18SentDate = 'wc18SentDate',
  TimeOfInjuryIllness = 'timeOfInjury',
  PrimaryCase = 'primaryCaseId', ///
  CaseStatus = 'caseStatus',
  ReopenedCaseExplanation = 'reopenExplain',
  TemporaryRemandStatus = 'remandStatus',
  TransferApprovalStatus = 'transferApprovalStatus',
  TemporaryRemandReason = 'remandReason',
  CaseReopenedDate = 'caseReopenedDate',
  CaseOpenedDate = 'caseOpenedDate',
  ClaimEndedDate = 'claimEndedDate',
  ControlledBy = 'controlledBy',
  OriginalLocation = 'originalLocation',
  TransferredServicingLocation = 'transferServiceLocation',
  OfficeTransferDate = 'officeTransferDate',
  CaseAdministratorId = 'caseAdminId',
  CaseAdministrator = 'caseAdmin',
  CountyCode = 'countyCode',
  TimeWorkdayBegan = 'workdayBegin',
  HowAccidentOccur = 'accidentOccurDesc',
  AccidentEmployeeDoing = 'accidentEmployeeDoing',
  CurrentServicingLocation = 'currentServicingLocation',
  MostRecentWC77ReceivedDate = 'recentWc77ReceivedDate',
  TransferReason = 'transferReason',
  AppealReceivedDate = 'appealReceivedDate',
  // Missing field
  FilterClaimantSocialSecurityNumber = 'claimant.ssn',
  FilterClaimantPassportNumber = 'claimant.passport',
  ClaimantSocialSecurityNumber = 'claimantSocialSecurityNumber',
  ClaimantPassportNumber = 'claimantPassportNumber',
  HourlyWage = 'hourlyWage',
  AverageWeeklyWage = 'avgWeeklyWage',
  MonthlySalary = 'monthlySalary',
  HRSWorkedWeek = 'hoursWorkWeek',
  WeighingFactor = 'weightingFactor',
  AdjusterCaseNumber = 'adjusterClaimNumber',
  MedicalDeductibleFlag = 'medicalDeductibleFlag',
  IsLiabilityDenied = 'isLiabilityDenied',
  CarrierCaseNumber = 'carrierClaimNumber',
  MedicalDeductibleAmount = 'medicalDeductible',
  TotalBenefitsAmountPaid = 'totalBenefitAmountPaid',
  Task = 'injuryTask',
  Activity = 'injuryActivity',
  InjuryIllnessFactor = 'injuryFactor',
  AOS = 'aos',
  SourceOfInjuryIllness = 'sourceInjury',
  MultipleBodyParts = 'isMultipleBodyParts',
  OnEmployerPremise = 'onEmployerPremise',
  WhereDidIIOccur = 'whereInjuryOccur',
  PossibilityOfBurn = 'possibilityBurn',
  PossibilityOfDisfigurement = 'possibilityDisfigurement',
  NatureOfII = 'natureOfBusiness',
  LapsedTime = 'recentWc1.lapsedTimeHours',
  MostRecentWC1ReceivedDate = 'rawDateReceived',
  WC1SignatureDate = 'signatureDate',
  MostRecentWC5ReceivedDate = 'wc5ReceivedDate',

  DateDisabilityBegan = 'dateDisabilityBegan',
  DateBackToWork = 'dateBackToWork',
  EmployeePaidInFullForDayOfII = 'isEmployeePaidForFullDayOfInjury',
  EmployeeFurnishedMealsTips = 'wasEmployeeFurnishesMealsTipsLodging',
  Prefabricated = 'prefabricatedFrom',
  FullTimePartTime = 'fulltimeParttime',
  Occupation = 'occupation',
  YearsEmployed = 'yearEmployed',
  RecentWC1 = 'recentWc1',

  FilterDateDisabilityBegan = 'recentWc1.dateDisabilityBegan',
  FilterDateBackToWork = 'recentWc1.dateBackToWork',
  FilterEmployeePaidInFullForDayOfII = 'recentWc1.isEmployeePaidForFullDayOfInjury',
  FilterEmployeeFurnishedMealsTips = 'recentWc1.wasEmployeeFurnishesMealsTipsLodging',
  FilterPrefabricated = 'recentWc1.prefabricatedFrom',
  FilterFullTimePartTime = 'recentWc1.fulltimeParttime',
  FilterOccupation = 'recentWc1.occupation',
  FilterYearsEmployed = 'recentWc1.yearEmployed',
  EmployerId = 'employerId',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  FilterHourlyWage = 'recentWc1.hourlyWage',
  FilterAverageWeeklyWage = 'recentWc1.avgWeeklyWage',
  FilterMonthlySalary = 'recentWc1.monthlySalary',
  FilterHRSWorkedWeek = 'recentWc1.hoursWorkWeek',
  FilterWeighingFactor = 'recentWc1.weightingFactor',
  FilterAdjusterCaseNumber = 'recentWc1.adjusterClaimNumber',
  FilterMedicalDeductibleFlag = 'recentWc1.medicalDeductibleFlag',
  FilterIsLiabilityDenied = 'recentWc1.isLiabilityDenied',
  FilterCarrierCaseNumber = 'recentWc1.carrierClaimNumber',
  FilterMedicalDeductibleAmount = 'recentWc1.medicalDeductible',
  FilterTotalBenefitsAmountPaid = 'recentWc1.totalBenefitAmountPaid',
  FilterTask = 'recentWc1.injuryTask',
  FilterActivity = 'recentWc1.injuryActivity',
  FilterInjuryIllnessFactor = 'recentWc1.injuryFactor',
  FilterAOS = 'recentWc1.aos',
  FilterSourceOfInjuryIllness = 'recentWc1.sourceInjury',
  FilterMultipleBodyParts = 'recentWc1.isMultipleBodyParts',
  FilterOnEmployerPremise = 'recentWc1.onEmployerPremise',
  FilterWhereDidIIOccur = 'recentWc1.whereInjuryOccur',
  FilterPossibilityOfBurn = 'recentWc1.possibilityBurn',
  FilterPossibilityOfDisfigurement = 'recentWc1.possibilityDisfigurement',
  FilterNatureOfII = 'recentWc1.natureOfBusiness',
  FilterLapsedTime = 'recentWc1.lapsedTimeHours',
  FilterMostRecentWC1ReceivedDate = 'recentWc1.rawDateReceived',
  FilterWC1SignatureDate = 'recentWc1.signatureDate',
  FilterMostRecentWC5ReceivedDate = 'recentWc1.wc5ReceivedDate',

  // SCF Cases
  SCFNumberOfWeeks = 'scfNumberOfWeeks',
  SCFTotalAmount = 'scfTotalAmount',
  SCFWeeklyBenefit = 'scfWeeklyBenefitAmount',
  SCFCalculatedAmount = 'scfCalculatedAmount',
  SCFRunningTotalDisbursements = 'scfRunningTotalDisbursement',
  SCFControlType = 'scfControlType',
  SCFControlNumber = 'scfControlNum',
  SCFInvolving = 'scfInvolving',
  SCFHoldDate = 'scfHoldDate',
  SCFRequestDate = 'scfRequestDate',
  SCFWc3ReceivedDate = 'scfWc3ReceivedDate',
  SCFDagsLastCheckDate = 'scfDagsLastCheckDate',
  SCFDailyBenefitAmount = 'scfDailyBenefitAmount',
  SCFMonthlyBenefitAmount = 'scfMonthlyBenefitAmount',
  SCFComment = 'scfComment',
  SCFIsInCareOf = 'scfIsInCareOf',
  SCFInCareOfContactName = 'scfInCareOfContactName',
  SCFInCareOfAddress = 'scfInCareOfAddress',
  SCFInCareOfCity = 'scfInCareOfCity',
  SCFInCareOfState = 'scfInCareOfState',
  SCFInCareOfZipcode = 'scfInCareOfZipcode',
  SCFFiscalContactName = 'scfFiscalContactName',
  SCFFiscalContactPhone = 'scfFiscalContactPhone',
  SCFCheckContactName = 'scfCheckContactName',
  SCFCheckContactPhone = 'scfCheckContactPhone',
  SCFCheckContactFax = 'scfCheckContactFax',
  PartyInsuranceCarrier = 'partyInsuranceCarrier',
  PartyAdjustingCompany = 'partyAdjustingCompany',
  //Claimant
  PartyClaimantName = 'partyClaimantName',
  PartyClaimantLastName = 'partyClaimantLastName',
  PartyClaimantFirstName = 'partyClaimantFirstName',
  PartyClaimantMI = 'partyClaimantMI',
  PartyClaimantSuffix = 'partyClaimantSuffix',
  PartyClaimantAddress = 'partyClaimantAddress',
  PartyClaimantCity = 'partyClaimantCity',
  PartyClaimantZipCode = 'partyClaimantZipCode',
  PartyClaimantEmail = 'partyClaimantEmail',
  PartyClaimantPhone = 'partyClaimantPhone',
  PartyClaimantSate = 'partyClaimantState',
  PartyClaimantIdType = 'partyClaimantIdType',
  PartyClaimantIdNumber = 'partyClaimantIdNumber',
  PartyClaimant = 'partyClaimant',
  //Employer
  PartyEmployerName = 'partyEmployerName',
  PartyEmployerDBAName = 'partyEmployerDBAName',
  PartyEmployerAddress = 'PartyEmployerAddress',
  PartyEmployerCity = 'partyEmployerCity',
  PartyEmployerState = 'partyEmployerState',
  PartyEmployerZip = 'partyEmployerZip',
  PartyEmployer = 'partyEmployer',

  //Adjusting Company
  PartyAdjustingCompanyName = 'partyAdjustingCompanyName',
  PartyAdjustingCompanyID = 'partyAdjustingCompanyID',
  PartyAdjustingCompanyEmail = 'partyAdjustingCompanyEmail',
  PartyAdjustingCompanyPhone = 'partyAdjustingCompanyPhone',
  PartyAdjustingCompanyAddress = 'partyAdjustingCompanyAddress',
  PartyAdjustingCompanyCity = 'partyAdjustingCompanyCity',
  PartyAdjustingCompanyState = 'partyAdjustingCompanyState',
  PartyAdjustingCompanyZip = 'partyAdjustingCompanyZip',

  PartyInsuranceCarrierName = 'partyInsuranceCarrierName',
  PartyInsuranceCarrierId = 'partyInsuranceCarrierId',
  RecentWC14 = 'recentWC14',
  RecentWC3 = 'recentWC3',
  RecentWC3A = 'recentWC3a',
}
