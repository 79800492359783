export enum TDI21Key {
  CalendarYear = 'calendarYear',
  DOLNumber = 'employerDOLNumber',
  EmployerName = 'employerName',
  DBAName = 'employerDBAName',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZipCode = 'employerZipcode',

  CarrierID = 'carrierId',
  CarrierName = 'carrierName',
  POCName = 'pointOfContactName',
  POCEmail = 'pointOfContactEmail',
  InsuranceAddress = 'address',
  InsuranceCity = 'city',
  InsuranceState = 'state',
  InsuranceZipCode = 'zip',

  PlanTypeOfBeingReported = 'typeOfPlan',
  EmployeeCoveredClass = 'classOfEmployeesCovered',

  MaleEmployeeJanuary = 'noOfCoveredMaleJan',
  MaleEmployeeFebruary = 'noOfCoveredMaleFeb',
  MaleEmployeeMarch = 'noOfCoveredMaleMar',
  MaleEmployeeApril = 'noOfCoveredMaleApr',
  MaleEmployeeMay = 'noOfCoveredMaleMay',
  MaleEmployeeAugust = 'noOfCoveredMaleAug',
  MaleEmployeeJune = 'noOfCoveredMaleJun',
  MaleEmployeeJuly = 'noOfCoveredMaleJul',
  MaleEmployeeSeptember = 'noOfCoveredMaleSep',
  MaleEmployeeOctober = 'noOfCoveredMaleOct',
  MaleEmployeeNovember = 'noOfCoveredMaleNov',
  MaleEmployeeDecember = 'noOfCoveredMaleDec',

  FemaleEmployeeJanuary = 'noOfCoveredFemaleJan',
  FemaleEmployeeFebruary = 'noOfCoveredFemaleFeb',
  FemaleEmployeeMarch = 'noOfCoveredFemaleMar',
  FemaleEmployeeApril = 'noOfCoveredFemaleApr',
  FemaleEmployeeMay = 'noOfCoveredFemaleMay',
  FemaleEmployeeAugust = 'noOfCoveredFemaleAug',
  FemaleEmployeeJune = 'noOfCoveredFemaleJun',
  FemaleEmployeeJuly = 'noOfCoveredFemaleJul',
  FemaleEmployeeSeptember = 'noOfCoveredFemaleSep',
  FemaleEmployeeOctober = 'noOfCoveredFemaleOct',
  FemaleEmployeeNovember = 'noOfCoveredFemaleNov',
  FemaleEmployeeDecember = 'noOfCoveredFemaleDec',

  TotalWages = 'amountWagesPaid',
  TaxableWages = 'taxableWagesPaid',
  EmployerContribution = 'amountEmployerContributionsPaid',
  EmployeeContribution = 'amountEmployeeContributionsWithheld',
  MaleBenefitAmount = 'amountBenefitsPaidToMale',
  NumberOfDifferentMaleEmployees = 'noOfDiffMalePaidDisability',
  FemalePaidNumberOfWeeks = 'noOfWeeksBenefitsPaidToFemale',
  MalePaidNumberOfSeparateDisabilityPeriods = 'noOfPeriodsBenefitsPaidToMale',
  MaleTotalDeniedClaims = 'noOfClaimsDeniedToMale',
  FemaleBenefitAmount = 'amountBenefitsPaidToFemale',
  NumberOfDifferentFemaleEmployees = 'noOfDiffFemalePaidDisability',
  MalePaidNumberOfWeeks = 'noOfWeeksBenefitsPaidToMale',
  FemalePaidNumberOfSeparateDisabilityPeriods = 'noOfPeriodsBenefitsPaidToFemale',
  FemaleTotalDeniedClaims = 'noOfClaimsDeniedToFemale',
  SubrogationPaymentsReceived = 'amountSubrogationPaymentsReceived',

  CoveredByAssociationOrUnionPlan = 'coveredByAssociationOrUnionPlan',
  AssociationOrUnionName = 'associationUnionName',
  AssociationOrUnionNumber = 'associationOrUnionNumber',

  Title = 'printNameTitle',
  SignatureDate = 'dateSigned',
  PhoneNumber = 'contactPhoneNumber',
  Signature = 'signature',

  TDI21TypeOfPlan = 'tdi21.typeOfPlan',
  TDI21EmployerName = 'tdi21.employerName',
  TDI21CarrierName = 'tdi21.carrierName',
}
