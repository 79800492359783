import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { DuplicatedCase } from './types';

export function useGetAllTDIDuplicatedCases(
  options?: UseQueryOptions<ApiResponseType<PaginationResponseType<DuplicatedCase>>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetDuplicatedCase,
  } = useQuery<ApiResponseType<PaginationResponseType<DuplicatedCase>>, Error>(
    [API_QUERIES.DuplicatedCase, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PaginationResponseType<DuplicatedCase>>>(
          apiClient.getAllTDIDuplicatedCases,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !isEmpty(params),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDuplicatedCase = () =>
    queryClient.invalidateQueries(API_QUERIES.DuplicatedCase);

  const { data: duplicatedCase, hasNext, payloadSize, totalRecords } = data?.data || {};

  return {
    duplicatedCase,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetDuplicatedCase,
    setParams,
    handleInvalidateDuplicatedCase,
  };
}
