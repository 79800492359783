import { Accept } from 'react-dropzone';

export enum AppName {
  External = 'EXTERNAL',
}

export const ONE_HOUR = 60 * 60 * 1000;

export const COMMON_TYPE: Accept = {
  'image/png': ['.png'],
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/webp': ['.webp'],
  'application/pdf': ['.pdf'],
  'application/csv': ['.csv'],
  'application/doc': ['.doc'],
  'application/docx': ['.docx'],
  'application/excel': ['.xls'],
  'application/xlsx': ['.xlsx'],
};

export const muiResponsive = {
  MOBILE: '(max-width:600px)',
  TABLET: '(max-width:960px)',
  LARGE_SCREEN: '(max-width:1200px)',
  EXTRA_LARGE_SCREEN: '(max-width:1440px)',
};

export const COLOR_CODE = {
  PRIMARY: '#005FA9',
  PRIMARY_DARK: '#1f1f1f', // primary 900
  PRIMARY_LIGHT: '#B9B9B9', // primary 300
  SECONDARY: '#006DCC',
  SUCCESS: '#3EAC57',
  WARNING: '#E87839',
  DANGER: '#DB0012',
  WHITE: '#fff',
  BACKGROUND: '#f7f8fa',
  INFO: '#0088CC',
  DISABLED: '#91979E',
  PRIMARY_900: '#1f1f1f',
  PRIMARY_800: '#1f1f1f',
  PRIMARY_700: '#395878',
  PRIMARY_600: '#525252',
  PRIMARY_500: '#787878',
  PRIMARY_400: '#999999',
  PRIMARY_300: '#b9b9b9',
  PRIMARY_200: '#d9d9d9',
  PRIMARY_100: '#ececec',
  PRIMARY_50: '#f8f8f8',
  TEXT: '#3E3E3C',
  GREY_05: '#dddbda',
  GREY_02: '#fafaf9',
  GREY_01: '#6D7176',
  PRIMARY_BLUE: '#388FE3',
  GRAY_92: '#ebebeb',
  WHITE_SMOKE: '#f5f5f5',
  GRAY_600: '#6D7176',
  GRAY_50: '#F8F8F9',
  DEFAULT_BORDER: '1px solid #ccc',
  HAWKES_BLUE: '#dee1e5',
  WHITE_700: '#EDEFF1',
  TEXT_CONTROL: '#484C4F',
};

export enum BOOLEAN {
  true = 'true',
  false = 'false',
}

export const NO_OPENER = 'noopener noreferrer';

export enum FormNewAmend {
  New = 'NEW',
  Amend = 'AMEND',
}

export const newAmendOptions = [
  { value: FormNewAmend.New, label: 'New' },
  { value: FormNewAmend.Amend, label: 'Amend' },
];

export enum YesOrNo {
  Yes = 'Yes',
  No = 'No',
}

export const yesNoOptions = [
  {
    label: 'Yes',
    value: YesOrNo.Yes,
  },
  {
    label: 'No',
    value: YesOrNo.No,
  },
];

export enum HomepageCategory {
  General = 'General',
  Wc = "Workers' Compensation (WC)",
  Tdi = 'Temporary Disability Insurance (TDI)',
}

export enum HomepageCategoryName {
  General = 'general',
  Wc = 'wc',
  Tdi = 'tdi',
}
