export enum WC77AKey {
  CaseId = 'wcCaseId',
  DateReceived = 'rawDateReceived',
  RecentWC77 = 'recentWC77',
  WCFormId = 'wcFormId',
  WCCase = 'wcCase',
  CaseNumber = 'caseNumber',

  RespondentName = 'rawRespondentName',
  RespondentPhone = 'rawRespondentPhone',
  RespondentAddress = 'rawRespondentAddress',
  RespondentCity = 'rawRespondentCity',
  RespondentState = 'rawRespondentState',
  RespondentZipCode = 'rawRespondentZipcode',
  RespondentRepresenting = 'rawRespondentRepresenting',

  ClaimantLastName = 'rawClaimantLastName',
  ClaimantFirstName = 'rawClaimantFirstName',
  ClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  ClaimantSuffix = 'rawClaimantSuffix',
  ClaimantIdType = 'rawClaimantIdType',
  ClaimantIdNumber = 'rawClaimantIdNumber',
  DateOfInjury = 'rawDateOfInjury',
  ClaimantAddress = 'rawClaimantAddress',
  ClaimantCity = 'rawClaimantCity',
  ClaimantState = 'rawClaimantState',
  ClaimantZipCode = 'rawClaimantZipcode',
  ClaimantEmail = 'rawClaimantEmail',
  ClaimantPhone = 'rawClaimantPhone',

  EmployerCarrierName = 'rawEmployerCarrierName',
  EmployerCarrierAddress = 'rawEmployerCarrierAddress',
  EmployerCarrierCity = 'rawEmployerCarrierCity',
  EmployerCarrierState = 'rawEmployerCarrierState',
  EmployerCarrierZipCode = 'rawEmployerCarrierZipcode',

  HearingRespondent = 'rawHearingRespondent',
  HearingFiledApplicant = 'rawHearingFiledApplicant',
  HearingApplicationDate = 'rawHearingApplicationDate',
  HearingApplicationResponses = 'rawHearingApplicationResponses',
  Response = 'response',
  Description = 'description',
  IsCheckedResponse = 'isCheckedResponse',

  Witness1Name = 'rawWitness1Name',
  Witness1WorkPhone = 'rawWitness1WorkPhone',
  Witness1HomePhone = 'rawWitness1HomePhone',
  Witness1Address = 'rawWitness1Address',
  Witness1City = 'rawWitness1City',
  Witness1State = 'rawWitness1State',
  Witness1ZipCode = 'rawWitness1Zipcode',

  Witness2Name = 'rawWitness2Name',
  Witness2WorkPhone = 'rawWitness2WorkPhone',
  Witness2HomePhone = 'rawWitness2HomePhone',
  Witness2Address = 'rawWitness2Address',
  Witness2City = 'rawWitness2City',
  Witness2State = 'rawWitness2State',
  Witness2ZipCode = 'rawWitness2Zipcode',

  Witness3Name = 'rawWitness3Name',
  Witness3WorkPhone = 'rawWitness3WorkPhone',
  Witness3HomePhone = 'rawWitness3HomePhone',
  Witness3Address = 'rawWitness3Address',
  Witness3City = 'rawWitness3City',
  Witness3State = 'rawWitness3State',
  Witness3ZipCode = 'rawWitness3Zipcode',

  Witness = 'rawWitnesses',
  WitnessName = 'name',
  WitnessWorkPhone = 'workPhone',
  WitnessHomePhone = 'homePhone',
  WitnessAddress = 'address',
  WitnessCity = 'city',
  WitnessState = 'state',
  WitnessZipCode = 'zipcode',

  SpecialConditions = 'rawSpecialConditions',
  Signature = 'rawSignature',
  SignatureDate = 'rawSignatureDate',
  SignatureTitle = 'rawSignatureTitle',
}
