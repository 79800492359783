import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { Account } from './types';

export function useGetAccounts(
  options?: UseQueryOptions<PaginationResponseType<Account>, Error> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllAccounts,
  } = useQuery<PaginationResponseType<Account>, Error>(
    [API_QUERIES.Accounts, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Account>>(apiClient.getAllAccounts, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllAccount = () => queryClient.invalidateQueries(API_QUERIES.Accounts);

  const { data: accounts, hasNext, payloadSize, totalRecords } = data || {};

  return {
    accounts,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllAccounts,
    setParams,
    handleInvalidateAllAccount,
  };
}
