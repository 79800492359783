import { WC77Key, WC77StatementKey } from './keys';

export interface HearingIssuesStatement {
  [WC77StatementKey.IssueStatement]: WC77IssueStatement;
  [WC77StatementKey.AttachmentUrl]?: string;
  [WC77StatementKey.DocumentName]?: string;
  [WC77StatementKey.Size]?: number;
  [WC77StatementKey.DocumentTitle]?: string;
  [WC77StatementKey.DocumentDescription]?: string;
  [WC77StatementKey.OtherIssuesToBeResolved]?: string;
}

export enum WC77WitnessKey {
  Name = 'name',
  WorkPhone = 'workPhone',
  HomePhone = 'homePhone',
  Address = 'address',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
}

export type WC77Witness = {
  [WC77WitnessKey.Name]: string;
  [WC77WitnessKey.WorkPhone]: string;
  [WC77WitnessKey.HomePhone]: string;
  [WC77WitnessKey.Address]: string;
  [WC77WitnessKey.City]: string;
  [WC77WitnessKey.State]: string;
  [WC77WitnessKey.ZipCode]: string;
};

export enum WC77IssueStatement {
  ReviewOfEmployerHealthcareDenial = `REVIEW OF EMPLOYER''S DENIAL OF HEALTHCARE`,
  TerminationOfTTD = 'TERMINATION OF TEMPORARY TOTAL DISABILITY',
  Compensability = 'COMPENSABILITY',
  TerminationOfTPD = 'TERMINATION OF TEMPORARY PARTIAL DISABILITY',
  PermanentDisability = 'PERMANENT DISABILITY',
  Disfigurement = 'DISFIGUREMENT',
  DependentDeathBenefits = 'DEPENDENT DEATH BENEFITS',
  ConcurrentEmployment = 'CONCURRENT EMPLOYMENT',
  Reopening = 'REOPENING',
  OtherIssues = 'OTHER ISSUES',
}

export interface WC77 {
  [WC77Key.Id]: string;
  [WC77Key.WcCaseId]: string;

  [WC77Key.RawApplicantName]: string;
  [WC77Key.RawApplicantAddress]: string;
  [WC77Key.RawApplicantCity]: string;
  [WC77Key.RawApplicantState]: string;
  [WC77Key.RawApplicantZipcode]: string;
  [WC77Key.RawApplicantPhone]: string;
  [WC77Key.RawApplicantRepresenting]: string;
  [WC77Key.RawApplicantRepOther]: string;

  [WC77Key.RawClaimantFirstName]: string;
  [WC77Key.RawClaimantLastName]: string;
  [WC77Key.RawClaimantMiddleInitial]: string;
  [WC77Key.RawClaimantSuffix]: string;
  [WC77Key.RawClaimantIdType]: string;
  [WC77Key.RawClaimantIdNumber]: string;
  [WC77Key.RawClaimantAddress]: string;
  [WC77Key.RawClaimantCity]: string;
  [WC77Key.RawClaimantState]: string;
  [WC77Key.RawClaimantZipcode]: string;

  [WC77Key.RawEmployerCarrierName]: string;
  [WC77Key.RawEmployerCarrierAddress]: string;
  [WC77Key.RawEmployerCarrierCity]: string;
  [WC77Key.RawEmployerCarrierState]: string;
  [WC77Key.RawEmployerCarrierZipcode]: string;
  [WC77Key.RawDateOfInjury]: string;

  [WC77Key.RawHearingApplicant]: string;
  [WC77Key.RawHearingDisputeSummary]: string;
  [WC77Key.RawHearingIssuesStatement]: HearingIssuesStatement[];
  [WC77Key.RawWitnesses]: WC77Witness[];

  [WC77Key.RawSpecialConditions]: string;

  [WC77Key.RawSignature]: string;
  [WC77Key.RawSignatureDate]: string;
  [WC77Key.RawSignatureTitle]: string;
}

export type WC77Payload = Partial<WC77>;
