export const TDI_CASE = [
  {
    id: '1',
    caseNumber: 'T0202200031',
    status: 'ended',
    claimant: 'Mighty Mouse',
    employee: 'Magic Castle',
    firstDateOfDisabilityClaimed: '2022-09-28T15:52:30.677Z',
    claimOrFileNumber: '3423423',
    adjustingService: 'AdjCo',
    statusClaim: 'Denied',
    insuranceCarrier: 'Hanh (Insurance Carrier - Org)',
    dateClaimFiled: '2022-09-28T15:52:30.677Z',
    appealFiledDate: '2022-09-28T15:52:30.677Z',
    appealWithDrawnDate: '2022-09-28T15:52:30.677Z',
  },
  {
    id: '2',
    caseNumber: 'T0202200032',
    status: 'ended',
    claimant: 'Mighty Mouse',
    employee: '',
    firstDateOfDisabilityClaimed: '2022-09-28T15:52:30.677Z',
    claimOrFileNumber: '3423423',
    adjustingService: 'AdjCo',
    statusClaim: 'Denied',
    insuranceCarrier: 'Hanh (Insurance Carrier - Org)',
    dateClaimFiled: '2022-09-28T15:52:30.677Z',
    appealFiledDate: '2022-09-28T15:52:30.677Z',
    appealWithDrawnDate: '2022-09-28T15:52:30.677Z',
  }
]

export const TDI_DETAIL = {
  id: '1',
  caseNumber: 'T0202200031',
  status: 'ended',
  claimant: 'Mighty Mouse',
  employee: 'Magic Castle',
  firstDateOfDisabilityClaimed: '2022-09-28T15:52:30.677Z',
  claimOrFileNumber: '3423423',
  adjustingService: 'AdjCo',
  statusClaim: 'Denied',
  insuranceCarrier: 'Hanh (Insurance Carrier - Org)',
  dateClaimFiled: '2022-09-28T15:52:30.677Z',
  appealFiledDate: '2022-09-28T15:52:30.677Z',
  appealWithDrawnDate: '2022-09-28T15:52:30.677Z',
  hearings: [
    {
      id: 'H-20221129-00262',
      status: 'Waiting to be Scheduled',
      date: '2022-09-28T15:52:30.677Z'
    },
    {
      id: 'H-20221129-00258',
      status: 'Hearing Held',
      date: '2022-09-28T15:52:30.677Z'
    },
    {
      id: 'H-20221129-00261',
      status: 'Cancelled',
      date: '2022-09-28T15:52:30.677Z'
    },
  ],
  decisions: [
    {
      id: 'D-20230110-00308',
      status: 'Completed'
    },
    {
      id: 'D-20230110-00309',
      status: 'In Progress'
    }
  ],
}