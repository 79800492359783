import { WC3Key } from './keys';
import { WC3Detail } from './types';

export const getWC3 = (data): WC3Detail => ({
  ...data,
  [WC3Key.ClaimantName]: data?.claimant?.accountName,
  [WC3Key.InsuranceName]: data?.insuranceCarrier?.accountName,
  [WC3Key.EmployerName]: data?.employer?.accountName,
  [WC3Key.CaseNumber]: data?.case?.caseNumber,
  [WC3Key.RawClaimant]: `${data?.rawClaimantFirstName} ${data?.rawClaimantMiddleInitial} ${data?.rawClaimantLastName}`,
});
