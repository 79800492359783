export enum WC77Key {
  WcCaseId = 'wcCaseId',

  // Applicant Info
  RawApplicantName = 'rawApplicantName',
  RawApplicantAddress = 'rawApplicantAddress',
  RawApplicantCity = 'rawApplicantCity',
  RawApplicantState = 'rawApplicantState',
  RawApplicantZipcode = 'rawApplicantZipcode',
  RawApplicantPhone = 'rawApplicantPhone',
  RawApplicantRepresenting = 'rawApplicantRepresenting',
  RawApplicantRepOther = 'rawApplicantRepOther',

  // Claimant Info
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffix = 'rawClaimantSuffix',
  RawClaimantIdType = 'rawClaimantIdType',
  RawClaimantIdNumber = 'rawClaimantIdNumber',
  RawClaimantAddress = 'rawClaimantAddress',
  RawClaimantCity = 'rawClaimantCity',
  RawClaimantState = 'rawClaimantState',
  RawClaimantZipcode = 'rawClaimantZipcode',

  // Employer/Carrier Info
  RawEmployerCarrierName = 'rawEmployerCarrierName',
  RawEmployerCarrierAddress = 'rawEmployerCarrierAddress',
  RawEmployerCarrierCity = 'rawEmployerCarrierCity',
  RawEmployerCarrierState = 'rawEmployerCarrierState',
  RawEmployerCarrierZipcode = 'rawEmployerCarrierZipcode',
  RawDateOfInjury = 'rawDateOfInjury',

  // Hearing Info
  RawHearingApplicant = 'rawHearingApplicant',
  RawHearingDisputeSummary = 'rawHearingDisputeSummary',
  RawHearingIssuesStatement = 'rawHearingIssuesStatement',

  // Special Conditions
  RawSpecialConditions = 'rawSpecialConditions',

  // Signature Info
  RawSignature = 'rawSignature',
  RawSignatureDate = 'rawSignatureDate',
  RawSignatureTitle = 'rawSignatureTitle',

  DateReceived = 'rawDateReceived',
  Witnesses = 'witnesses',

  RawWitnesses = 'rawWitnesses',
  WcCase = 'wcCase',
  CaseNumber = 'caseNumber',
  WcForm = 'wcForm',
  WCFormNumber = 'wcFormNumber',
  Claimant = 'claimant',
  Hearing = 'hearing',
  Id = 'id',
  FormNumber = 'formNumber',
  IsTouchCaseId = 'isTouchCaseId',

  // office User
  HearingDeniedReasons = 'hearingDeniedReasons',
  HearingsReviewSection = 'hearingsReviewSection',
  OfficeUseOther = 'officeUseOther',
  OfficeUseDate = 'officeUseDate',
  OfficeUseApprovedBy = 'officeUseApprovedBy',
  OfficeUseApprovedDate = 'officeUseApprovedDate',
}

export enum WC77StatementKey {
  IssueStatement = 'issueStatement',
  AttachmentUrl = 'attachmentUrl',
  DocumentName = 'documentName',
  Size = 'size',
  DocumentTitle = 'documentTitle',
  DocumentDescription = 'documentDescription',
  IsCheck = 'isCheck',
  Files = 'files',
  OtherIssuesToBeResolved = 'otherIssuesToBeResolved',
}

export enum ApplicantRepresenting {
  Employer = 'EMPLOYER',
  Employee = 'EMPLOYEE',
  Other = 'OTHER',
}

export enum WC77FormIssueStatement {
  ReviewOfEmployersDenialOfHealthcare = 'ReviewOfEmployersDenialOfHealthcare',
  TerminationOfTemporaryTotalDisability = 'TerminationOfTemporaryTotalDisability',
  Compensability = 'Compensability',
  TerminationOfTemporaryPartialDisability = 'TerminationOfTemporaryPartialDisability',
  PermanentDisability = 'PermanentDisability',
  Disfigurement = 'Disfigurement',
  DependentDeathBenefits = 'DependentDeathBenefits',
  ConcurrentEmployment = 'ConcurrentEmployment',
  Reopening = 'Reopening',
  OtherIssues = 'OtherIssues',
}

export const issueStatementValue = {
  [WC77FormIssueStatement.ReviewOfEmployersDenialOfHealthcare]:
    "REVIEW OF EMPLOYER''S DENIAL OF HEALTHCARE",
  [WC77FormIssueStatement.Compensability]: 'COMPENSABILITY',
  [WC77FormIssueStatement.TerminationOfTemporaryTotalDisability]:
    'TERMINATION OF TEMPORARY TOTAL DISABILITY',
  [WC77FormIssueStatement.TerminationOfTemporaryPartialDisability]:
    'TERMINATION OF TEMPORARY PARTIAL DISABILITY',
  [WC77FormIssueStatement.PermanentDisability]: 'PERMANENT DISABILITY',
  [WC77FormIssueStatement.Disfigurement]: 'DISFIGUREMENT',
  [WC77FormIssueStatement.DependentDeathBenefits]: 'DEPENDENT DEATH BENEFITS',
  [WC77FormIssueStatement.ConcurrentEmployment]: 'CONCURRENT EMPLOYMENT',
  [WC77FormIssueStatement.Reopening]: 'REOPENING',
  [WC77FormIssueStatement.OtherIssues]: 'OTHER ISSUES',
};
