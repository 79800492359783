import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../Properties';
import { TDI46 } from './types';

export function useGetAllTDI46(
  options?: UseQueryOptions<PaginationResponseType<TDI46>, Error> & { tdiCaseId?: string }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllTDI46,
  } = useQuery<PaginationResponseType<TDI46>, Error>(
    [API_QUERIES.TDI46s, { ...params, tdiCaseId: options?.tdiCaseId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDI46>>(apiClient.getAllTDI46s, params);
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllTDI46 = () => queryClient.invalidateQueries(API_QUERIES.TDI46s);

  const { data: tdi46s, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdi46s,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllTDI46,
    setParams,
    handleInvalidateAllTDI46,
  };
}
