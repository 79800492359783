import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { getWC2 } from './helpers';
import { WC2 } from './types';

export function useGetWC2(
  options?: UseQueryOptions<ApiResponseType<{ form: WC2 }>, Error, WC2> & {
    id: string;
  }
) {
  const {
    data: wc2,
    error,
    isError,
    isFetching: isLoading,
    refetch: getWC2ByID,
  } = useQuery<ApiResponseType<{ form: WC2 }>, Error, WC2>([API_QUERIES.WC2s, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ form: WC2 }>>(apiClient.getWC2, params);
    },
    select: ({ data }) => getWC2(data.form),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC2 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC2s, { id: options.id }]);

  return {
    wc2,
    error,
    isError,
    isLoading,
    getWC2ByID,
    handleInvalidateWC2,
  };
}
