import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { getWCForm } from './helpers';
import { WCForm } from './types';

export function useGetWCForm(
  options?: UseQueryOptions<ApiResponseType<WCForm>, Error, WCForm> & {
    id: string;
  }
) {
  const {
    data: wcForm,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWCForm,
  } = useQuery<ApiResponseType<WCForm>, Error, WCForm>([API_QUERIES.WCForm, options], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<WCForm>>(apiClient.getWCForm, params);
    },
    select: ({ data }) => getWCForm(data),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWCForm = () => queryClient.invalidateQueries([API_QUERIES.WCForm, options]);

  return {
    wcForm,
    error,
    isError,
    isLoading,
    onGetWCForm,
    handleInvalidateWCForm,
  };
}
