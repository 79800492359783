import { formatShowPassportNumber, formatShowSSN } from 'src/modules/shared-main/common';
import { IdentificationType } from 'src/queries';
import { DCDCaseKey } from './keys';

export const getClaimantIdentificationTypeAndValue = (data: { ssn: string; passport: string }) => {
  const { ssn, passport } = data || {};

  if (!ssn) {
    return passport
      ? { type: IdentificationType.Passport, value: formatShowPassportNumber(passport) }
      : null;
  }

  return { type: IdentificationType.SSN, value: formatShowSSN(ssn) };
};

export const toDCDCaseDetail = (data) => ({
  ...data,
  [DCDCaseKey.ClaimantName]: data?.claimant?.accountName,
  [DCDCaseKey.EmployerName]: data?.employer?.accountName,
  [DCDCaseKey.PartyInsuranceCarrierName]: data?.partyInsuranceCarrier?.accountName,
  [DCDCaseKey.PartyInsuranceCarrierId]: data?.partyInsuranceCarrier?.carrierId,

  //Claimant
  [DCDCaseKey.PartyClaimantName]: data?.partyClaimant?.accountName,
  [DCDCaseKey.PartyClaimantLastName]: data?.partyClaimant?.lastName,
  [DCDCaseKey.PartyClaimantFirstName]: data?.partyClaimant?.firstName,
  [DCDCaseKey.PartyClaimantMI]: data?.partyClaimant?.middleInitial,
  [DCDCaseKey.PartyClaimantSuffix]: data?.partyClaimant?.suffix,
  [DCDCaseKey.PartyClaimantAddress]: data?.partyClaimant?.street,
  [DCDCaseKey.PartyClaimantCity]: data?.partyClaimant?.city,
  [DCDCaseKey.PartyClaimantZipCode]: data?.partyClaimant?.zip,
  [DCDCaseKey.PartyClaimantEmail]: data?.partyClaimant?.email,
  [DCDCaseKey.PartyClaimantPhone]: data?.partyClaimant?.phone,
  [DCDCaseKey.PartyClaimantSate]: data?.partyClaimant?.state,
  [DCDCaseKey.PartyClaimantIdType]: getClaimantIdentificationTypeAndValue(data?.partyClaimant)
    ?.type,
  [DCDCaseKey.PartyClaimantIdNumber]: getClaimantIdentificationTypeAndValue(data?.partyClaimant)
    ?.value,
  //Employer
  [DCDCaseKey.PartyEmployerDBAName]: data?.partyEmployer?.dbaName,
  [DCDCaseKey.PartyEmployerName]: data?.partyEmployer?.accountName,
  [DCDCaseKey.PartyEmployerAddress]: data?.partyEmployer?.street,
  [DCDCaseKey.PartyEmployerCity]: data?.partyEmployer?.city,
  [DCDCaseKey.PartyEmployerState]: data?.partyEmployer?.state,
  [DCDCaseKey.PartyEmployerZip]: data?.partyEmployer?.zip,
  //Adjusting Company
  [DCDCaseKey.PartyAdjustingCompanyName]: data?.partyAdjustingCompany?.accountName,
  [DCDCaseKey.PartyAdjustingCompanyID]: data?.partyAdjustingCompany?.adjusterId,
  [DCDCaseKey.PartyAdjustingCompanyEmail]: data?.partyAdjustingCompany?.email,
  [DCDCaseKey.PartyAdjustingCompanyPhone]: data?.partyAdjustingCompany?.phone,
  [DCDCaseKey.PartyAdjustingCompanyAddress]: data?.partyAdjustingCompany?.street,
  [DCDCaseKey.PartyAdjustingCompanyCity]: data?.partyAdjustingCompany?.city,
  [DCDCaseKey.PartyAdjustingCompanyState]: data?.partyAdjustingCompany?.state,
  [DCDCaseKey.PartyAdjustingCompanyZip]: data?.partyAdjustingCompany?.zip,
});
