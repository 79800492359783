export enum AccountKey {
  AccountName = 'accountName',
  Type = 'accountType',
  CarrierId = 'carrierId',
  Phone = 'phone',
  TDIClaimServiceAccount = 'tdiClaimsServiceAccount',
  TDIClaimServiceAccountName = 'tdiClaimsServiceAccountName',
  SuccessorDolNumber = 'successorDolNumber',
  SubsidiaryDolNumber = 'subsidiaryDolNumber',
  PredecessorDolNumber = 'predecessorDolNumber',
  DBAName = 'dbaName',
  DCDStatus = 'dcdStatus',
  DCDStatusDate = 'dcdStatusDate',
  UIStatus = 'uiStatus',
  UIStatusDate = 'uiStatusDate',
  Website = 'website',
  Fax = 'fax',
  Email = 'email',
  AdjusterId = 'adjusterId',
  LaborNumber = 'laborNumber',
  Street = 'street',
  City = 'city',
  Country = 'country',
  ZipCode = 'zip',
  County = 'county',
  State = 'state',
  District = 'district',
  WCClaimServiceAccount = 'wcClaimsServiceAccount',
  WCClaimServiceAccountName = 'wcClaimsServiceAccountName',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleInitial = 'middleInitial',
  Suffix = 'suffix',
  SSN = 'ssn',
  Passport = 'passport',

  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
}

export enum AccountType {
  Adjuster = 'ADJUSTING_COMPANY',
  Agent = 'AGENT',
  Association = 'ASSOCIATION',
  Employer = 'EMPLOYER',
  InsuranceCarrier = 'INSURANCE_CARRIER',
  Provider = 'PROVIDER',
  Vendor = 'VENDOR',
  Claimant = 'CLAIMANT',
  Complainant = 'COMPLAINANT',
  Dependent = 'DEPENDENT',
  Representative = 'REPRESENTATIVE',
  VocationalRehabCounselor = 'VOCATIONAL_REHAB_COUNSELOR',
  VocationalRehabProvider = 'VOCATIONAL_REHAB_PROVIDER',
}

export enum RelatedContactKey {
  Id = 'id',
  Salutation = 'salutation',
  FirstName = 'firstName',
  MiddleInitial = 'middleInitial',
  LastName = 'lastName',
  Suffix = 'suffix',
  Email = 'email',
  Phone = 'phone',
  Title = 'title',
  Type = 'type',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  ContactName = 'contactName',
}

export const EMPLOYER_SUFFIX = '00';
export const DOL_LENGTH = 10;

export enum Gender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum MaritalStatus {
  Single = 'SINGLE',
  Married = 'MARRIED',
}

export enum PreFabricated {
  WC2 = 'WC-2',
  WC5 = 'WC-5',
}
