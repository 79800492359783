export enum TDIFormKey {
  ID = 'id',
  FormType = 'formType',
  Status = 'formStatus',
  TDI21 = 'tdi21',
  FormNumber = 'tdiFormNumber',
  TdiFormNumber = 'tdiFormNumber',
  EmployerName = 'employerName',
  DolNumber = 'dolNumber',
  FormStatus = 'formStatus',
  TDI46 = 'tdi46',
  TDI62 = 'tdi62',
  DateSubmitted = 'dateSubmitted',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
}

export enum DuplicatedCaseKey {
  ID = 'id',
  TDICaseNumber = 'tdiCaseNumber',
  AdjustingCompany = 'adjustingCompany',
  ClaimantName = 'claimantName',
  ClaimantFirstDateOfDisabilityClaimed = 'claimantFirstDateOfDisabilityClaimed',
  ClaimantIdType = 'claimantIdType',
  ClaimantPassport = 'claimantPassport',
  ClaimantSSN = 'claimantSSN',
  ClaimantZipcode = 'claimantZipcode',
  EmployerName = 'employerName',
}
